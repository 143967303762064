<div class="flex items-center justify-center w-full grow">
  <app-promotion-content></app-promotion-content>
  <div
    class="basis-1/2 grow shrink-0 py-8 px-4 flex flex-col items-center justify-center gap-10"
  >
    <div class="flex flex-col gap-6 w-full max-w-96">
      <app-logo class="tablet:hidden desktop:hidden"></app-logo>
      <div class="flex flex-col items-start gap-2">
        <h1 class="text-2xl font-semibold">{{ 'Sign Up' | locale }}</h1>
        <p class="text-gray-500">{{ "Let's get started." | locale }}</p>
      </div>
    </div>

    <form class="flex flex-col gap-4 w-full max-w-96" [formGroup]="form">
      <app-input
        [label]="'Personal name'"
        [isRequired]="true"
        [formControl]="form.controls.name"
        [errorMessage]="form.controls.name.errors?.['error']"
        [autocomplete]="'name'"
      />
      <app-input
        [label]="'Phone'"
        [isRequired]="true"
        [type]="'tel'"
        [formControl]="form.controls.phone"
        [errorMessage]="form.controls.phone.errors?.['error']"
        [autocomplete]="'tel'"
      />
      <app-input
        [label]="'Email'"
        [autocomplete]="'email'"
        [isRequired]="true"
        [type]="'email'"
        [formControl]="form.controls.email"
        [errorMessage]="form.controls.email.errors?.['error']"
        [autocomplete]="'email'"
      />
      <div>
        <app-input
          [label]="'Password'"
          [autocomplete]="'off'"
          [isRequired]="true"
          [type]="'password'"
          [formControl]="form.controls.password"
          [errorMessage]="form.controls.password.errors?.['error']"
          [autocomplete]="'new-password'"
        />
        <small class="text-sm text-gray-500"
          >Must be at least 8 characters</small
        >
      </div>
      <app-button
        [buttonStyleClass]="'w-full block'"
        (click)="signUp($event)"
        [disabled]="signingUp()"
      >
        Sign Up
      </app-button>
      <app-button
        [buttonStyleClass]="'w-full font-semibold'"
        (click)="navigateToHome()"
        [outlined]="true"
        [type]="'dark'"
      >
        Continue as guest
      </app-button>
      <div class="text-sm text-[var(--normal)]">
        <span>Already have an account? </span>
        <a
          [routerLink]="'/auth/login'"
          [queryParams]="{ authReturnUrl: getReturnUrl() }"
          class="text-[var(--accent)]"
          >Login</a
        >
      </div>
    </form>
  </div>
</div>

<ion-alert
  [isOpen]="!!errorMessage()"
  [message]="errorMessage()"
  [header]="'Error!'"
  [buttons]="signUpErrorButtons"
/>
