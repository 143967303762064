import { ValidatorFunction } from "src/app/models/validator";

export function requiredValidator(fieldName: string): ValidatorFunction {
  return function (control) {
    if (!control.touched || !control.dirty) return null;
    const value = control.value;
    if (!value && value !== 0 /* why javascript? */) {
      return {
        error: `${fieldName ?? 'A value'} is required`
      };
    }
    return null;
  };
}