<div class="text-accent relative p-1.5 flex items-center justify-between">
  <h4 class="font-sherika-regular px-3 py-1 font-semibold">
    {{ title() }}
  </h4>
  @if (shouldShowEditButton()) {
    <ion-icon
      [name]="'create-outline'"
      class="text-xl cursor-pointer p-1"
      (click)="editCustomField()"
    />
  }
</div>
<div class="h-full grow min-h-0 overflow-y-auto {{ wrapperStyleClass() }}">
  <ng-content />
</div>
