import { Component, computed, inject, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { EntityReadComponent } from 'src/app/components/entity/entity-read/entity-read.component';
import { CustomFieldType } from 'src/app/models/entity/custom-field';
import { CustomFieldValue } from 'src/app/models/entity/edit/form';
import { registerClassOnWindow } from 'src/app/utils/global';

@Component({
  selector: 'app-custom-field-wrapper',
  templateUrl: './custom-field-wrapper.component.html',
  styleUrls: ['./custom-field-wrapper.component.scss'],
})
export class CustomFieldWrapperComponent {
  model = input.required<CustomFieldValue>();
  activatedRoute = inject(ActivatedRoute);
  router = inject(NavController);
  entityReadComponent = inject(EntityReadComponent);
  type = computed(() => this.model().fieldType);
  title = computed(() => {
    const model = this.model();
    if (model.fieldType === CustomFieldType.Images) return model.imagePicker?.title;
    if (model.fieldType === CustomFieldType.RichTextEditor) return model.richTextEditor?.title;
    if (model.fieldType === CustomFieldType.Videos) return model.videoPicker?.title;
    // implement the others...
    return;
  });
  wrapperStyleClass = input<string>('');
  shouldShowEditButton = computed(() => this.entityReadComponent?.showActionButtons());

  constructor() {
    registerClassOnWindow('CustomFieldWrapperComponent', this);
  }

  editCustomField() {
    const entityId = this.activatedRoute.snapshot.paramMap.get('entityId');
    this.router.navigateForward(`/dashboard/pages/${entityId}/edit?activeIndex=3#${this.model().id}`);
  }
}