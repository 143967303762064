export function plural(value: number | undefined) {
  if (value === 1) return '';
  return 's';
}

export function replaceSpaces(text: string, replaceWith = '-') {
  return text.replaceAll(/\s/g, replaceWith);
}

export function capitalizeWords(value: string) {
  return value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;