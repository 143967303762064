import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  platform = inject(Platform);
  router = inject(Router);

  constructor() { }

  get isInstalledApp() {
    return this.platform.is('android') || this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone') || this.platform.is('tablet');
  }

  goToPaymentScreen() {
    // TODO implement payment
    if (this.isInstalledApp) {
      // google or apple robbery
    }
    else {
      this.router.navigateByUrl('/');
    }
  }
}
