import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  get isDevelopment() {
    return !environment.production;
  }

  get isInstalledApp() {
    return Capacitor.isNativePlatform();
  }
}
