<ion-tabs>
  <ion-tab-bar
    slot="bottom"
    class="hidden mobile:flex {{ routeData() === Tab.None ? 'hidden' : '' }} "
  >
    <ion-tab-button
      [routerLink]="'/'"
      routerDirection="root"
      [class.tab-selected]="routeData() === Tab.Home"
    >
      <ion-label>{{ 'Home' | locale }}</ion-label>
      <ion-icon [name]="'search-outline'" />
    </ion-tab-button>
    <ion-tab-button
      [class.tab-selected]="routeData() === Tab.Business"
      [tab]="'dashboard'"
      routerDirection="root"
    >
      <ion-label>{{ 'Business' | locale }}</ion-label>
      <ion-icon [name]="'speedometer-outline'" />
    </ion-tab-button>
    @if (sessionService.isAdmin()) {
      <ion-tab-button
        [class.tab-selected]="routeData() === Tab.Admin"
        [tab]="'admin'"
      >
        <ion-label>Admin</ion-label>
        <ion-icon name="cog-outline" />
      </ion-tab-button>
    }
    <ion-tab-button
      [class.tab-selected]="routeData() === Tab.Account"
      [routerLink]="'/user/account'"
    >
      <ion-label>Account</ion-label>
      <ion-icon name="person-circle-outline" />
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
