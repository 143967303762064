import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleDirective } from './directives/locale.directive';
import { LocalePipe } from './pipes/locale.pipe';

@NgModule({
  declarations: [
    LocalePipe,
    LocaleDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    LocalePipe,
  ]
})
export class LocalizationModule { }
