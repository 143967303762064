<div>
  <h2 class="text-xl font-semibold">Profile</h2>
  <div class="text-normal">Update your personal details</div>
</div>

<form [formGroup]="form" (submit)="updateUser()" class="flex flex-col gap-6">
  <app-input
    [label]="'Name'"
    [formControl]="form.controls.name"
    [errorMessage]="form.controls.name.errors?.['error']"
  />
  <app-button [disabled]="saving()" [icon]="'save-outline'">Save</app-button>
</form>

<app-button
  (click)="signOut()"
  [disabled]="loggingOut()"
  [type]="'dark'"
  [icon]="'power-outline'"
  [outlined]="true"
  >Sign out</app-button
>
