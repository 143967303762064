import { Component, inject, signal, viewChild } from '@angular/core';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { combineLatest, filter, map, startWith, Subject, Subscription, switchMap, tap } from 'rxjs';
import { EntityEditFormValue } from 'src/app/models/entity/edit/form';
import { ApiService } from 'src/app/services/api.service';
import { SessionService } from 'src/app/services/session.service';
import { Column, TableComponent } from '../../ui/table/table.component';
import { registerClassOnWindow } from 'src/app/utils/global';
import { AutocompleteCellEditorComponent, AutocompleteOption } from '../../ui/table/cell-editors/autocomplete-cell-editor/autocomplete-cell-editor.component';
import { AddressService } from 'src/app/services/address.service';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { emailRegex } from 'src/app/utils/string';
import { wait } from 'src/app/utils/async';
import { isUrlValid } from 'src/app/utils/url';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-bulk-pages',
  templateUrl: './bulk-pages.component.html',
  styleUrls: ['./bulk-pages.component.scss'],
})
export class BulkPagesComponent implements ViewWillEnter, ViewDidLeave {
  apiService = inject(ApiService);
  sessionService = inject(SessionService);
  addressService = inject(AddressService);
  toastService = inject(ToastService);
  loggingService = inject(LoggingService);

  loading = signal(false);
  reloadPages$ = new Subject<void>();
  pages = signal<Page[]>([]);
  pages$ = combineLatest([
    this.reloadPages$.pipe(startWith(null)),
    this.sessionService.sessionChanged$,
  ]).pipe(
    filter(() => !this.loading()),
    filter(([_, session]) => !!session),
    filter(() => !this.pages().length),
    tap(() => this.loading.set(true)),
    switchMap(() => this.apiService.getAsync<EntityEditFormValue[]>('/admin/get-all-pages')),
    tap(() => this.loading.set(false)),
    map(response => this.refactorModel(response.data)),
  );
  subscription = new Subscription();

  saving = signal(false);
  columns: Column<Page>[] = [
    {
      field: 'name',
      headerName: "Name",
      filter: true,
      sortable: true,
      editable: true,
      width: 120,
      cellClassRules: {
        'cell-invalid': params => !params.value?.trim(),
      },
      tooltipValueGetter: params => !params.value?.trim() ? "Name is required" : params.value?.trim(),
    },
    {
      field: 'phoneNumber',
      headerName: "Phone Number",
      filter: true,
      sortable: true,
      editable: true,
      width: 150,
      tooltipValueGetter: params => params.value?.trim(),
    },
    { field: 'username', headerName: "Username", filter: true, sortable: true, editable: true, width: 140, hide: true },
    {
      field: 'address',
      headerName: "Address",
      filter: true,
      sortable: true,
      editable: true,
      cellEditor: AutocompleteCellEditorComponent,
      cellEditorParams: {
        loaderFunction: (value: string) => this.loaderFunction(value),
      },
      valueFormatter: param => param.value?.label,
      valueGetter: param => param.data?.['address'],
      valueSetter: params => {
        if (params.newValue && params.newValue.value !== params.oldValue?.value) {
          params.data!['address'] = params.newValue;
          return true;
        }
        return false;
      },
      cellEditorPopup: true,
      width: 250,
      tooltipValueGetter: params => params.value?.label?.trim(),
    },
    {
      field: 'emailAddress',
      headerName: "Email",
      filter: true,
      sortable: true,
      editable: true,
      width: 250,
      cellClassRules: {
        'cell-invalid': params => params.value?.trim() ? !emailRegex.test(params.value?.trim()) : false,
      },
      tooltipValueGetter: params => {
        const value = params.value?.trim();
        if (!value) return null;
        return emailRegex.test(value) ? value : 'Invalid email';
      }
    },
    {
      field: 'website',
      headerName: "Website",
      filter: true,
      sortable: true,
      editable: true,
      tooltipValueGetter: params => {
        const value = params.value?.trim();
        if (!value) return null;
        if (isUrlValid(value)) return value;
        return "Invalid URL";
      },
      cellClassRules: {
        'cell-invalid': params => {
          const value = params.value?.trim();
          if (!value) return false;
          return !isUrlValid(value);
        }
      },
      hide: true,
    },
    {
      field: 'description',
      headerName: "Description",
      filter: true,
      sortable: true,
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      tooltipValueGetter: params => params.value?.trim(),
    },
    { field: 'hideFromSearch', headerName: "Hide From Search", filter: true, sortable: true, editable: true, hide: true },
    { field: 'isUnclaimed', headerName: "Is Unclaimed", filter: true, sortable: true, hide: true },
    {
      field: 'tags',
      headerName: "Tags",
      filter: true,
      sortable: true,
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      tooltipValueGetter: params => params.value?.trim(),
    },
    {
      field: "fullName",
      headerName: "User",
      filter: true,
      sortable: true,
      editable: false,
      hide: !this.sessionService.isAdmin(),
    },
    {
      field: "createdAt",
      headerName: "Date Created",
      filter: true,
      sortable: true,
      editable: false,
    },
    { field: 'userId', headerName: "User ID", filter: true, sortable: true, hide: true },
    { field: 'trackingId', hide: true },
  ];
  table = viewChild<TableComponent>('table');
  newRowModelResolver = (): Page => ({
    trackingId: crypto.randomUUID(),
    modified: true,
  });

  constructor() {
    registerClassOnWindow('BulkPagesComponent', this);
  }

  async loaderFunction(value: string): Promise<AutocompleteOption[]> {
    try {
      const predictions = await this.addressService.getPlacePredictions(value);
      return predictions.map(p => ({ label: p.description, value: p.place_id, data: p }));
    } catch (e) {
      this.loggingService.error("There wan an error fetching place predictions. Please reload and try again.", { error: e, query: value });
      this.toastService.error("There was an error showing address suggestions");
      return [];
    }
  }

  ionViewWillEnter(): void {
    this.reloadPages$.next();
    if (this.subscription.closed)
      this.subscription = new Subscription();
    const reloadSubscription = this.reloadPages$.subscribe(() => this.pages.set([]));
    this.subscription.add(reloadSubscription);
    const pagesSubscription = this.pages$.subscribe(p => this.pages.set(p));
    this.subscription.add(pagesSubscription);
  }

  ionViewDidLeave(): void {
    this.reloadPages$.next();
    this.subscription.unsubscribe();
  }

  refactorModel(model: EntityEditFormValue[]): Page[] {
    const refactoredModel: Page[] = model.map(page => ({
      address: page.general?.address as AutocompleteOption,
      description: page.general?.description,
      emailAddress: page.general?.email,
      hideFromSearch: page.general?.hideFromSearch,
      id: page.general?.id,
      username: page.general?.username,
      isUnclaimed: page.general?.isUnclaimed,
      userId: page.general?.userId,
      name: page.general?.name,
      phoneNumber: page.general?.phoneNumber,
      website: page.general?.website,
      tags: page.search?.tags?.map(tag => tag.label).join(', '),
      trackingId: crypto.randomUUID(),
      fullName: page.fullName,
      createdAt: page.general?.createdAt ? new Date(page.general.createdAt).toLocaleDateString() : null,
    }));
    return refactoredModel;
  }

  async save() {
    if (this.saving()) return;
    this.table()?.api()?.stopEditing();
    this.table()?.api()?.clearFocusedCell();
    await wait(0);
    let data = this.table()?.getAllData<Page>() ?? [];
    data = data.filter(d => d.modified);
    if (!data.length) {
      this.toastService.info("Nothing to update");
      return;
    }
    const isValid = !document.querySelectorAll('app-table .cell-invalid').length;
    if (!isValid) {
      this.toastService.error("Please fix the errors in order to save.");
      return;
    }
    const saveModel = data.map<EntityEditFormValue>(page => ({
      general: {
        address: page.address,
        description: page.description,
        email: page.emailAddress,
        hideFromSearch: page.hideFromSearch,
        id: page.id,
        username: page.username,
        isUnclaimed: page.isUnclaimed,
        userId: page.userId,
        name: page.name,
        phoneNumber: page.phoneNumber,
        website: page.website,
        trackingId: page.trackingId,
      },
      search: {
        tags: page.tags?.split(',').map(tag => ({ label: tag.trim() })),
      },
    }));
    try {
      this.saving.set(true);
      const response = await this.apiService.postAsync('/admin/bulk-save-pages', { body: saveModel });
      this.toastService.success(response.message);
    } catch (e) {
      console.error(e);
      this.toastService.error((e as HttpErrorResponse).message);
    } finally {
      this.saving.set(false);
      this.reloadPages$.next();
    }
  }

  addRow() {
    this.table()?.addNewRow();
  }
}

export interface Page {
  id?: number | null;
  trackingId?: string;
  name?: string | null;
  username?: string | null;
  website?: string | null;
  description?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  address?: AutocompleteOption | null;
  avatar?: string | null;
  hideFromSearch?: boolean | null;
  profilePhoto?: string | null;
  isUnclaimed?: boolean | null;
  tags?: string | null;
  userId?: string | null;
  modified?: boolean;
  fullName?: string | null; // the user that created this page
  createdAt?: string | null;
}