export enum SubscriptionType {
  Classic = 1,
  Premium,
  Platinum,
}

export interface CreateSubscriptionArgs {
  subscriptionType: SubscriptionType;
  isActive?: boolean;
  promoCode?: string;
}

export interface OnUpgradeArgs {
  subscriptionType: SubscriptionType;
  amount: number;
}