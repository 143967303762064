<ion-header class="hidden mobile:flex">
  <ion-toolbar>
    <ion-title>Bulk pages</ion-title>
  </ion-toolbar>
</ion-header>
<div class="mobile:hidden mobile:px-6 px-12 pt-6">
  <div class="max-w-screen-lg flex flex-col">
    <h1 class="text-2xl font-sherika-bold">
      {{ 'Bulk pages' | locale }}
    </h1>
    <p class="text-sm text-normal">
      {{ 'Create business pages in bulk' | locale }}
    </p>
  </div>
</div>
<ion-content>
  <div class="p-12 mobile:p-4 flex flex-col gap-5 h-full">
    <app-table
      [columns]="columns"
      [data]="pages()"
      [loading]="loading()"
      (reload)="reloadPages$.next()"
      #table
      [allowAddNewRow]="true"
      [newRowModel]="newRowModelResolver"
    />
    <div class="flex items-center justify-end gap-3">
      <app-button [outlined]="true" [type]="'dark'" (click)="addRow()"
        >Add row</app-button
      >
      <app-button class="self-end" (click)="save()" [disabled]="saving()">
        {{ saving() ? ('Saving...' | locale) : ('Save' | locale) }}
      </app-button>
    </div>
  </div>
</ion-content>
