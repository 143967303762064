<div>
  <h2 class="text-xl font-semibold">Feedback</h2>
  <div class="text-normal">Report a bug or send feedback</div>
</div>

<form class="flex flex-col gap-3 w-96 max-w-full">
  @if (!fullName()) {
  <app-input
    [formControl]="form.controls.name"
    [label]="'Full name'"
    [errorMessage]="form.controls.name.errors?.['error']"
  />
  } @if (!email()) {
  <app-input
    [formControl]="form.controls.email"
    [label]="'Email'"
    [errorMessage]="form.controls.email.errors?.['error']"
  />
  }
  <app-textarea
    [formControl]="form.controls.message"
    [label]="'Message'"
    [errorMessage]="form.controls.message.errors?.['error']"
    (keydown)="sendWithControlEnter($event)"
  />
  <app-button (click)="send($event)" [disabled]="sending()">Submit</app-button>
</form>
