<ag-grid-angular
  style="width: 100%; height: 100%"
  class="grow"
  [rowData]="data()"
  [columnDefs]="columns()"
  (gridReady)="onGridReady($event)"
  [pagination]="true"
  [getRowId]="getRowId"
  [defaultColDef]=""
  (cellValueChanged)="cellValueChanged($event)"
  [gridOptions]="gridOptions"
  #agGrid
  (cellKeyDown)="onCellKeyDown($event)"
  [theme]="theme"
  [loading]="loading()"
/>
