import { Component, computed, inject, input, model, output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EntityEditForm, OperatingHour } from 'src/app/models/entity/edit/form';
import { DateService } from 'src/app/services/date.service';
import { EntityEditService } from 'src/app/services/entity-edit.service';
import { ToastService } from 'src/app/services/toast.service';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { registerClassOnWindow } from 'src/app/utils/global';
import { EntityEditComponent } from '../entity-edit.component';

@Component({
  selector: 'app-entity-edit-hours',
  templateUrl: './entity-edit-hours.component.html',
  styleUrls: ['./entity-edit-hours.component.scss'],
})
export class EntityEditHoursComponent {
  dateService = inject(DateService);
  toastService = inject(ToastService);
  entityEditService = inject(EntityEditService);
  entityEditComponent = inject(EntityEditComponent);

  timeDropdownOptions = computed(() => this.dateService.localeTimeFormatOptions());
  daysOfTheWeek = computed(() => this.dateService.daysOfTheWeek());
  form = input.required<FormGroup<EntityEditForm>>();
  hoursForm = computed(() => this.form().controls.hours);
  next = output<void>();
  back = output<void>();
  isAlwaysOpen = model<boolean | undefined>(undefined);
  entityId = computed(() => this.entityEditComponent.entityId());

  constructor() {
    registerClassOnWindow('EntityEditHours', this);
  }

  getNoteForTimeEntry(entry: Partial<OperatingHour>) {
    const openingTime = entry.openingTime?.value;
    const closingTime = entry.closingTime?.value;
    const openingTimeSet = openingTime || openingTime === 0;
    const closingTimeSet = closingTime || closingTime === 0;
    if (!openingTimeSet && !closingTimeSet) return "Closed";
    if (!openingTime && openingTime !== 0) return;
    if (!closingTime && closingTime !== 0) return;
    if (closingTime < openingTime) return "Closing time will be in the next day";
    if (closingTime === openingTime) return "Open 24 hours";
    return;
  }

  onNext(event: MouseEvent) {
    event.preventDefault();
    const generalForm = this.hoursForm();
    markAllControlsAsTouchedAndDirty(generalForm);
    if (generalForm.invalid) {
      this.toastService.error("Please fix the errors in order to continue");
      return;
    };
    this.next.emit();
  }

  onBack(event: MouseEvent) {
    event.preventDefault();
    this.back.emit();
  }

  alwaysOpenChange(isAlwaysOpen: boolean | undefined) {
    this.entityEditService.alwaysOpenChange(this.form(), isAlwaysOpen);
  }
}
