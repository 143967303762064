import { Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '../../validators/required';
import { fixedLengthValidator } from '../../validators/fixed-length';
import { registerClassOnWindow } from 'src/app/utils/global';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { LoggingService } from 'src/app/services/logging.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-forgot-password-verify-otp',
  templateUrl: './forgot-password-verify-otp.component.html',
  styleUrls: ['./forgot-password-verify-otp.component.scss'],
})
export class ForgotPasswordVerifyOtpComponent implements ViewWillEnter, ViewDidLeave {
  toastService = inject(ToastService);
  loggingService = inject(LoggingService);
  router = inject(Router);
  apiService = inject(ApiService);

  readonly OTP_LENGTH = 6;

  form = new FormGroup({
    otp: new FormControl('', [requiredValidator("Code"), fixedLengthValidator(this.OTP_LENGTH)]),
  });
  email = signal<string | undefined>(undefined);
  confirming = signal(false);
  resending = signal(false);

  subscription = new Subscription();

  constructor() {
    registerClassOnWindow('ConfirmEmailComponent', this);

    const navigation = this.router.getCurrentNavigation();
    const email = navigation?.extras.state?.['email'] as string | undefined;
    this.email.set(email);
  }

  ionViewWillEnter(): void {
    const email = this.email();
    if (!email) {
      this.router.navigateByUrl('/auth/enter-id');
      this.toastService.error("Please enter your email first");
    }
    if (this.subscription.closed) {
      this.subscription = new Subscription();
    }
    this.init();
  }

  ionViewDidLeave(): void {
    this.subscription.unsubscribe();
  }

  init() {
    const subscription = this.form.controls.otp.statusChanges.subscribe(status => {
      if (status !== 'VALID') return;
      this.verifyOtp();
    });
    this.subscription.add(subscription);
  }

  async verifyOtp(event?: Event) {
    event?.preventDefault();
    if (this.confirming()) return;
    try {
      const code = this.form.value.otp;
      if (!code || code.length !== 6) return;
      const email = this.email();
      if (!email) {
        this.toastService.error("Please request a new verification code");
        return;
      }
      const payload: VerifyOtpOptions = {
        code,
        email
      };
      const response = await this.apiService.postAsync<string, VerifyOtpOptions>('/auth/verify-otp', { body: payload });
      this.router.navigateByUrl('/auth/reset-password', { state: { token: response.data, email } });
    } catch (e) {
      const error = e as HttpErrorResponse;
      const errorMessage = error.error.message || "There was an error verifying your code";
      this.toastService.error(errorMessage);
      this.loggingService.error(e, { form: this.form.value });
    } finally {
      this.confirming.set(false);
    }
  }

  async resendCode(event?: Event) {
    event?.preventDefault();
    if (this.resending()) return;
    try {
      this.resending.set(true);
      const email = this.email();
      const response = await this.apiService.postAsync<string, { email: string | undefined; }>('/auth/send-otp', { body: { email } });
      this.toastService.info(response.message);
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message ?? "Error sending code");
    } finally {
      this.resending.set(false);
    }
  }
}

export interface VerifyCode {
  otp: FormControl<string | null>;
}

export interface VerifyOtpOptions {
  code: string;
  email: string;
}