import { Injectable } from '@angular/core';
import imageCompression, { Options } from 'browser-image-compression';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  compress(file: File, maxSizeKb: number) {
    const options: Options = {
      maxSizeMB: maxSizeKb / 1024,
      maxWidthOrHeight: 1080,
      useWebWorker: true,
    };
    return imageCompression(file, options);
  }
}
