import { Component, computed, effect, ElementRef, forwardRef, HostListener, inject, input, model, OnDestroy, OnInit, output, signal, viewChildren } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { toNumber } from 'src/app/utils/number';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {

  label = input<string>();
  value = input<Value>();
  disabled = model<boolean | undefined | null>();
  required = input(false);
  errorMessage = input<string | null | undefined>();
  note = input<string>();
  valueChange = output<DropdownOption | undefined>();
  selectOptions = input.required<DropdownOption[]>();
  selectedOption = model<DropdownOption | undefined>(undefined);
  allowClear = input<boolean>(false);
  selectedOptionText = computed(() => this.selectedOption()?.label ?? '-- Please Select --');
  dropdownOpen = signal(false);
  dropdownOpenChanged$ = toObservable(this.dropdownOpen);
  searchText = signal<string | undefined>(undefined);
  dropdownItemElements = viewChildren('dropdownItem');
  firstOptionToFocusChanged$ = toObservable(this.searchText);
  shiftKeyDown = signal(false);
  noteStyleClass = input<string | undefined>('');

  elementRef = inject(ElementRef);

  constructor() {
    // registerClassOnWindow('DropdownComponent', this);
    effect(() => {
      const currentValue = this.selectedOption();
      this.valueChange.emit(currentValue);
    });

    effect(() => {
      const disabled = this.disabled();
      if (disabled) {
        this.dropdownOpen.set(false);
      }
    }, { allowSignalWrites: true });

    this.dropdownOpenChanged$.pipe(
      takeUntilDestroyed(),
    ).subscribe(value => this.handleDropdownOpenChanged(value));
  }

  ngOnInit() {
    document.addEventListener('click', this.close);
  }


  ngOnDestroy(): void {
    document.removeEventListener('click', this.close);
  }

  onChange(value: any) { }
  onTouched = () => { };

  writeValue(value: string | undefined): void {
    const match = this.selectOptions().find(o => o.value === value);
    this.selectedOption.set(match);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled.set(isDisabled);
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (!this.dropdownOpen()) return;
    const key = event.key;
    this.shiftKeyDown.set(event.shiftKey);
    if (key === 'Escape') {
      this.dropdownOpen.set(false);
      return;
    }
    if (key === 'Enter') {
      this.handleEnterOnFocus();
    }
    if (key.length > 1) return;
    this.findDropdownItem(event);
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    if (!this.dropdownOpen()) return;
    this.shiftKeyDown.set(event.shiftKey);
    if (!event.shiftKey) {
      this.searchText.set(undefined);
    }
  }

  handleEnterOnFocus() {
    const focusedElement = document.activeElement as HTMLElement | null;
    const dropdownIndexString = focusedElement?.dataset['key'];
    if (dropdownIndexString == null) return;
    const dropdownIndexNumber = toNumber(dropdownIndexString);
    this.selectedOption.set(this.selectOptions()[dropdownIndexNumber]);
    this.dropdownOpen.set(false);
  }

  findDropdownItem(event: KeyboardEvent) {
    const key = event.key;
    if (!this.shiftKeyDown()) {
      this.searchText.set(key);
    } else {
      this.searchText.update(prev => prev ? prev + key : key);
    }
    const searchTerm = this.searchText()?.toLocaleLowerCase() ?? '';
    const firstMatch = this.selectOptions().find(o => {
      const candidate = o.label?.toLocaleLowerCase();
      return candidate?.startsWith(searchTerm);
    });
    if (!this.shiftKeyDown())
      this.searchText.set(undefined);
    if (!firstMatch) return;
    const index = this.selectOptions().indexOf(firstMatch);
    const element = document.querySelector(`[data-key="${index}"]`) as HTMLElement | null;
    element?.focus({ preventScroll: true });
    element?.scrollIntoView({ behavior: 'instant', block: 'center' });
  }

  handleDropdownOpenChanged(value: boolean) {
    if (!value) {
      this.searchText.set(undefined);
    } else {
      // We'll first focus the dropdown item that is currently selected
      const selectOption = this.selectedOption();
      if (selectOption) {
        const selectedItemIndex = this.selectOptions().indexOf(selectOption);
        const element = document.querySelector(`[data-key="${selectedItemIndex}"]`) as HTMLElement | null;
        element?.focus({ preventScroll: true });
      }
    }
  }

  close = (event: Event) => {
    const target = event.target as HTMLElement;
    const isChild = target.closest('app-dropdown');
    if (isChild) return;
    if (this.dropdownOpen())
      this.dropdownOpen.set(false);
  };

  valueChanged(value: DropdownOption | undefined) {
    this.selectedOption.set(value);
    const primitiveValue = value?.value;
    this.onChange(primitiveValue);
    this.onTouched();
  }

  clearSelection(event: MouseEvent) {
    if (this.disabled()) return;
    event.stopPropagation(); // prevent the dropdown from opening or closing
    this.selectedOption.set(undefined);
    this.onChange(undefined);
    this.onTouched();
  }

  toggleDropdown() {
    if (this.disabled()) return;
    this.dropdownOpen.set(!this.dropdownOpen());
  }

  closeDropdown() {
    this.dropdownOpen.set(false);
  }
}

type Value = string | number | null | undefined;

export interface DropdownOption<T = string | number> {
  label?: string | null;
  value?: T | null;
  dropdownItemStyleClass?: string;
  emoji?: string;
}