import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotion-content',
  templateUrl: './promotion-content.component.html',
  styleUrls: ['./promotion-content.component.scss'],
})
export class PromotionContentComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
