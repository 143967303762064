<app-navbar
  [hideLogo]="true"
  [menuItems]="menuItems()"
  [popoverButtons]="popoverButtons()"
/>

<div class="mobile:hidden mobile:px-6 px-12 pt-6">
  <div class="max-w-screen-lg">
    <h1 class="text-2xl font-sherika-bold">
      {{ 'Manage users' | locale }}
    </h1>
  </div>
</div>
<ion-header class="hidden mobile:block">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/admin" />
    </ion-buttons>
    <ion-title>{{ 'Manage users' | locale }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar
    (ionInput)="searchText$.next($event.target.value ?? '')"
    class="mobile:p-0 px-8 max-w-xl"
  />
  <ion-list class="mobile:p-0 px-8 max-w-xl">
    @for (user of model()?.users; track user.userId) {
      <ion-item [button]="true" [routerLink]="user.userId">
        @if (user.role === Role.Admin) {
          <ion-icon name="cog-outline" slot="start" color="medium" />
        } @else if (user.role === Role.Staff) {
          <ion-icon name="people-outline" slot="start" color="medium" />
        } @else {
          <ion-icon name="person-circle-outline" slot="start" color="medium" />
        }
        <ion-label position="fixed" class="user-label">
          <h3>
            {{ user.fullName }}
            {{ user.userId === sessionService.userId() ? '(You)' : '' }}
          </h3>
          <p>{{ user.email }}</p>
        </ion-label>
      </ion-item>
    }
    <ion-infinite-scroll (ionInfinite)="onInfinite($event)">
      <ion-infinite-scroll-content
        loadingText="Loading more..."
        loadingSpinner="bubbles"
      />
    </ion-infinite-scroll>
  </ion-list>
</ion-content>
