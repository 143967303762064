<ion-header class="grow-0">
  <ion-toolbar>
    <ion-buttons slot="start"> </ion-buttons>
    <ion-title>Crop image</ion-title>
    <ion-buttons slot="end" class="flex gap-2">
      <ion-button (click)="close()" [strong]="false">Cancel</ion-button>
      <ion-icon
        name="checkmark-outline"
        (click)="confirmCrop()"
        class="text-xl cursor-pointer p-1 rounded-full border border-accent text-accent mr-2 transition-colors hover:border-accent-strong hover:text-accent-strong {{
          croppedImageBlob() ? '' : 'opacity-50'
        }}"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="grow flex items-center justify-center bg-black/85 relative">
  <image-cropper
    [imageURL]="imageSrc()"
    [maintainAspectRatio]="maintainAspectRatio()"
    format="png"
    (imageCropped)="imageCropped($event)"
    [aspectRatio]="aspectRatio()"
    (cropperReady)="onCropperReady($event)"
    (loadImageFailed)="handleLoadImageFail()"
  />
  @if (!cropperReady()) {
  <div class="absolute inset-0 w-full h-full z-10 bg-black/85">
    <app-loader styleClass="!text-zinc-100" />
  </div>
  }
</div>
