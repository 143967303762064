@if (label()) {
  <app-label [required]="isRequired()" [value]="label()" />
}
<div class="relative">
  <input
    [autocomplete]="autocomplete()"
    [type]="showPassword() ? 'text' : type()"
    class="focusable relative w-full min-w-0 {{ inputStyleClass() }} {{
      icon() ? '!pl-9' : ''
    }} {{ type() === 'password' ? '!pr-9' : '' }}"
    [value]="value()"
    (input)="onInput($event)"
    [placeholder]="placeholder()"
    [size]="1"
    [maxLength]="maxLength()"
    [disabled]="disabled()"
    #inputElement
    [inputMode]="inputMode()"
    (keydown.enter)="handleEnter($event)"
  />
  @if (loading()) {
    <ion-spinner
      class="absolute right-2 top-1/2 -translate-y-1/2"
      name="crescent"
    />
  }
  @if (icon()) {
    <ion-icon
      [name]="icon()"
      class="text-xl absolute left-3 bottom-1/2 translate-y-1/2 text-muted"
    />
  }
  @if (type() === 'password') {
    <ion-button
      class="absolute right-2 top-1/2 -translate-y-1/2 leading-3 cursor-pointer"
      shape="round"
      (click)="showPassword.set(!showPassword())"
    >
      @if (showPassword()) {
        <ion-icon name="eye-off-outline" class="text-lg" slot="icon-only" />
      } @else {
        <ion-icon name="eye-outline" class="text-lg" slot="icon-only" />
      }
    </ion-button>
  }
</div>
@if (successMessage()) {
  <app-validation-output [value]="successMessage()" [type]="'success'" />
} @else if (errorMessage()) {
  <app-validation-output [value]="errorMessage()" />
} @else if (note()) {
  <app-validation-output [value]="note()" [type]="'info'" />
}
