import { inject, Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';
import { registerClassOnWindow } from '../utils/global';
import { ToastService } from './toast.service';
import { Role } from '../models/role';
import { Router } from '@angular/router';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  supabaseService = inject(SupabaseService);
  toastService = inject(ToastService);
  apiService = inject(ApiService);
  router = inject(Router);

  constructor() {
    registerClassOnWindow('AuthService', this);
  }

  async signUp(args: SignUpArgs) {
    if (!args.email) return;
    if (!args.password) return;
    const signUpResponse = await this.supabaseService.supabase.auth.signUp({
      email: args.email,
      password: args.password,
      phone: args.phone!,
      // options: {
      //   emailRedirectTo: `${environment.baseUrl}/auth/email-confirmed`,
      // }
    });
    if (signUpResponse.error?.message) {
      console.error({ error: signUpResponse.error });
      throw signUpResponse.error;
    }
    await this.sendOtp(args.email);
    await this.createUserRecord(args, signUpResponse.data.user?.id);
  }

  async createUserRecord(args: SignUpArgs, newUserId: string | undefined) {
    const response = await this.supabaseService.supabase.from('account').insert({
      user_id: newUserId,
      full_name: args.name,
      role: Role.RegularUser,
    });
    if (response.error) {
      console.error(response.error);
      throw new Error(response.error.message);
    }
  }

  async login(args: SignUpArgs) {
    if (!args.email) return;
    if (!args.password) return;
    const response = await this.supabaseService.supabase.auth.signInWithPassword({
      email: args.email,
      password: args.password,
    });
    if (response.error) {
      console.error(response.error);
      throw new Error(response.error.message);
    }
  }

  async requestResetPasswordLink(email: string | undefined) {
    if (!email) return;
    const urlBase = window.location.origin;
    const { error } = await this.supabaseService.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${urlBase}/auth/reset-password`
    });
    if (error)
      throw error;
  }

  async updatePassword(newPassword: string | undefined | null) {
    if (!newPassword) return;
    const { error } = await this.supabaseService.supabase.auth.updateUser({
      password: newPassword,
    });
    if (error) throw error;
  }

  async logout() {
    const { error } = await this.supabaseService.supabase.auth.signOut();
    if (error?.message) {
      this.toastService.error(`Sign out error: ${error.message}`);
    }
    this.router.navigateByUrl('/auth/login');
  }

  sendOtp(email: string) {
    return this.supabaseService.supabase.auth.signInWithOtp({ email, options: {} });
  }

  verifyOtp(email: string, otp: string) {
    return this.supabaseService.supabase.auth.verifyOtp({ email, type: 'email', token: otp });
  }

  banUser(userId: string, hours: string | null) {
    return this.apiService.postAsync('/admin/ban-user', { body: { userId, hours } });
  }
}

export interface SignUpArgs {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  phone?: string | null;
}