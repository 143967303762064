import { Component, effect, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, fromEvent, mergeMap } from 'rxjs';
import { isChildOf } from 'src/app/utils/dom';

@Component({
  selector: 'app-popover-buttons',
  templateUrl: './popover-buttons.component.html',
  styleUrls: ['./popover-buttons.component.scss'],
})
export class PopoverButtonsComponent {

  label = input<string | null | undefined>();
  buttons = input.required<PopoverButton[]>();
  isOpen = signal(false);

  shouldClose$ = fromEvent(document, 'click').pipe(
    filter(event => !isChildOf(event.target as HTMLElement, 'app-popover-buttons')),
    takeUntilDestroyed(),
  );

  constructor() {
    this.shouldClose$.subscribe(() => this.isOpen.set(false));
  }

}

export interface PopoverButton {
  label?: string;
  icon?: string;
  href?: string;
  action?: (event: Event) => void | Promise<void>;
  isDivider?: boolean;
}