import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-edit-wrapper',
  templateUrl: './entity-edit-wrapper.component.html',
  styleUrls: ['./entity-edit-wrapper.component.scss'],
})
export class EntityEditWrapperComponent {

}
