import { Component, computed, input, output, viewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellValueChangedEvent, ColDef, GetRowIdFunc, GetRowIdParams, GridOptions, GridReadyEvent, CellKeyDownEvent, FullWidthCellKeyDownEvent, themeQuartz } from 'ag-grid-community';
import { wait } from 'src/app/utils/async';
import { toNumber } from 'src/app/utils/number';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {

  columns = input.required<Column[]>();
  data = input.required<Data[] | undefined>();
  loading = input(false);
  reload = output<void>();
  gridOptions: GridOptions = {
    tooltipShowDelay: 0,
  };
  agGrid = viewChild<AgGridAngular>('agGrid');
  api = computed(() => this.agGrid()?.api);
  cellKeyDown = output<TableCellKeyDownEvent>();
  allowAddNewRow = input<boolean>();
  newRowModel = input<() => object>();
  theme = themeQuartz.withParams({
    rowBorder: { style: 'solid', color: '#ddd', width: 1 },
    columnBorder: { style: 'solid', color: '#ddd', width: 1 },
  });

  onGridReady(event: GridReadyEvent) {
    this.reload.emit();
  }
  getRowId: GetRowIdFunc = (params: GetRowIdParams) => String(params.data.trackingId);

  cellValueChanged(event: CellValueChangedEvent) {
    event.data.modified = true;
  }

  getAllData<T = unknown>() {
    const data: T[] = [];
    this.api()?.forEachNode(node => data.push(node.data));
    return data;
  }

  async addRowOnBottom<T extends object>(row: T, columnKey?: string) {
    this.api()?.applyTransaction({ add: [row] });
    await wait(0);
    const newIndex = toNumber(this.api()?.getDisplayedRowCount()) - 1;
    if (!columnKey) {
      const firstVisibleColumn = this.columns().find(c => c.hide);
      if (!firstVisibleColumn?.field) {
        console.error("No column to focus to");
        return;
      }
      columnKey = firstVisibleColumn.field;
    }
    this.api()?.setFocusedCell(newIndex, columnKey);
  }

  isForwardKey(key: string, event: CellKeyDownEvent<Data, any>) {
    if (key === 'ArrowDown') return true;
    const allColumns = event.api.getAllDisplayedColumns();
    const lastColumn = allColumns[allColumns.length - 1];
    const currentColumn = event.column.getColDef();
    const isLastColumn = lastColumn?.getColDef().field === currentColumn.field;
    if (isLastColumn) {
      if (key === 'Tab') return true;
      if (key === 'Enter') return true;
    }
    return false;
  }

  onCellKeyDown(event: TableCellKeyDownEvent) {
    this.cellKeyDown.emit(event);
    const kbdEvent = event.event as KeyboardEvent | undefined;
    if (!kbdEvent) return;
    if (!('column' in event)) return;
    const { key, altKey, ctrlKey, metaKey, shiftKey } = kbdEvent;
    if (altKey || ctrlKey || metaKey || shiftKey) return;
    const isLastRow = event.node?.rowIndex === toNumber(this.api()?.getDisplayedRowCount()) - 1;
    const isForwardKey = this.isForwardKey(key, event);
    if (!isLastRow || !isForwardKey) return;
    event.event?.preventDefault();
    this.addNewRow(event.column.getColDef().field);
  }

  addNewRow(columnKey?: string) {
    if (!this.allowAddNewRow()) return;
    const newRowModel = this.newRowModel()?.();
    if (!newRowModel) {
      console.error("newRowModel is missing");
      return;
    }
    this.addRowOnBottom(newRowModel, columnKey);
  }
}

export type Column<TCol = any, TData = any> = ColDef<Data, any>;

export interface Data {
  [key: string]: any;
}

export enum CellEditor {
  Text = 1,
  Number,
  Checkbox,
  Textarea,
}

export interface LoadPageArgs {
  pageNumber: number;
  pageSize: number;
}

export type TableCellKeyDownEvent = CellKeyDownEvent<Data, any> | FullWidthCellKeyDownEvent<Data, any>;