import { inject } from "@angular/core";
import { ValidatorFunction } from "src/app/models/validator";
import { CreditCardService } from "src/app/services/credit-card.service";

export function cardNumberValidator(): ValidatorFunction {
  const creditCardService = inject(CreditCardService);
  return function (control) {
    if (!control.touched || !control.dirty) return null;
    const value = control.value;
    if (!value) {
      return { error: "Credit card number is required" };
    }
    if (!creditCardService.isValid(value)) {
      return { error: "Invalid credit card number" };
    }
    return null;
  };
}