<app-input
  [label]="label()"
  [value]="value()?.label ?? ''"
  (valueChange)="inputValueChange($event)"
  [autocomplete]="'off'"
  [loading]="loading()"
/>
<div
  class="absolute top-[calc(100%+1px)] left-0 overflow-hidden transition-[max-height] ease-in-out duration-300 text-[var(--normal)] shadow-card w-full rounded-br-lg rounded-bl-lg z-40 bg-white {{
    optionsOpen() ? 'max-h-52 overflow-y-auto' : 'max-h-0'
  }}"
>
  @for (item of selectOptions(); track $index; let index = $index) {
    <div
      class="px-4 py-2 hover:bg-[var(--highlight)] focus:bg-[var(--highlight)] transition-colors rounded mx-1 overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer {{
        item.dropdownItemStyleClass ?? ''
      }}"
      (click)="onSelect(item)"
      [attr.data-key]="index"
      [tabIndex]="index"
      #dropdownItem
    >
      @if (item.emoji) {
        <span class="mr-0.5">{{ item.emoji }}</span>
      }
      {{ item.label }}
    </div>
  }
</div>
