import { ValidatorFn } from "@angular/forms";

const MIN_PASSWORD_LENGTH = 8;

export function passwordValidator(): ValidatorFn {
  return function (control) {
    if (!control.touched || !control.dirty) return null;
    const password = control.value;
    if (password.length < MIN_PASSWORD_LENGTH) {
      return {
        error: "Password must be at least 8 characters long",
      };
    }
    if (!/\d/.test(password)) {
      return {
        error: "Password must contain at least one number",
      };
    }
    if (!/[a-zA-Z\W]/.test(password)) {
      return {
        error: "Password must contain at least on alphabetical or special character"
      };
    }
    return null;
  };
}