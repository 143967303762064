import { inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  platform = inject(Platform);

  isMobile() {
    return this.platform.is('android') || this.platform.is('ios') || this.platform.is('mobile') || this.platform.is('mobileweb');
  }

  isDesktop() {
    return !this.isMobile();
  }
}
