<app-navbar
  [hideLogo]="true"
  [menuItems]="menuItems()"
  [popoverButtons]="popoverButtons()"
/>
<div class="mobile:hidden mobile:px-6 px-12 pt-6">
  <div class="max-w-screen-lg">
    <h1 class="text-2xl font-sherika-bold">Update user</h1>
  </div>
</div>
<ion-header class="hidden mobile:block">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/admin/users" />
    </ion-buttons>
    <ion-title>Update user</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  @if (user(); as u) {
    <div class="mobile:p-0 px-8">
      <div class="flex flex-col gap-4 ion-padding py-6">
        <div class="flex items-center gap-1">
          <ion-icon
            name="person-circle-outline"
            class="text-6xl"
            color="medium"
          ></ion-icon>
          <div class="flex flex-col">
            <ion-label color="dark"
              >{{ u.full_name }}
              {{ isLoggedInUser() ? '(You)' : '' }}</ion-label
            >
            <ion-note color="medium" class="text-sm self-center">{{
              u.email
            }}</ion-note>
          </div>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex items-center horizontal-scroll">
            <div
              class="flex items-center overflow-x-auto snap-x snap-mandatory overscroll-x-contain chip-container"
              style="-webkit-overflow-scrolling: touch"
            >
              <ion-chip [outline]="true">
                @if (u.role === Role.Admin) {
                  <ion-icon name="cog-outline"></ion-icon>
                  <ion-label>Admin user</ion-label>
                } @else if (u.role === Role.Staff) {
                  <ion-icon name="people-outline" />
                  <ion-label>Koshery staff</ion-label>
                } @else {
                  <ion-icon name="person-circle-outline"></ion-icon>
                  <ion-label>Regular user</ion-label>
                }
              </ion-chip>
              <ion-chip
                [color]="isBanned() ? 'danger' : undefined"
                [outline]="true"
              >
                @if (isBanned()) {
                  <ion-icon color="danger" name="ban-outline"></ion-icon>
                  <ion-label>Banned</ion-label>
                } @else {
                  <ion-icon name="checkmark-outline"></ion-icon>
                  <ion-label>Active</ion-label>
                }
              </ion-chip>
              <ion-chip [outline]="true">
                <ion-icon name="pricetag-outline"></ion-icon>
                <ion-label>{{ u.pages ?? 0 }} pages</ion-label>
              </ion-chip>
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (!isLoggedInUser()) {
      <ion-list
        [inset]="false"
        class="ion-padding-end max-w-xl mobile:p-0 px-8"
      >
        <ion-item
          (click)="toggleUserBan()"
          [button]="true"
          [disabled]="banning()"
        >
          @if (isBanned()) {
            <ion-label color="primary">Unban user</ion-label>
          } @else {
            <ion-label color="danger">Ban user</ion-label>
          }
        </ion-item>
        <ion-item [button]="true" (click)="openActionSheet.set(true)">
          <ion-label>Change role</ion-label>
        </ion-item>
      </ion-list>
    }
  }
  <ion-action-sheet
    [isOpen]="openActionSheet()"
    (didDismiss)="actionSheetResults($event)"
    header="Update user role. Note that this action takes effect immediately"
    [buttons]="getUserRoleActionSheetButtons()"
  ></ion-action-sheet>
</ion-content>
