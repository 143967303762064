import { Component, ElementRef, forwardRef, inject, input, model, OnInit, output, signal, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor {
  label = input<string>();
  htmlFor = input<string>();
  value = model<string>('');
  errorMessage = input<string>();
  successMessage = input<string>();
  note = input<string>();
  type = input<InputType>();
  isRequired = input<boolean>();
  inputStyleClass = input<string>();
  autocomplete = input<AutoFill>('on');
  loading = input(false);
  placeholder = input<string>('');
  icon = input<string>();
  readonly MAX_LENGTH_DEFAULT = 524288;
  maxLength = input<number | undefined>(this.MAX_LENGTH_DEFAULT);
  disabled = model<boolean>();
  inputMode = input<InputMode>();
  inputElement = viewChild<ElementRef<HTMLInputElement>>('inputElement');
  onEnter = output<void>();

  showPassword = signal(false);

  constructor() {
  }
  onChange = (value: string) => { };
  onTouched = () => { };
  writeValue(value: string): void {
    this.value.set(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.value.set(value);
    this.onChange(value);
    this.onTouched();
  }

  focus() {
    this.inputElement()?.nativeElement.focus();
  }

  handleEnter(event: Event) {
    event.preventDefault();
    this.onEnter.emit();
  }
}

type InputType = 'text' | 'number' | 'email' | 'password' | 'tel' | 'url';
type InputMode = 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';