import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { PlatformService } from 'src/app/services/platform.service';
import { SessionService } from 'src/app/services/session.service';
import { PopoverButton } from '../ui/popover-buttons/popover-buttons.component';
import { AuthService } from 'src/app/services/auth.service';
import { filter, interval, Subscription } from 'rxjs';
import { ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { wait } from 'src/app/utils/async';
import { VisibilityService } from 'src/app/services/visibility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, ViewDidEnter, ViewDidLeave {
  platformService = inject(PlatformService);
  sessionService = inject(SessionService);
  authService = inject(AuthService);
  visibilityService = inject(VisibilityService);

  menuItems = computed<MenuItem[]>(() => [
    { label: "Business dashboard", href: "/dashboard/pages" },
    { label: "Profile", href: "/user/account/profile", icon: "person-circle-outline" },
    { label: "Support", href: "/user/account/feedback", icon: "help-circle-outline" },
  ]);
  popoverButtons = computed<PopoverButton[]>(() => {
    const items: PopoverButton[] = [
      { label: "Profile", icon: "person-circle-outline", href: "/user/account/profile" },
      { label: "Support", icon: "help-circle-outline", href: "/user/account/feedback" },
      { isDivider: true },
    ];
    items.push({ label: "Log out", action: () => this.authService.logout(), icon: "power-outline" });
    return items;
  });

  placeholderOptions = [
    "Wedding halls",
    "Grocery stores",
    "Barber shops",
    "One man band",
    "Kitchen designer",
    "Clean my shtreimel",
    "Kids shoes for shabbos",
    "Beis hamedresh",
  ];
  isRunning = signal(false);
  placeholderIndex = signal<number>(0);
  placeholderRotator$ = interval(2_000);
  placeholder = signal<string>(this.placeholderOptions[this.placeholderOptions.length - 1]!);
  subscription = new Subscription();

  searchValue = signal<string>('');

  ngOnInit(): void {
    this.beginRotation();
  }

  ionViewDidEnter(): void {
    this.beginRotation();
  }

  ionViewDidLeave(): void {
    this.subscription.unsubscribe();
    this.isRunning.set(false);
  }

  beginRotation() {
    if (this.isRunning()) return;
    this.subscription = new Subscription();
    this.subscription.add(this.placeholderRotator$.pipe(
      filter(() => !this.searchValue().length),
      // we need to filter out when this tab (or app) loses focus or is minimized. 
      // Otherwise, JavaScript will slow down this timer, and when the user comes
      // back, it will try to "catch up", pushing in all delayed executions at once...
      filter(() => this.visibilityService.visible()),
    ).subscribe(() => this.rotatePlaceholders()));
    this.isRunning.set(true);
  }

  async rotatePlaceholders() {
    this.placeholder.set('');
    const currentPlaceholderIndex = this.placeholderIndex();
    const currentPlaceholderOption = this.placeholderOptions[currentPlaceholderIndex] ?? '';
    for (const char of currentPlaceholderOption) {
      const existingPlaceholder = this.placeholder();
      const newPlaceholder = existingPlaceholder + char;
      this.placeholder.set(newPlaceholder);
      await wait(66);
    }
    const newPlaceholderIndex = (currentPlaceholderIndex + 1) % this.placeholderOptions.length;
    this.placeholderIndex.set(newPlaceholderIndex);
  }

  searchValueChanged(event: Event) {
    const el = event.target as HTMLInputElement;
    this.searchValue.set(el.value);
  }
}
