<ion-icon [name]="icon()" class="text-muted text-2xl" />
<span class="text-nowrap overflow-hidden mobile:w-full shrink text-sm">{{
  label()
}}</span>
<span
  class="flex items-center justify-end gap-1 mr-0 ml-auto shadow-[-4px_0px_16px_#ffffffbb]"
>
  <ion-icon
    [name]="'copy-outline'"
    class="p-2 bg-gray-50 transition-colors hover:bg-black/5 active:bg-black/10 rounded-full cursor-pointer text-lg mobile:text-[1rem] text-black shadow-sm"
    (click)="copyToClipboard($event)"
  />
</span>
