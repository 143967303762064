import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {

  getCardType(cardNumber: string | null | undefined): CardType | undefined {
    if (!cardNumber) return;
    if (cardNumber.startsWith('4')) return CardType.Visa;
    if (cardNumber.startsWith('6')) return CardType.Discover;
    const firstTwoDigits = cardNumber.slice(0, 2);
    if (firstTwoDigits === '34' || firstTwoDigits === '37') return CardType.AmericanExpress;
    if (cardNumber.startsWith('2') || cardNumber.startsWith('5')) return CardType.Mastercard;
    return undefined;
  }

  format(ccNumber: string, overrideCardType?: CardType) {
    const cardType = overrideCardType ?? this.getCardType(ccNumber);
    if (!cardType) return '';
    if (cardType === CardType.AmericanExpress) {
      return this.formatAmex(ccNumber);
    }
    return this.formatGeneral(ccNumber);
  }

  formatAmex(ccNumber: string): string {
    ccNumber = this.parse(ccNumber);
    const firstFour = ccNumber.slice(0, 4);
    const secondSix = ccNumber.slice(4, 10);
    const lastFive = ccNumber.slice(10, 15);
    let results = '';
    if (firstFour) {
      results = firstFour;
    }
    if (secondSix) {
      results += ` ${secondSix}`;
    }
    if (lastFive) {
      results += ` ${lastFive}`;
    }
    return results;
  }

  formatGeneral(ccNumber: string): string {
    ccNumber = this.parse(ccNumber);
    ccNumber = ccNumber.slice(0, 16);
    const parts = ccNumber.match(/(\d|\*){1,4}/g);
    return parts?.join(' ') ?? '';
  }

  parse(ccNumber: string) {
    return ccNumber.replace(/\s+/g, '');
  }

  isValid(ccNumber: string | null | undefined): boolean {
    if (!ccNumber) return false;
    let cardType = this.getCardType(ccNumber);
    if (!cardType) return false;
    if (cardType === CardType.AmericanExpress) {
      return this.isValidAmex(ccNumber);
    }
    return this.isValidGeneral(ccNumber);
  }

  isValidAmex(ccNumber: string): boolean {
    ccNumber = this.parse(ccNumber);
    return ccNumber.length === 15;
  }

  isValidGeneral(ccNumber: string): boolean {
    ccNumber = this.parse(ccNumber);
    return ccNumber.length === 16;
  }
}

export enum CardType {
  Visa = "visa",
  Mastercard = "mastercard",
  Discover = "discover",
  AmericanExpress = "amex",
}