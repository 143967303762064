import { AfterViewInit, Component, computed, effect, inject, model, OnInit, signal } from '@angular/core';
import { Step } from './stepper/stepper.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { emailValidator } from '../../validators/email';
import { QueryParamsService } from 'src/app/services/query-params.service';
import { EntityEditCustomFields, EntityEditForm, EntityEditFormValue, OperatingHour, RichTextEditor, Tag } from 'src/app/models/entity/edit/form';
import { registerClassOnWindow } from 'src/app/utils/global';
import { FileService, UploadArgs } from 'src/app/services/file.service';
import { DbAppUser, SessionService } from 'src/app/services/session.service';
import { ValidatorFunction } from 'src/app/models/validator';
import { urlValidator } from '../../validators/url';
import { toNumber } from 'src/app/utils/number';
import { SupabaseService } from 'src/app/services/supabase.service';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { requiredValidator } from '../../validators/required';
import { isUsernameAvailable } from '../../validators/username';
import { DateService } from 'src/app/services/date.service';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { ToastService } from 'src/app/services/toast.service';
import { ApiService } from 'src/app/services/api.service';
import { CustomFieldType } from 'src/app/models/entity/custom-field';
import { copyObject, deleteProperty } from 'src/app/utils/object';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, combineLatest, filter, firstValueFrom, map, of, Subscription, switchMap, tap } from 'rxjs';
import { DisplayMedia } from 'src/app/models/image';
import { AddressService } from 'src/app/services/address.service';
import { ImagePicker } from 'src/app/models/entity/edit/form';
import { CustomFieldService } from 'src/app/services/custom-field.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EntityEditService } from 'src/app/services/entity-edit.service';
import { maxLengthValidator } from '../../validators/max-length';
import { wait } from 'src/app/utils/async';
import { VideoService } from 'src/app/services/video.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ImageService } from 'src/app/services/image.service';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'app-entity-edit',
  templateUrl: './entity-edit.component.html',
  styleUrls: ['./entity-edit.component.scss'],
})
export class EntityEditComponent implements OnInit, AfterViewInit, ViewDidLeave, ViewWillEnter {

  queryParamsService = inject(QueryParamsService);
  sessionService = inject(SessionService);
  supabaseService = inject(SupabaseService);
  dateService = inject(DateService);
  toastService = inject(ToastService);
  apiService = inject(ApiService);
  fileService = inject(FileService);
  imageService = inject(ImageService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  addressService = inject(AddressService);
  customFieldService = inject(CustomFieldService);
  entityEditService = inject(EntityEditService);
  videoService = inject(VideoService);
  loggingService = inject(LoggingService);

  steps = computed<Step[]>(() => {
    const user = this.sessionService.account() as DbAppUser;
    const stepsToShow: Step[] = [];
    stepsToShow.push(
      { label: "General information", description: "Enter the basics of your business", displayOrder: 1 },
      { label: "Custom boxes", description: "Design your page to your liking!", displayOrder: 3 },
      { label: "Hours", description: "When your business opens and closes", displayOrder: 2 },
    );
    stepsToShow.sort((a, b) => a.displayOrder - b.displayOrder);
    return stepsToShow;
  });

  form = new FormGroup<EntityEditForm>({
    general: new FormGroup({
      name: new FormControl('', [requiredValidator('Page name'), maxLengthValidator(25)]),
      username: new FormControl('', [requiredValidator("Username"), maxLengthValidator(20)], [isUsernameAvailable()]),
      website: new FormControl('', [urlValidator(false), maxLengthValidator(35)]),
      description: new FormControl('', [maxLengthValidator(500)]),
      phoneNumber: new FormControl('', [maxLengthValidator(15), requiredValidator("Phone number")]),
      email: new FormControl('', [emailValidator(false), maxLengthValidator(50)]),
      address: new FormControl({}),
      avatar: new FormControl<UploadArgs[] | null>([]),
      hideFromSearch: new FormControl(false),
      profilePhoto: new FormControl<UploadArgs | null>(null),
      isUnclaimed: new FormControl<boolean | null>(null),
    }),
    hours: new FormGroup({
      days: new FormArray([
        ...this.dateService.daysOfTheWeek().map<FormGroup<OperatingHour>>((day, index) => new FormGroup({
          day: new FormControl(index),
          openingTime: new FormControl(),
          closingTime: new FormControl(),
        }))
      ]),
    }),
    search: new FormGroup({
      tags: new FormControl<Tag[] | null>([]),
    }),
    customFields: new FormArray<FormGroup<EntityEditCustomFields>>([])
  });
  deletedFieldIds: number[] = [];

  activeIndex = computed(() => this.queryParamsService.queryParams().activeIndex);
  activeIndexChanged$ = toObservable(this.activeIndex).pipe(takeUntilDestroyed());
  activeIndexNum = computed(() => toNumber(this.activeIndex()));
  modelLoading = signal(false);
  entityId$ = this.activatedRoute.params.pipe(
    map(params => toNumber(params['entityId'])),
    filter(entityId => !!entityId),
  );
  entityId = toSignal(this.entityId$);
  modelLoaded$ = combineLatest([this.sessionService.sessionChanged$, this.entityId$]).pipe(
    filter(([session, entityId]) => !!session),
    filter(() => !this.form.value.general?.username),
    tap(() => this.modelLoading.set(true)),
    switchMap(([session, entityId]) => this.apiService.get<EntityEditFormValue>(`entity/read?entityId=${entityId}`)),
    tap(() => this.modelLoading.set(false)),
    catchError(e => {
      console.error(e);
      this.toastService.error(e.message ?? "There was an error loading this page.");
      this.loggingService.error(e);
      this.modelLoading.set(false);
      return of(null);
    })
  );

  subscriptions: Subscription = new Subscription();

  saving = signal(false);
  isAlwaysOpen = model(false);
  initialized = signal(false);

  constructor() {
    registerClassOnWindow('EntityEditComponent', this);

    effect(() => {
      const isAlwaysOpen = this.isAlwaysOpen();
      if (isAlwaysOpen) {
        this.entityEditService.alwaysOpenChange(this.form, isAlwaysOpen);
      }
    }, { allowSignalWrites: true });
  }

  init() {
    if (this.initialized()) return;
    this.subscriptions.add(this.activeIndexChanged$.subscribe(index => this.handleOutOfRangeActiveIndex(index)));
    this.subscriptions.add(this.customFieldService.customFieldDeleted.subscribe(fieldId => this.deletedFieldIds.push(fieldId)));
    this.subscriptions.add(this.modelLoaded$.subscribe(model => this.setModel(model?.data!)));
    this.initialized.set(true);
  }

  ngOnInit() {
    this.init();
  }

  ionViewWillEnter(): void {
    this.init();
  }

  ngAfterViewInit() {
    this.ensureActiveIndex();
  }

  ionViewWillLeave() {
    this.subscriptions.unsubscribe();
    this.initialized.set(false);
  }

  ionViewDidLeave() {
    this.setModel(null);
  }

  private ensureActiveIndex() {
    const activeIndex = this.activeIndex();
    if (!activeIndex) {
      this.setActiveIndex('0');
    }
  }

  validateEntityName(isRequiredResolver?: () => boolean, controlName?: string): ValidatorFunction {
    return function (control) {
      if (!control.touched || !control.dirty) return null;
      if (!control.value && isRequiredResolver?.()) {
        return { error: controlName ? `${controlName} is required` : "Please enter a value" };
      }
      return null;
    };
  }

  incrementActiveIndex() {
    let activeIndexNum = toNumber(this.activeIndex());
    activeIndexNum++;
    this.setActiveIndex(activeIndexNum);
  }

  decrementActiveIndex() {
    let activeIndexNum = toNumber(this.activeIndex());
    activeIndexNum--;
    this.setActiveIndex(activeIndexNum);
  }

  next() {
    const account = this.sessionService.account();
    const isStaff = this.sessionService.isStaffAccount(account);
    const activeIndex = this.activeIndex();
    if (!isStaff) {
      this.incrementActiveIndex();
      return;
    }
    if (activeIndex === '0' || activeIndex === '1') {
      this.setActiveIndex('2');
    } else if (activeIndex === '2') {
      this.save();
    }
  }

  back() {
    const account = this.sessionService.account();
    const isStaff = this.sessionService.isStaffAccount(account);
    const activeIndex = this.activeIndex();
    if (!isStaff) {
      this.decrementActiveIndex();
      return;
    }
    if (activeIndex === '3') { // customField
      this.decrementActiveIndex(); // go to tags
    } else if (activeIndex === '2' || activeIndex === '1') {
      this.setActiveIndex('0');
    }
  }

  setActiveIndex(value: string | number) {
    if (typeof value === 'number')
      value = value.toString();
    this.queryParamsService.set('activeIndex', value);
  }

  handleOutOfRangeActiveIndex(index: string | undefined) {
    const indexNum = toNumber(index);
    if (!indexNum) return;
    if (indexNum < 0 || indexNum >= 4) {
      this.queryParamsService.set('activeIndex', '0');
    };
    this.tryScrollToCustomField();
  }

  private async validateForm() {
    markAllControlsAsTouchedAndDirty(this.form);
    this.form.controls.customFields.updateValueAndValidity(); // need to recalibrate the form array
    if (this.form.status === 'PENDING') {
      await firstValueFrom(
        this.form.statusChanges.pipe(
          filter(status => status === 'VALID' || status === 'INVALID'),
        ),
      );
    }
    if (this.form.valid) return true;
    if (!this.form.controls.general.valid) {
      this.setActiveIndex('0');
    } else if (!this.form.controls.hours.valid) {
      this.setActiveIndex('1');
    } else {
      this.setActiveIndex('2');
    }
    this.toastService.error("Please fix the errors in order to continue");
    return false;
  }

  private async uploadAvatar(formValue: EntityEditFormValue) {
    const avatar = formValue.general?.avatar?.[0];
    if (!avatar?.file) return; // will check whether it was deleted on the server.
    if (avatar.objectName) {
      const { data: exists } = await this.supabaseService.supabase.storage.from('avatar').exists(avatar.objectName);
      if (exists) return;
    }
    try {
      const resizedImage = await this.fileService.resizeImage(avatar.file, 1);
      const response = await this.fileService.resumableUpload({ bucketName: 'avatar', file: resizedImage });
      avatar.objectName = response.objectName;
      deleteProperty(avatar, 'file');
    } catch (e) {
      console.error(e);
      this.toastService.error("Error uploading avatar");
      this.loggingService.error("Error uploading avatar. Error code 1107", e);
    }
  }

  async uploadProfilePhoto(formValue: EntityEditFormValue) {
    const profilePhoto = formValue.general?.profilePhoto;
    if (!profilePhoto?.file) return;
    if (profilePhoto.objectName) { // TODO check if the upload args have a file_reference_id...
      const { data: exists } = await this.supabaseService.supabase.storage.from('profile-photo').exists(profilePhoto.objectName);
      if (exists) return;
    }
    try {
      const resizedImage = await this.imageService.compress(profilePhoto.file, 100);
      const response = await this.fileService.resumableUpload({ bucketName: 'profile-photo', file: resizedImage });
      profilePhoto.objectName = response.objectName;
      deleteProperty(profilePhoto, 'file');
    } catch (e) {
      console.error(e);
      this.toastService.error("Error uploading profile photo");
      this.loggingService.error("Error uploading profile photo. Error code 1106", e);
    }
  }

  handleAlwaysOpen(formValue: EntityEditFormValue) {
    if (!this.isAlwaysOpen()) return;
    formValue.hours?.days?.forEach(d => {
      d.openingTime = 0;
      d.closingTime = 0;
    });
  }

  async save() {
    try {
      if (this.saving()) return;
      this.customFieldService.deleteUnnecessaryCustomFieldProperties(this.form.value);
      this.saving.set(true);
      const valid = await this.validateForm();
      if (!valid) {
        this.saving.set(false);
        return;
      };
      const value = copyObject(this.form.getRawValue());
      const submissionValue: EntityEditFormValue & { deletedFieldIds: number[]; userId: string; } = {
        deletedFieldIds: this.deletedFieldIds,
        userId: this.sessionService.account()?.user_id!,
        customFields: value.customFields,
        general: value.general,
        hours: value.hours,
        search: value.search,
        entityId: this.entityId(),
      };
      await this.customFieldService.uploadCustomFieldImages(submissionValue);
      await this.customFieldService.uploadVideos(submissionValue);
      await this.uploadAvatar(submissionValue);
      await this.uploadProfilePhoto(submissionValue);
      this.handleAlwaysOpen(submissionValue);
      const response = await this.apiService.postAsync<{ entityId: number; }, EntityEditFormValue>('/entity/save', {
        body: submissionValue
      });
      this.deletedFieldIds.length = 0;
      const entityId = response.data.entityId;
      this.router.navigateByUrl(`/dashboard/pages/${entityId}`);
    } catch (e) {
      const error = e as HttpErrorResponse;
      console.error(error);
      this.toastService.error(error.error?.message ?? error.error ?? "Unknown error saving your page");
      this.loggingService.error(error);
    } finally {
      this.saving.set(false);
    }
  }

  async loadAvatar(model: EntityEditFormValue) {
    const image = model.general?.avatar?.[0];
    const objectName = image?.objectName;
    if (!objectName) return;
    image.file = await this.fileService.createFileFromObjectName(objectName, 'avatar');
  }

  async loadProfilePhoto(model: EntityEditFormValue) {
    const image = model.general?.profilePhoto;
    const objectName = image?.objectName;
    if (!objectName) return;
    image.file = await this.fileService.createFileFromObjectName(objectName, 'profile-photo');
  }

  async loadCustomFieldImages(model: EntityEditFormValue) {
    const customFields = model.customFields || [];
    const customFieldImages = customFields
      .filter(cf => cf.fieldType === CustomFieldType.Images)
      .map(cf => cf.imagePicker?.value)
      .filter(cfi => cfi !== null && cfi !== undefined)
      .flatMap(cfi => cfi) as DisplayMedia[]; // the ng compiler throws a tantrum without as DisplayImage[].
    for (const image of customFieldImages) {
      if (!image.objectName || !image.bucketName) {
        this.loggingService.error("Image objectName or bucketName is missing. Error code 1108");
        continue;
      };
      image.originalFile = await this.fileService.createFileFromObjectName(image.objectName, image.bucketName);
      image.src = await this.fileService.fileToUrl(image.originalFile);
    }
  }

  async prepareModel(model: EntityEditFormValue) {
    await this.loadAvatar(model);
    await this.loadProfilePhoto(model);
    await this.loadCustomFieldImages(model);
    return model;
  }

  async setModel(model: EntityEditFormValue | null) {
    if (!model) {
      this.form.reset();
      return;
    };
    await this.prepareModel(model);

    const operatingHoursAlwaysOpen = !!model.hours?.days?.every(h => h.openingTime === 0 && h.closingTime === 0);
    this.isAlwaysOpen.set(operatingHoursAlwaysOpen);

    this.form.controls.customFields.clear();

    const customFields = model.customFields ?? [];
    this.form.controls.customFields.clear();
    for (const field of customFields) {
      const newFormControl = new FormGroup<EntityEditCustomFields>({
        fieldType: new FormControl<CustomFieldType | null>(field.fieldType ?? null),
        id: new FormControl(field.id ?? null),
      });
      if (field.fieldType === CustomFieldType.RichTextEditor) {
        newFormControl.addControl('richTextEditor', new FormGroup<RichTextEditor>({
          title: new FormControl<string | null>(field.richTextEditor?.title ?? null),
          value: new FormControl<string | null>(field.richTextEditor?.value ?? null),
        }) as FormGroup<RichTextEditor | null>);
      }
      else if (field.fieldType === CustomFieldType.Images) {
        newFormControl.addControl('imagePicker', new FormGroup({
          title: new FormControl<string | null>(field.imagePicker?.title ?? null),
          value: new FormControl<DisplayMedia[] | null>(field.imagePicker?.value ?? [])
        }) as FormGroup<ImagePicker | null>);
      }
      else if (field.fieldType === CustomFieldType.Videos) {
        newFormControl.addControl('videoPicker', new FormGroup({
          title: new FormControl<string | null>(field.imagePicker?.title ?? null),
          value: new FormControl<DisplayMedia[] | null>(field.videoPicker?.value ?? [])
        }) as FormGroup<ImagePicker | null>);
      }
      this.form.controls.customFields.controls.push(newFormControl);
    }

    this.resolveCustomFieldVideoUrl(model);

    this.form.patchValue(model);
    this.tryScrollToCustomField();
  }

  async tryScrollToCustomField() {
    await wait(100);
    const activeIndex = this.activeIndex();
    if (activeIndex === '2') {
      const id = this.activatedRoute.snapshot.fragment;
      document.getElementById(id!)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  resolveCustomFieldVideoUrl(model: EntityEditFormValue) {
    const customFields = model.customFields
      ?.filter(c => c.fieldType === CustomFieldType.Videos)
      .flatMap(c => c.videoPicker?.value)
      .filter(c => c != null) as DisplayMedia[] | undefined; // there is a mismatch between angular's ts compiler and the installed ts linter. TODO fix this.
    if (!customFields?.length) return;
    for (const field of customFields) {
      if (!field.objectName) continue;
      field.src = this.fileService.getStreamableVideoUrl(field.objectName);
      field.status = 'saved';
      field.thumbnail = this.fileService.getStreamableVideoThumbnailUrl(field.objectName);
    }
  }
}