import { Component, effect, HostListener, inject, input, model } from '@angular/core';
import { LoggingService } from 'src/app/services/logging.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-entity-contact',
  templateUrl: './entity-contact.component.html',
  styleUrls: ['./entity-contact.component.scss'],
})
export class EntityContactComponent {
  toastService = inject(ToastService);
  loggingService = inject(LoggingService);

  url = input.required<string>();
  label = model<string>();
  icon = input.required<string>();
  target = input<'_blank' | undefined>();

  constructor() {
    effect(() => {
      const url = this.url();
      if (!this.label()) {
        this.label.set(url);
      }
    });
  }

  @HostListener('click', ['$event']) onClick(event: Event) {
    this.openUrl();
  }

  async copyToClipboard(event: MouseEvent) {
    event.stopPropagation();
    const text = this.label();
    if (!text) return;
    window.navigator.clipboard.writeText(text)
      .then(() => this.toastService.info("Copied to clipboard"))
      .catch(() => this.loggingService.error("Error copying to clipboard. Error code 1115"));
  }

  openUrl() {
    window.open(this.url(), this.target());
  }
}
