export function copyObject<T extends any = any>(object: T): T {
  try {
    return structuredClone(object);
  } catch (e) {
    console.error(e);
    return JSON.parse(JSON.stringify(object));
  }
}

export function deleteProperty<T extends object>(obj: T, property: keyof T) {
  Reflect.deleteProperty(obj, property);
}

export function strictAssign<T extends {}, S extends T>(target: T, source: S) {
  return Object.assign(source, target);
}