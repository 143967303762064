<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start"> </ion-buttons>
    <ion-title>{{ imageFile() ? 'Edit ' : 'Set ' }} profile image</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">Close</ion-button>
      @if (imageFile()) {
        <ion-button (click)="confirmCrop()" [strong]="true">Confirm</ion-button>
      }
    </ion-buttons>
  </ion-toolbar>
</ion-header>
@if (imageFile(); as img) {
  <div
    class="grow flex items-center justify-center bg-black/85 relative min-h-0 max-h-full"
  >
    @if (!cropperReady()) {
      <div
        class="grow flex items-center justify-center bg-black/85 w-full h-full absolute inset-0"
      >
        <app-loader />
      </div>
    }
    <image-cropper
      [aspectRatio]="1"
      [roundCropper]="true"
      [imageFile]="imageFile()"
      [maintainAspectRatio]="true"
      format="webp"
      (cropperReady)="onCropperReady($event)"
      (loadImageFailed)="handleLoadImageFail()"
      (imageCropped)="cropperChange($event)"
      class="my-auto grow"
    />
  </div>
} @else {
  <div class="p-5 w-full max-w-60 my-auto">
    <input
      type="file"
      name="file"
      [accept]="accepts()"
      hidden
      [id]="htmlFor()"
      (change)="onFileChange($event)"
    />
    <label
      for="koshery-file-upload"
      class="w-full h-auto aspect-square border border-solid border-gray-300 rounded-full flex justify-center items-center cursor-pointer"
      [tabIndex]="0"
      [htmlFor]="htmlFor()"
    >
      <ion-icon
        name="camera-outline"
        size="large"
        class="text-gray-600"
      ></ion-icon>
    </label>
  </div>
}
