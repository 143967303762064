import { Component, computed, forwardRef, inject, input, model, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DisplayMedia } from 'src/app/models/image';
import { FileService } from 'src/app/services/file.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToastService } from 'src/app/services/toast.service';
import { plural } from 'src/app/utils/string';
import { MediaSlideshowComponent } from '../../media-slideshow/media-slideshow.component';
import { VideoService } from 'src/app/services/video.service';
import { MediaType } from 'src/app/models/file';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-multimedia-picker',
  templateUrl: './multimedia-picker.component.html',
  styleUrls: ['./multimedia-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultimediaPickerComponent),
      multi: true,
    }
  ]
})
export class MultimediaPickerComponent implements ControlValueAccessor, OnDestroy {
  fileService = inject(FileService);
  toastService = inject(ToastService);
  modalService = inject(ModalService);
  videoService = inject(VideoService);
  loggingService = inject(LoggingService);

  media = model<DisplayMedia[]>([]);
  id = input.required<string>();
  type = input.required<MediaType>();
  maxMediaAmount = input<number>();
  maxVideoDurationSeconds = input<number>();
  maxVideoFileUploadSizeMb = 80;

  mediaToShow = computed(() => {
    const images = this.media();
    return images?.filter(i => i.status !== 'deleted');
  });
  supportedFileTypes = computed(() => this.fileService.supportedFileFormats());
  supportedMimeTypes = computed(() => {
    const mediaType = this.type();
    const supportedFileExtensions = this.supportedFileTypes()[mediaType].flatMap(t => t.extension);
    let unique = Array.from(new Set(supportedFileExtensions));
    return unique.map(t => `${mediaType}/${t}`);
  });
  supportedMimeTypesString = computed<string>(() => {
    return this.supportedMimeTypes().join(',');
  });

  objectUrls: string[] = [];

  onChange = (value: DisplayMedia[]) => { };
  onTouched = () => { };
  writeValue(value: DisplayMedia[] | undefined): void {
    value ||= [];
    const newValueArray = [...(value), ...(this.media())];
    this.media.set(newValueArray);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy() {
    this.objectUrls.forEach(o => URL.revokeObjectURL(o));
  }

  async addMedia(event: Event) {
    const element = event.target as HTMLInputElement;
    const fileList = element.files;
    if (!fileList?.length) return;
    const files = Array.from(fileList);

    // check if user can add media
    const type = this.type();
    const totalNewMedia = this.media().length + files.length;
    const maxImagesAmount = this.maxMediaAmount();
    if (maxImagesAmount && totalNewMedia > maxImagesAmount) {
      this.toastService.error(`Exceeded maximum of ${maxImagesAmount} ${type}s`);
      return;
    }

    const addedMedias: DisplayMedia[] = [];
    try {
      for (let file of files) {
        if (!this.supportedMimeTypes().includes(file.type)) {
          throw new Error(`File type ${file.type} not supported!`);
        }

        // check if user can add file
        const fileSizeMb = this.fileService.getFileSizeMb(file);
        if (fileSizeMb > this.maxVideoFileUploadSizeMb) {
          throw new Error(`File is too large. Max ${this.maxVideoFileUploadSizeMb}Mb allowed`);
        }

        const src = URL.createObjectURL(file);
        this.objectUrls.push(src);
        const videoThumbnail = type === 'image' ? null : await this.videoService.generateRandomThumbnail(file);
        addedMedias.push({
          originalFile: file,
          src,
          status: 'new',
          thumbnail: videoThumbnail,
        });
      }
    } catch (e) {
      console.error(e);
      const error = e as Error;
      this.toastService.error(error.message);
    }
    element.value = ''; // this is required, so the (change) event fires again if the user adds the same file again.

    const existingMedia = this.media();
    const newImagesArray = [...existingMedia, ...addedMedias];
    this.media.set(newImagesArray);
    this.onChange(newImagesArray);
    this.onTouched();
  }

  deleteMedia(media: DisplayMedia) {
    let existingMedia = this.media();
    const type = this.type();
    if (!media.fileReferenceId) {
      const mediaIndex = existingMedia.indexOf(media);
      if (mediaIndex < 0) {
        console.error(`${type} index is negative`);
        this.loggingService.error(`${type} index is negative. Error 1116`);
        return;
      }
      existingMedia.splice(mediaIndex, 1);
    } else {
      media.status = 'deleted';
    }
    const newMediasArray = [...existingMedia];
    this.media.set(newMediasArray);
    this.onChange(newMediasArray);
    this.onTouched();
  }

  openMediaSlideshow(imageIndex: number) {
    const media = this.media();
    this.modalService.open(MediaSlideshowComponent, {
      componentProps: {
        media: media,
        mediaIndex: imageIndex,
        type: this.type(),
      },
    });
  }
}
