import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillLeave } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { SupabaseService } from 'src/app/services/supabase.service';
import { registerClassOnWindow } from 'src/app/utils/global';
import { requiredValidator } from '../../validators/required';
import { fixedLengthValidator } from '../../validators/fixed-length';
import { ToastService } from 'src/app/services/toast.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoggingService } from 'src/app/services/logging.service';
import { wait } from 'src/app/utils/async';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements ViewWillLeave {
  supabaseService = inject(SupabaseService);
  sessionService = inject(SessionService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  toastService = inject(ToastService);
  authService = inject(AuthService);
  loggingService = inject(LoggingService);

  readonly OTP_LENGTH = 6;
  form = new FormGroup({
    otp: new FormControl('', { validators: [requiredValidator("Code"), fixedLengthValidator(this.OTP_LENGTH)], updateOn: 'blur' }),
  });
  subscription = new Subscription();
  confirming = signal(false);
  resending = signal(false);

  constructor() {
    registerClassOnWindow('ConfirmEmailComponent', this);
    const subscription = this.form.controls.otp.statusChanges.subscribe(status => {
      if (status !== 'VALID') return;
      this.confirmEmail();
    });
    this.subscription.add(subscription);
  }

  ionViewWillLeave() {
    this.subscription.unsubscribe();
  }

  async confirmEmail(event?: MouseEvent) {
    event?.preventDefault();
    if (this.confirming()) return;
    if (window.location.hostname === 'localhost') {
      this.confirming.set(true);
      await this.setEmailConfirmed();
      this.confirming.set(false);
      this.returnUrl();
      return;
    }
    const email = this.sessionService.session()?.user.email;
    if (!email) {
      this.loggingService.error(new Error("Email not found in session. Error code 1104"));
      this.toastService.error("Error code 1100. Please contact support");
      return;
    };
    await wait(0); // need to wait for event loop to update the values into the form.
    const otp = this.form.value.otp;
    if (!otp || otp.length !== this.OTP_LENGTH) return;
    try {
      this.confirming.set(true);
      const { data, error } = await this.authService.verifyOtp(email, otp);
      if (error) {
        this.toastService.error(error.message);
        this.confirming.set(false);
        return;
      }
      await this.setEmailConfirmed();
      this.returnUrl();
      this.sessionService.session.set(data.session);
    } catch (e) {
      this.loggingService.error(e, { email });
      this.toastService.error("An error has occurred. Please contact support");
      this.confirming.set(false); // we don't care to reset this to false if the email confirmation operation was successful.
    }
  }

  private async setEmailConfirmed() {
    const userId = this.sessionService.userId();
    if (!userId) {
      this.loggingService.error(new Error("User id not found in session. Error code 1105"));
      this.toastService.error("Error completing your account. Error code 1101. Please contact support");
      return;
    }
    await this.supabaseService.supabase.from('account').update({ is_email_confirmed: true }).eq('user_id', userId).throwOnError();
  }

  getReturnUrl() {
    const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('authReturnUrl');
    return decodeURIComponent(returnUrl ?? '');
  }

  returnUrl() {
    let returnUrl = this.getReturnUrl();
    if (returnUrl) {
      this.router.navigateByUrl(returnUrl);
    } else {
      this.router.navigateByUrl('/user/upgrade');
    }
  }

  async resendCode(event: MouseEvent) {
    event.preventDefault();
    if (this.resending()) return;
    try {
      const email = this.sessionService.email();
      if (!email) {
        this.toastService.error("An error happened. Code 1102. Please contact support");
        return;
      }
      this.resending.set(true);
      const { error } = await this.authService.sendOtp(email);
      if (error) {
        this.toastService.error(error.message);
      }
      this.toastService.info(`Code sent to ${email}`);
    } catch (e) {
      this.toastService.error("An unknown error happened. Code 1103");
    } finally {
      this.resending.set(false);
    }
  }
}
