import { inject } from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { debounceTime, distinctUntilChanged, map, of, switchMap, take, tap } from "rxjs";
import { AsyncValidatorFunction } from "src/app/models/validator";
import { ApiService } from "src/app/services/api.service";
import { toNumber } from "src/app/utils/number";

export function isUsernameAvailable(): AsyncValidatorFunction {
  const apiService = inject(ApiService);
  const activatedRoute = inject(ActivatedRoute);

  const forbiddenUsernames = [
    "admin",
    "dashboard",
  ];

  const entityId$ = activatedRoute.params.pipe(
    map(params => toNumber(params['entityId'])),
    takeUntilDestroyed()
  );
  const entityId = toSignal(entityId$);
  return function (control) {
    if (!control.touched || !control.dirty) return of(null);
    const username = control.value as string;
    if (!username) return of(null);
    const invalidCharacterPattern = /[^a-zA-Z0-9\-_.~]/;
    if (invalidCharacterPattern.test(username)) {
      return of({
        error: "Username invalid! Please only include alphanumeric characters and - _ ."
      });
    }
    const usernameForCompare = username.trim().toLowerCase();
    if (forbiddenUsernames.includes(usernameForCompare)) {
      return of({
        error: "Forbidden username!"
      });
    }
    return control.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((value) => apiService.get<{ isAvailable: boolean; }>(`entity/is-username-available?username=${value}&entity-id=${entityId() || ''}`)),
      map(res => res.data.isAvailable ? null : { error: "Username is not available" }),
      take(1)
    );
  };
}