import { Component, forwardRef, input, model, OnDestroy, OnInit, signal } from '@angular/core';
import { DropdownOption } from '../dropdown/dropdown.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';
import { registerClassOnWindow } from 'src/app/utils/global';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
  label = input<string>();
  htmlFor = input<string>();
  value = model<DropdownOption | undefined>({ label: '' });
  valueChanged$ = toObservable(this.value);
  loaderFunction = input.required<Loader>();
  loading = signal(false);
  optionsOpen = signal(false);
  selectOptions = signal<DropdownOption[]>([]);
  selectOptionsChanged$ = toObservable(this.selectOptions);
  selectedOption = signal<DropdownOption | undefined>(undefined);
  selectedOptionChanged$ = toObservable(this.selectedOption);
  formalValueJustSet = signal(false);
  debounceDuration = input(300);
  defaultOptionResolver = input<() => Promise<DropdownOption | undefined>>();

  onChange = (value: DropdownOption | undefined | null) => { };
  onTouched = () => { };
  async writeValue(value: DropdownOption | undefined) {
    this.selectedOption.set(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  constructor() {
    this.valueChanged$.pipe(
      filter((value) => {
        if (this.formalValueJustSet()) {
          this.formalValueJustSet.set(false);
          return false;
        };
        this.selectedOption.set(undefined);
        return true;
      }),
      tap(() => this.loading.set(true)),
      switchMap((value) => {
        if (value) {
          return of(value).pipe(debounceTime(this.debounceDuration()));
        } else {
          return of(value);
        }
      }),
      distinctUntilChanged(),
      takeUntilDestroyed(),
    ).subscribe(async value => {
      if (value) {
        const response = await this.loaderFunction()(value.label ?? undefined);
        this.selectOptions.set(response);
      }
      else {
        this.selectOptions.set([]); // setting this to [] also closes the dropdown
      }
      this.loading.set(false);
    });

    this.selectedOptionChanged$.pipe(
      takeUntilDestroyed(),
    ).subscribe(item => {
      this.formalValueJustSet.set(true);
      this.value.set(item);
      this.onChange(item);
      this.selectOptions.set([]);
    });

    this.selectOptionsChanged$.pipe(
      takeUntilDestroyed(),
      map(value => !!value),
    ).subscribe(value => this.optionsOpen.set(value));

    // registerClassOnWindow('AutocompleteComponent', this);
  }

  clearOptions = (event: MouseEvent) => {
    const el = event.target as HTMLElement;
    if (el.closest('app-autocomplete')) return;
    this.selectOptions.set([]);
  };

  ngOnInit(): void {
    document.addEventListener('click', this.clearOptions);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.clearOptions);
  }

  onSelect(item: DropdownOption) {
    this.selectedOption.set(item);
  }

  inputValueChange(value: string) {
    const existingValue = this.value() ?? {};
    existingValue.label = value;
    this.value.set({ ...existingValue });
  }
}

export type Loader = (value: string | undefined) => DropdownOption[] | Promise<DropdownOption[]>;