<app-navbar [menuItems]="menuItems()" [popoverButtons]="popoverButtons()" />
<div
  class="px-8 py-12 max-w-screen-xl mx-auto w-full flex flex-col gap-6 mobile:py-0 mobile:px-0"
>
  <h1 class="text-3xl font-semibold mobile:text-center mobile:hidden">
    Settings
  </h1>
  <ion-header class="hidden mobile:flex">
    <ion-toolbar>
      <ion-title>Settings</ion-title>
    </ion-toolbar>
  </ion-header>
  <hr class="w-full border-none h-px bg-slate-200 mobile:hidden" />
  <div class="flex mobile:flex-col relative">
    <div
      class="flex flex-col mobile:flex-row gap-2 pr-8 mobile:pl-8 mobile:pb-12"
    >
      <button
        [routerLink]="'/user/account/profile'"
        [routerLinkActive]="'bg-gray-100'"
        class="px-3 py-1 transition-colors rounded text-start"
      >
        Profile
      </button>
      <button
        [routerLink]="'/user/account/password'"
        [routerLinkActive]="'bg-gray-100'"
        class="px-3 py-1 transition-colors rounded text-start"
      >
        Password
      </button>
      <button
        [routerLink]="'/user/account/feedback'"
        [routerLinkActive]="'bg-gray-100'"
        class="px-3 py-1 transition-colors rounded text-start"
      >
        Feedback
      </button>
    </div>
    <ion-router-outlet
      [animated]="true"
      [swipeGesture]="true"
      class="relative grow"
    />
  </div>
</div>
