<ion-header>
  <ion-toolbar>
    <ion-title>Admin</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="">
  <ion-list [inset]="true">
    <ion-item [button]="true" [routerLink]="'/admin/users'">
      <ion-icon slot="start" name="people-outline" />
      <ion-label>Users</ion-label>
      <ion-note slot="end">{{ userCount() }}</ion-note>
    </ion-item>
    <!-- <ion-item [button]="true">
      <ion-icon slot="start" name="pricetag-outline"></ion-icon>
      <ion-label>Pages</ion-label>
      <ion-note slot="end">{{ pageCount() }}</ion-note>
    </ion-item> -->
    <ion-item [button]="true" [routerLink]="'/admin/export'">
      <ion-icon slot="start" name="cloud-download-outline"></ion-icon>
      <ion-label>Export</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
