import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController, ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { catchError, combineLatest, filter, finalize, map, of, startWith, Subject, Subscription, switchMap, tap } from 'rxjs';
import { LoggingService } from 'src/app/services/logging.service';
import { SessionService } from 'src/app/services/session.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SupabaseService } from 'src/app/services/supabase.service';
import { ToastService } from 'src/app/services/toast.service';
import { toNumber } from 'src/app/utils/number';
import { requiredValidator } from '../../validators/required';
import { ApiService } from 'src/app/services/api.service';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-entity-claim-verify',
  templateUrl: './entity-claim-verify.component.html',
  styleUrls: ['./entity-claim-verify.component.scss'],
})
export class EntityClaimVerifyComponent implements OnInit, ViewWillEnter, ViewDidLeave {
  activatedRoute = inject(ActivatedRoute);
  sessionService = inject(SessionService);
  supabaseService = inject(SupabaseService);
  toastService = inject(ToastService);
  loggingService = inject(LoggingService);
  subscriptionService = inject(SubscriptionService);
  apiService = inject(ApiService);
  router = inject(NavController);

  entityId$ = this.activatedRoute.params.pipe(
    map(params => toNumber(params['entityId'])),
  );
  entityId = toSignal(this.entityId$);
  reload$ = new Subject<void>();
  loading = signal(false);
  model$ = combineLatest([
    this.sessionService.sessionChanged$.pipe(startWith(null)),
    this.entityId$.pipe(startWith(null)),
    this.reload$.pipe(startWith(null)),
  ]).pipe(
    filter(([session, entityId]) => !!session && !!entityId),
    filter(() => !this.loading()),
    tap(() => this.loading.set(true)),
    switchMap(([_, entityId]) => this.apiService.get<EntityReadClaim>(`/entity/get-for-claim?entityId=${entityId}`)),
    map(model => model.data),
    tap(() => this.loading.set(false)),
    filter(model => {
      if (!model.isUnclaimed) {
        this.toastService.info("This page is already yours");
        return false;
      }
      return true;
    }),
    catchError(e => {
      this.toastService.error("An error has occurred");
      this.loggingService.error("Error loading entity", e);
      return of(null);
    }),
    finalize(() => this.loading.set(false))
  );
  model = signal<EntityReadClaim | null | undefined>(undefined);
  emailAddress = computed(() => this.model()?.emailAddress);

  state = signal<ClaimState>('start');

  otpForm = new FormGroup({
    otp: new FormControl('', [requiredValidator("Code")]),
  });

  sendingCode = signal(false);
  verifyingCode = signal(false);

  initialized = signal(false);
  subscription = new Subscription();
  ngOnInit(): void {
    this.init();
  }

  ionViewWillEnter(): void {
    this.init();
  }

  ionViewDidLeave(): void {
    this.initialized.set(false);
    this.subscription.unsubscribe();
  }

  init() {
    if (this.initialized()) return;
    if (this.subscription.closed) {
      this.subscription = new Subscription();
    }
    this.subscription.add(this.model$.subscribe(model => this.model.set(model)));
    this.reload$.next();
    this.initialized.set(true);
  }

  nextState() {
    const currentState = this.state();
    if (currentState === 'start') {
      this.state.set('enter-otp');
    } else if (currentState === 'enter-otp') {
      this.state.set('confirmation');
    }
  }

  previousState() {
    const currentState = this.state();
    if (currentState === 'enter-otp') {
      this.state.set('start');
    } else if (currentState === 'start') {
      this.router.back();
    }
  }

  async sendCode() {
    if (this.sendingCode()) return;
    try {
      const entityId = this.entityId();
      if (!entityId) {
        this.loggingService.error("Entity id missing in order to send code");
        this.toastService.error("There was an error sending a code");
        return;
      }
      this.sendingCode.set(true);
      const response = await this.apiService.postAsync<unknown, SendClaimsOtpArgs>('/entity/send-claim-otp', { body: { entityId } });
      this.toastService.info(response.message ?? "Code sent successfully");
      this.nextState();
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message ?? "There was an error emailing a code");
      this.loggingService.error("Error emailing code", e);
    } finally {
      this.sendingCode.set(false);
    }
  }

  async verifyCode() {
    if (this.verifyingCode()) return;
    try {
      const entityId = this.entityId();
      if (!entityId) {
        this.loggingService.error("Entity id missing in order to send code");
        this.toastService.error("There was an error sending a code");
        return;
      }
      markAllControlsAsTouchedAndDirty(this.otpForm);
      if (this.otpForm.invalid) return;
      const otp = this.otpForm.value.otp;
      if (!otp) return;
      this.verifyingCode.set(true);
      const response = await this.apiService.postAsync<unknown, VerifyClaimArgs>('/entity/verify-claim-otp', { body: { entityId, code: otp } });
      this.toastService.success(response.message ?? "Page transferred successfully");
      this.nextState();
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message ?? "Error verifying code");
    } finally {
      this.verifyingCode.set(false);
    }
  }

  goToPage() {
    const entityId = this.entityId();
    this.router.navigateForward(`/dashboard/pages/${entityId}/edit`);
  }
}

export type ClaimState = 'start' | 'enter-otp' | 'confirmation';

export interface EntityReadClaim {
  isUnclaimed: boolean | null;
  emailAddress: string | null;
  pageName: string | null;
}

export interface SendClaimsOtpArgs {
  entityId: number;
}

export interface VerifyClaimArgs {
  entityId: number;
  code: string;
}