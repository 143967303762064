<div
  class="flex items-center justify-between mobile:flex-col mobile:items-start gap-10 relative"
>
  <app-entity-edit-header
    [title]="'Operating Hours'"
    [description]="'Set your opening and closing hours'"
    [backUrl]="'/dashboard/pages/' + entityId() + '/edit?activeIndex=0'"
  >
    @if (!entityEditComponent.modelLoading()) {
      <app-switch
        [(value)]="isAlwaysOpen"
        (valueChange)="alwaysOpenChange($event)"
        [label]="'Always open'"
        [styleClass]="'text-xl'"
        class="mobile:px-6 px-10 action"
      />
    }
  </app-entity-edit-header>
  @if (!entityEditComponent.modelLoading()) {
    <app-switch
      [(value)]="isAlwaysOpen"
      (valueChange)="alwaysOpenChange($event)"
      [label]="'Always open'"
      [styleClass]="'text-xl'"
      class="mobile:px-6 px-10 action hidden mobile:block"
    />
  }
</div>
<app-entity-edit-wrapper>
  @if (entityEditComponent.modelLoading()) {
    <app-loader class="my-auto" />
  } @else {
    <div
      [formGroup]="hoursForm()"
      class="flex flex-col gap-4 mobile:gap-6 small-tablet:gap-6 max-w-screen-lg"
    >
      @for (dayFormGroup of hoursForm().controls.days.controls; track $index) {
        <div
          class="flex gap-2 mobile:flex-col small-tablet:flex-col mobile:items-stretch small-tablet:items-stretch items-center"
        >
          <div class="text-xl basis-1/4">
            {{ dateService.daysOfTheWeek()[dayFormGroup.controls.day.value!] }}
          </div>
          <div
            class="flex flex-wrap basis-3/4 mobile:basis-full small-tablet:basis-full grow gap-4"
          >
            <app-dropdown
              [selectOptions]="timeDropdownOptions()"
              [label]="'Opening time'"
              [allowClear]="true"
              [formControl]="dayFormGroup.controls.openingTime"
              class="basis-[calc(50%-0.5rem)] min-w-48 grow"
            />
            <app-dropdown
              [selectOptions]="timeDropdownOptions()"
              [label]="'Closing time'"
              [allowClear]="true"
              [formControl]="dayFormGroup.controls.closingTime"
              class="basis-[calc(50%-0.5rem)] min-w-48 grow"
              [note]="getNoteForTimeEntry(dayFormGroup.controls)"
              [noteStyleClass]="'text-right'"
            />
          </div>
        </div>
      }
      <div class="flex flex-col gap-3 mt-2">
        <app-note
          [value]="
            'Time format is based on your browser settings. The end user will see the time format based on their browser as well.'
          "
          [type]="'muted'"
          [size]="'small'"
          class="justify-end"
        />
        <div class="flex mobile:flex-col gap-3 self-end mobile:self-stretch">
          <app-button
            [type]="'dark'"
            [outlined]="true"
            class="ml-auto mr-0 mobile:m-0 mobile:order-2"
            (click)="onBack($event)"
          >
            Back
          </app-button>
          <app-button
            class="ml-auto mr-0 mobile:m-0 mobile:order-1"
            (click)="onNext($event)"
          >
            Next
          </app-button>
        </div>
      </div>
    </div>
  }
</app-entity-edit-wrapper>
