import { Component, inject, input, OnInit } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { SessionService } from 'src/app/services/session.service';
import { PopoverButton } from '../popover-buttons/popover-buttons.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {

  sessionService = inject(SessionService);

  menuItems = input.required<MenuItem[]>();
  popoverButtons = input<PopoverButton[]>([]);
  hideLogo = input<boolean>(false);
}
