import { CanActivateFn, Router } from '@angular/router';
import { SupabaseService } from '../services/supabase.service';
import { inject } from '@angular/core';

/**
 * Determines whether the user is NOT logged in.
 * @param route 
 * @param state 
 * @returns 
 */
export const unauthGuard: CanActivateFn = async (route, state) => {
  const supabaseService = inject(SupabaseService);
  const router = inject(Router);

  const session = await supabaseService.supabase.auth.getSession();
  const userId = session.data.session?.user.id;
  if (userId) {
    router.navigateByUrl('/dashboard/pages');
    return false;
  }
  return true;
};
