import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SubscriptionService } from '../services/subscription.service';
import { SupabaseService } from '../services/supabase.service';
import { SessionService } from '../services/session.service';
import { ToastService } from '../services/toast.service';
import { Role } from '../models/role';

export const subscriptionGuard: CanActivateFn = async (route, state) => {
  const subscriptionService = inject(SubscriptionService);
  const supabaseService = inject(SupabaseService);
  const sessionService = inject(SessionService);
  const toastService = inject(ToastService);
  const router = inject(Router);

  const navigateToLoginPage = () => router.navigateByUrl('/auth/login');

  const userId = await sessionService.getUserId();
  if (!userId) {
    navigateToLoginPage();
    return false;
  }

  const account = await sessionService.getAccount();
  if (account?.role === Role.Admin || account?.role === Role.Staff) return true;

  const subscription = await subscriptionService.getSubscription();
  if (!subscription) {
    router.navigateByUrl('/user/upgrade');
    toastService.info("Please choose a subscription first");
    return false;
  }

  const subscriptionPricing = subscriptionService.subscriptionPricing[subscription.type];
  const { count: entityCount } = await supabaseService.supabase.from('entity').select('*', { count: 'exact', head: true }).eq('user_id', userId);
  const isOnCreatePage = route.params['entityId'] === '0' || !route.params['entityId'];
  if (!isOnCreatePage) return true;
  const canCreateMoreListings = (entityCount ?? 0) < subscriptionPricing.maxPages;
  if (!canCreateMoreListings) {
    toastService.error(`Please upgrade your subscription before creating another page`);
    router.navigateByUrl('/dashboard/pages');
    return false;
  }
  return true;
};
