<ion-header class="hidden mobile:flex">
  <ion-toolbar>
    <ion-title>Claim page</ion-title>
  </ion-toolbar>
</ion-header>

<div class="mobile:hidden flex flex-col px-10 pt-10">
  <h1 class="text-2xl font-sherika-bold">Claim your page</h1>
  <p class="text-muted">
    @if (subscriptionService.subscription()?.isActive) {
      {{
        'Once claimed, you will be able to fully customize your page' | locale
      }}
    } @else {
      {{
        'After your claim your page, you will have to buy a subscription in order to keep it online'
          | locale
      }}
    }
  </p>
</div>

<div class="p-10 mobile:p-4 max-w-md flex flex-col grow">
  @if (loading()) {
    <app-loader />
  } @else if (state() === 'start') {
    <div class="flex flex-col gap-10 grow mobile:justify-between">
      <p class="text-sm text-normal">
        We are going to email a verification code to
        <span class="text-black">{{ emailAddress() }}</span
        >.
      </p>
      <div class="flex flex-col gap-3">
        <app-button (click)="sendCode()" [disabled]="sendingCode()"
          >Send</app-button
        >
        <app-button (click)="previousState()" [type]="'dark'" [outlined]="true"
          >Back</app-button
        >
      </div>
    </div>
  } @else if (state() === 'enter-otp') {
    <form
      [formGroup]="otpForm"
      class="flex flex-col gap-5 grow mobile:justify-between"
    >
      <app-input
        [formControl]="otpForm.controls.otp"
        [label]="'Code'"
        [errorMessage]="otpForm.controls.otp.errors?.['error']"
      />
      <div class="flex flex-col gap-3">
        <app-button (click)="verifyCode()">Verify</app-button>
        <app-button (click)="previousState()" [type]="'dark'" [outlined]="true"
          >Back</app-button
        >
      </div>
    </form>
  } @else {
    <div class="flex flex-col gap-3 grow mobile:justify-between">
      <div>
        <h4 class="text-xl font-semibold font-sherika-regular">Congrats!</h4>
        <p class="text-normal font-sherika-regular">
          You have now full ownership over {{ model()?.pageName }}. This means
          you can now fully customize and personalize your page to your own
          taste!
        </p>
      </div>
      <app-button (click)="goToPage()">Go!</app-button>
    </div>
  }
</div>
