import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'src/app/models/menu-item';
import { LoggingService } from 'src/app/services/logging.service';
import { SessionService } from 'src/app/services/session.service';
import { SupabaseService } from 'src/app/services/supabase.service';
import { ToastService } from 'src/app/services/toast.service';
import { ForInsert } from 'src/app/types/database';
import { registerClassOnWindow } from 'src/app/utils/global';
import { PopoverButton } from '../../ui/popover-buttons/popover-buttons.component';
import { AuthService } from 'src/app/services/auth.service';
import { NavController, ViewWillEnter } from '@ionic/angular';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, ViewWillEnter {
  supabaseService = inject(SupabaseService);
  sessionService = inject(SessionService);
  toastService = inject(ToastService);
  router = inject(NavController);
  loggingService = inject(LoggingService);
  authService = inject(AuthService);

  entities = signal<ForInsert<'entity'>[]>([]);

  menuItems = computed<MenuItem[]>(() => {
    const items: MenuItem[] = [
      { label: "Profile", href: "/user/account/profile", icon: "person-circle-outline" },
      { label: "Support", href: "/user/account/feedback", icon: "help-circle-outline" },
    ];
    return items;
  });
  popoverButtons = computed<PopoverButton[]>(() => [
    { label: "Profile", icon: "person-circle-outline", href: "/user/account/profile" },
    { label: "Support", icon: "help-circle-outline", href: "/user/account/feedback" },
    { isDivider: true },
    { label: "Log out", action: () => this.authService.logout(), icon: "power-outline" }
  ]);

  loading = signal(false);

  constructor() {
    registerClassOnWindow('PagesComponent', this);
  }

  ngOnInit() { }

  ionViewWillEnter(): void {
    this.loadPages();
  }

  async loadPages() {
    const userId = this.sessionService.account()?.id;
    if (!userId) {
      this.loggingService.error("User has no account. Error code 1106");
      return;
    };
    this.loading.set(true);
    const { data, error } = await this.supabaseService.supabase.from('entity').select('*');//.eq('user_id', userId);
    this.loading.set(false);
    if (error) {
      this.toastService.error("There was an error loading you pages.");
    } else {
      this.entities.set(data!);
    }
  }

  newEntityPage() {
    this.router.navigateForward('/dashboard/pages/0/edit?activeIndex=0', { animated: true });
  }
}
