<div class="flex items-start mobile:hidden justify-between px-10">
  <div class="mobile:py-6">
    <h1 class="text-3xl font-semibold mobile:text-2xl">
      {{ title() }}
    </h1>
    @if (fullName()) {
      <div class="mobile:hidden text-muted">Welcome back, {{ fullName() }}</div>
    }
  </div>
  <div class="mobile:absolute bottom-2 right-0">
    <ng-content select=".right-content" />
  </div>
</div>
<ion-header class="hidden mobile:flex">
  <ion-toolbar>
    <ion-title>{{ title() }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div
    class="grow max-h-full overflow-y-auto flex flex-col min-h-0 mobile:p-4 px-10"
  >
    <ng-content />
  </div>
</ion-content>
