<app-entity-edit-header
  [title]="'Custom boxes'"
  [description]="
    'With Custom boxes you can express yourself in any way possible. From customized text, to images, videos, audio, and PDF downloads'
  "
  [backUrl]="'/dashboard/pages/' + entityId() + '/edit?activeIndex=1'"
/>
<app-entity-edit-wrapper>
  @if (entityEditComponent.modelLoading()) {
    <app-loader class="my-auto" />
  } @else {
    <div class="max-w-screen-lg flex flex-col gap-12 grow">
      @for (
        customField of form.controls.customFields.controls;
        track customField
      ) {
        <div
          class="flex flex-col gap-4 px-8 py-9 shadow rounded-xl"
          [id]="customField.controls.id?.value"
        >
          <div class="flex items-end self-stretch gap-2">
            <app-dropdown
              [allowClear]="true"
              [selectOptions]="customFieldOptions()"
              [label]="'Choose a custom field type'"
              [formControl]="customField.controls.fieldType"
              (selectedOptionChange)="
                handleCustomFieldSelected($any($event), customField)
              "
              class="max-w-96 basis-full"
            />
            <button (click)="removeField($event, $index)">
              <ion-icon
                name="close-outline"
                class="!p-2 !rounded-full !text-lg focusable"
              ></ion-icon>
            </button>
          </div>
          @if (
            customField.controls.fieldType.value ===
            CustomFieldType.RichTextEditor
          ) {
            <app-rich-text-editor
              [formGroup]="customField!"
              [showTitle]="true"
              [label]="'Please enter a title'"
              [id]="'rich-text-editor-' + $index"
              [placeholder]="'Express yourself in 3d dimension...'"
              [labelRequired]="true"
              [maxLength]="500"
            />
          } @else if (
            customField.controls.fieldType.value === CustomFieldType.Images
          ) {
            <div class="flex flex-col gap-4">
              <app-input
                [placeholder]="'Image title'"
                [formControl]="
                  customField.controls.imagePicker?.controls?.title!
                "
                [errorMessage]="
                  customField.controls.imagePicker?.controls?.title?.errors?.[
                    'error'
                  ]
                "
                [maxLength]="20"
                class="max-w-96"
              />
              <app-multimedia-picker
                [id]="'image-picker-' + $index"
                [formControl]="
                  customField.controls.imagePicker?.controls?.value!
                "
                [type]="'image'"
                [maxMediaAmount]="subscription()?.maxImagesPerCustomField"
                [maxVideoDurationSeconds]="60"
              />
            </div>
          } @else if (
            customField.controls.fieldType.value === CustomFieldType.Videos
          ) {
            <div class="flex flex-col gap-4">
              <app-input
                [placeholder]="'Video title'"
                [formControl]="
                  customField.controls.videoPicker?.controls?.title!
                "
                [errorMessage]="
                  customField.controls.videoPicker?.controls?.title?.errors?.[
                    'error'
                  ]
                "
                [maxLength]="20"
                class="max-w-96"
              />
              <app-multimedia-picker
                [id]="'image-picker-' + $index"
                [formControl]="
                  customField.controls.videoPicker?.controls?.value!
                "
                [type]="'video'"
                [maxMediaAmount]="subscription()?.maxVideosPerCustomField"
                [maxVideoDurationSeconds]="60"
              />
            </div>
          } @else {
            <div class="text-muted">
              Please select a custom field type to continue
            </div>
          }
        </div>
      }
      <button (click)="addCustomField($event)" class="self-end">
        <ion-icon
          name="add-circle-outline"
          class="p-4 rounded-md border border-solid border-light transition-all hover:border-muted text-lg shadow-sm hover:shadow"
        ></ion-icon>
      </button>
      <div
        class="flex mobile:flex-col gap-3 self-end mobile:self-stretch mt-auto mb-0"
      >
        <app-button
          [type]="'dark'"
          [outlined]="true"
          class="ml-auto mr-0 mobile:m-0 mobile:order-2"
          (click)="onBack($event)"
        >
          Back
        </app-button>
        <app-button
          class="self-end mobile:self-stretch mobile:order-1S"
          (click)="save($event)"
          [disabled]="saving()"
        >
          {{ saving() ? ('Saving...' | locale) : ('Save' | locale) }}
        </app-button>
      </div>
    </div>
  }
</app-entity-edit-wrapper>
