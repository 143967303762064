import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '../../validators/required';
import { passwordValidator } from '../../validators/password';
import { ValidatorFunction } from 'src/app/models/validator';
import { SupabaseService } from 'src/app/services/supabase.service';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { registerClassOnWindow } from 'src/app/utils/global';
import { LoggingService } from 'src/app/services/logging.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-account-password',
  templateUrl: './account-password.component.html',
  styleUrls: ['./account-password.component.scss'],
})
export class AccountPasswordComponent {

  supabaseService = inject(SupabaseService);
  sessionService = inject(SessionService);
  apiService = inject(ApiService);
  toastService = inject(ToastService);
  loggingService = inject(LoggingService);

  saving = signal(false);

  form = new FormGroup<ResetPasswordForm>({
    oldPassword: new FormControl('', [requiredValidator('Old Password')]),
    newPassword: new FormControl('', [
      requiredValidator('Old Password'),
      passwordValidator(),
    ]),
  }, { validators: [this.ensurePasswordIsNotSameAsOld()] });

  constructor() {
    registerClassOnWindow('AccountPasswordComponent', this);
  }

  ensurePasswordIsNotSameAsOld(): ValidatorFunction {
    return function (formGroup) {
      if (!formGroup.touched || !formGroup.dirty) return null;
      const oldPassword = formGroup.get('oldPassword');
      const newPassword = formGroup.get('newPassword');
      if (!newPassword?.dirty) return null;
      if (newPassword.value === oldPassword?.value) {
        return {
          error: "New password cannot be the same as the old password"
        };
      }
      return null;
    };
  }

  async updatePassword() {
    if (this.saving()) return; // prevent rage clicking
    markAllControlsAsTouchedAndDirty(this.form);
    if (!this.form.valid) return;
    const formValue = this.form.value;
    try {
      this.saving.set(true);
      const response = await this.apiService.postAsync('/auth/update-password', { body: formValue });
      await this.supabaseService.supabase.auth.signInWithPassword({
        email: this.sessionService.email()!,
        password: formValue.newPassword!,
      });
      this.toastService.success("Password updated successfully");
    } catch (e) {
      this.loggingService.error(e);
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error?.message ?? "Error updating password");
    } finally {
      this.saving.set(false);
    }
  }
}

export interface ResetPasswordForm {
  oldPassword: FormControl<string | null>;
  newPassword: FormControl<string | null>;
}
