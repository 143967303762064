import { Component, computed, inject } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { AuthService } from 'src/app/services/auth.service';
import { PlatformService } from 'src/app/services/platform.service';
import { SessionService } from 'src/app/services/session.service';
import { PopoverButton } from '../ui/popover-buttons/popover-buttons.component';

@Component({
  selector: 'app-account-home',
  templateUrl: './account-home.component.html',
  styleUrls: ['./account-home.component.scss'],
})
export class AccountHomeComponent {
  platformService = inject(PlatformService);
  sessionService = inject(SessionService);
  authService = inject(AuthService);
  menuItems = computed<MenuItem[]>(() => [
    { label: "Home", href: "/", icon: "home-outline" },
    { label: "Business", href: "/dashboard/pages", icon: "speedometer-outline" },
    { label: "Support", href: "/dashboard/support", icon: "help-circle-outline" },
  ]);
  popoverButtons = computed<PopoverButton[]>(() => [
    { label: "Support", icon: "help-circle-outline", href: "/dashboard/support" },
    { isDivider: true },
    { label: "Log out", action: () => this.authService.logout(), icon: "power-outline" }
  ]);
}
