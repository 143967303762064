import { Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService, SignUpArgs } from 'src/app/services/auth.service';
import { passwordValidator } from '../../validators/password';
import { registerClassOnWindow } from 'src/app/utils/global';
import { emailValidator } from '../../validators/email';
import { requiredValidator } from '../../validators/required';
import { AlertButton } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { ToastService } from 'src/app/services/toast.service';
import { AuthError } from '@supabase/supabase-js';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent {

  toastService = inject(ToastService);
  authService = inject(AuthService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  form = new FormGroup({
    name: new FormControl('', [requiredValidator("Name")]),
    phone: new FormControl('', [requiredValidator("Phone")]),
    email: new FormControl('', {
      validators: [emailValidator(), requiredValidator("Email")],
    }),
    password: new FormControl('', {
      validators: [passwordValidator()],
    }),
  }, { updateOn: 'change' });

  signingUp = signal(false);
  errorMessage = signal<string | undefined>(undefined);
  signUpErrorButtons: AlertButton[] = [
    {
      text: 'Cancel',
      handler: () => {
        this.errorMessage.set(undefined);
      }
    },
    {
      text: "Log in",
      handler: () => {
        this.router.navigateByUrl('/auth/login');
        this.errorMessage.set(undefined);
      }
    }
  ];

  constructor() {
    registerClassOnWindow('SignupComponent', this);
  }

  async signUp(event: MouseEvent) {
    markAllControlsAsTouchedAndDirty(this.form);
    if (this.form.invalid) return;
    event.preventDefault();
    const value = this.form.value;
    this.signingUp.set(true);
    try {
      const response = await this.authService.signUp(value);
      this.afterSignUp();
    } catch (e) {
      const error = e as AuthError;
      if (error.code === 'user_already_exists') {
        this.toastService.error("Email already exist.");
      }
      else if (error.message?.includes('violates foreign key constraint')) {
        this.errorMessage.set("Email already exist. Want to Log In?");
      }
      console.log('ERROR!', { error });
    } finally {
      this.signingUp.set(false);
    }
  }

  getReturnUrl() {
    const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('authReturnUrl');
    return encodeURIComponent(returnUrl ?? '');
  }

  afterSignUp() {
    this.router.navigateByUrl(`/auth/confirm-email?authReturnUrl=${this.getReturnUrl()}`);
  }

  navigateToHome() {
    this.router.navigateByUrl('/');
  }

}
