import { Component, computed, inject, signal } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { FileService } from 'src/app/services/file.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent {
  authService = inject(AuthService);
  apiService = inject(ApiService);
  toastService = inject(ToastService);
  fileService = inject(FileService);

  menuItems = computed(() => {
    const items: MenuItem[] = [];
    return items;
  });
  popoverButtons = computed(() => [{ label: "Log out", action: () => this.authService.logout(), icon: 'power' }]);

  exporting = signal(false);

  async export() {
    if (this.exporting()) return;
    this.exporting.set(true);
    try {
      const response = await this.apiService.getAsync<string>('/admin/export-entities');
      const binary = atob(response.data);
      const array = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        array[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      await this.fileService.download(blob, 'export.xlsx');
    } catch (e) {
      this.toastService.error('Failed to export data');
    } finally {
      this.exporting.set(false);
    }
  }
}
