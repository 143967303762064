<swiper-container
  class="w-full h-full bg-transparent"
  #swiper
  [injectStyles]="['assets/swiper.theme.css']"
  (swiperslidechange)="handleSlideChange($event)"
>
  @for (media of media(); track $index) {
    <swiper-slide
      class="!w-full h-full flex items-center justify-center cursor-pointer"
      (click)="close()"
    >
      @if (type() === 'image') {
        <img
          [src]="media.src"
          class="h-auto w-auto max-w-full max-h-full object-contain block cursor-default"
          (click)="mediaClick($event)"
        />
      } @else {
        <app-video-player
          #videoPlayer
          [media]="media"
          [(muted)]="muted"
          class="cursor-default"
        />
      }
    </swiper-slide>
  }
</swiper-container>
