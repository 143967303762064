import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { SessionService } from '../services/session.service';
import { Role } from '../models/role';

export const adminGuard: CanActivateFn = async (route, state) => {
  const sessionService = inject(SessionService);
  const account = await sessionService.getAccount();
  return account?.role === Role.Admin;
};
