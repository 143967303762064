@if (sessionService.isLoggedIn()) {
  <div
    class="h-full border-r border-solid border-gray-200 desktop:w-72 desktop:max-w-72 flex flex-col mobile:hidden gap-8 grow-0 shrink-0 shadow bg-zinc-50"
  >
    <app-logo class="px-5 pt-12"></app-logo>
    <div class="flex flex-col p-2 items-stretch grow gap-1">
      @for (button of sideMenuButtons(); track $index) {
        @if (button.isDivider) {
          <hr class="w-full" />
        } @else {
          <button
            (click)="button.action?.($event)"
            class="px-4 py-3 flex items-center justify-start gap-5 text-gray-500 hover:text-gray-800 active:text-gray-400 transition-colors rounded-lg hover:bg-gray-100"
            [routerLink]="button.href"
            [routerDirection]="'root'"
            routerLinkActive="!text-black active:!text-gray-400"
            #rla="routerLinkActive"
          >
            <ion-icon
              [name]="rla.isActive ? button.icon : button.icon + '-outline'"
              class="text-2xl text-gray-700"
            ></ion-icon>
            <span
              class="font-sherika-regular w-full text-start not-desktop:hidden"
              >{{ button.label }}</span
            >
          </button>
        }
      }
      <button
        class="mt-auto mb-0 self-stretch flex items-center pb-2 px-3 gap-3"
      >
        <div
          class="rounded-full bg-gray-400 text-white flex items-center justify-center w-8 h-8"
        >
          {{ sessionService.account()?.full_name?.charAt(0) }}
        </div>
        <div class="flex flex-col items-start not-desktop:hidden">
          <span>{{ sessionService.account()?.full_name }}</span>
          <span class="text-xs text-muted">{{
            sessionService.session()?.user?.email
          }}</span>
        </div>
      </button>
    </div>
  </div>
}

<div class="grow flex max-w-full relative">
  <ion-router-outlet [animated]="true" [swipeGesture]="true" />
</div>
