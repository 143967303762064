import { Component, input } from '@angular/core';

@Component({
  selector: 'app-validation-output',
  templateUrl: './validation-output.component.html',
  styleUrls: ['./validation-output.component.scss'],
  host: {
    '[class.text-red-500]': 'type() === "error"',
    '[class.text-green-500]': 'type() === "success"',
    '[class.text-gray-500]': 'type() === "info"',
  }
})
export class ValidationOutputComponent {

  value = input.required<string | null | undefined>();
  type = input<'error' | 'success' | 'info'>('error');
  styleClass = input<string | undefined>('');
}
