import { inject, Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { SupabaseService } from './supabase.service';

@Injectable({
  providedIn: 'root'
})
export class EntityReadService {
  sessionService = inject(SessionService);
  supabaseService = inject(SupabaseService);

  userOwnsPage(userId: string | undefined | null) {
    const isAdmin = this.sessionService.isAdmin();
    const loggedInUserId = this.sessionService.session()?.user.id;
    return userId === loggedInUserId || isAdmin;
  }

  getEntityCount() {
    return this.supabaseService.supabase.from('entity').select('*', { count: 'exact', head: true });
  }
}
