<video
  #video
  [src]="videoSrc()"
  playsinline
  class="mx-auto cursor-pointer h-fit self-center max-h-screen"
  (click)="togglePlay($event)"
></video>
<div
  class="absolute z-20 bottom-0 right-0 py-4 px-6 w-full text-white bg-gradient-to-b from-transparent to-black/60 flex items-center gap-3"
  (click)="overlayClicked($event)"
>
  <div>
    <ion-icon
      [name]="paused() ? 'play-outline' : 'pause-outline'"
      class="text-2xl cursor-pointer"
      (click)="togglePlay()"
    ></ion-icon>
  </div>
  <div>
    <ion-icon
      [name]="muted() ? 'volume-mute-outline' : 'volume-high-outline'"
      class="text-2xl cursor-pointer"
      (click)="toggleMute()"
    />
  </div>
</div>
@if (media().processing) {
  <p class="absolute-center subtle-text-shadow text-white">
    {{ 'Video processing...' | locale }}
  </p>
}
