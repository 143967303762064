<button
  class="flex items-center justify-center gap-2 relative p-4"
  (click)="isOpen.set(!isOpen())"
>
  {{ label() | locale }}
  <ion-icon name="chevron-down-outline"></ion-icon>
  @if(isOpen()) {

  <div
    class="flex flex-col items-stretch justify-start border border-solid border-gray-100 rounded-lg shadow-[1px_9px_14px_#00000011] min-w-64 absolute top-full right-0 py-2 z-10 buttons bg-white"
  >
    @for (button of buttons(); track button; let first = $first; let last =
    $last) { @if(button.isDivider) {
    <hr class="h-px w-full bg-gray-100 border-none my-[calc(.25rem-1px)]" />
    } @else {
    <button
      (click)="button.action?.($event)"
      class="flex items-center justify-start gap-2 hover:bg-gray-50 active:bg-gray-200 px-4 py-2"
      [routerLink]="button.href"
    >
      <ion-icon [name]="button.icon" class="text-lg"></ion-icon>
      {{ button.label }}
    </button>
    } }
  </div>
  }
</button>
