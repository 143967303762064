<div>
  <h2 class="text-xl font-semibold">Password</h2>
  <div class="text-normal">Update your account password</div>
</div>

<form
  [formGroup]="form"
  (submit)="updatePassword()"
  class="flex flex-col gap-6"
>
  <app-input
    [label]="'Old password'"
    [formControl]="form.controls.oldPassword"
    [errorMessage]="form.controls.oldPassword.errors?.['error']"
    [type]="'password'"
  />
  <app-input
    [label]="'Old password'"
    [formControl]="form.controls.newPassword"
    [errorMessage]="form.controls.newPassword.errors?.['error'] || form.errors?.['error']"
    [type]="'password'"
  />
  <app-button [disabled]="saving()">Save</app-button>
</form>
