import { computed, inject, Injectable, signal } from '@angular/core';
import { DropdownOption } from '../components/ui/form/dropdown/dropdown.component';
import { LocalizationService } from '../modules/localization/services/localization.service';
import { FormGroup } from '@angular/forms';
import { OperatingHour as FormOperatingHour } from '../models/entity/edit/form';


@Injectable({
  providedIn: 'root'
})
export class DateService {
  localizationService = inject(LocalizationService);
  time12HourFormats = signal<DropdownOption<number>[]>(
    [
      { label: '12:00 AM', value: 0 },
      { label: '12:30 AM', value: 0.5 },
      { label: '01:00 AM', value: 1 },
      { label: '01:30 AM', value: 1.5 },
      { label: '02:00 AM', value: 2 },
      { label: '02:30 AM', value: 2.5 },
      { label: '03:00 AM', value: 3 },
      { label: '03:30 AM', value: 3.5 },
      { label: '04:00 AM', value: 4 },
      { label: '04:30 AM', value: 4.5 },
      { label: '05:00 AM', value: 5 },
      { label: '05:30 AM', value: 5.5 },
      { label: '06:00 AM', value: 6 },
      { label: '06:30 AM', value: 6.5 },
      { label: '07:00 AM', value: 7 },
      { label: '07:30 AM', value: 7.5 },
      { label: '08:00 AM', value: 8 },
      { label: '08:30 AM', value: 8.5 },
      { label: '09:00 AM', value: 9 },
      { label: '09:30 AM', value: 9.5 },
      { label: '10:00 AM', value: 10 },
      { label: '10:30 AM', value: 10.5 },
      { label: '11:00 AM', value: 11 },
      { label: '11:30 AM', value: 11.5 },
      { label: '12:00 PM', value: 12 },
      { label: '12:30 PM', value: 12.5 },
      { label: '01:00 PM', value: 13 },
      { label: '01:30 PM', value: 13.5 },
      { label: '02:00 PM', value: 14 },
      { label: '02:30 PM', value: 14.5 },
      { label: '03:00 PM', value: 15 },
      { label: '03:30 PM', value: 15.5 },
      { label: '04:00 PM', value: 16 },
      { label: '04:30 PM', value: 16.5 },
      { label: '05:00 PM', value: 17 },
      { label: '05:30 PM', value: 17.5 },
      { label: '06:00 PM', value: 18 },
      { label: '06:30 PM', value: 18.5 },
      { label: '07:00 PM', value: 19 },
      { label: '07:30 PM', value: 19.5 },
      { label: '08:00 PM', value: 20 },
      { label: '08:30 PM', value: 20.5 },
      { label: '09:00 PM', value: 21 },
      { label: '09:30 PM', value: 21.5 },
      { label: '10:00 PM', value: 22 },
      { label: '10:30 PM', value: 22.5 },
      { label: '11:00 PM', value: 23 },
      { label: '11:30 PM', value: 23.5 },
    ]
  );
  time24HourFormats = signal<DropdownOption<number>[]>([
    { label: '00:00', value: 0 },
    { label: '00:30', value: 0.5 },
    { label: '01:00', value: 1 },
    { label: '01:30', value: 1.5 },
    { label: '02:00', value: 2 },
    { label: '02:30', value: 2.5 },
    { label: '03:00', value: 3 },
    { label: '03:30', value: 3.5 },
    { label: '04:00', value: 4 },
    { label: '04:30', value: 4.5 },
    { label: '05:00', value: 5 },
    { label: '05:30', value: 5.5 },
    { label: '06:00', value: 6 },
    { label: '06:30', value: 6.5 },
    { label: '07:00', value: 7 },
    { label: '07:30', value: 7.5 },
    { label: '08:00', value: 8 },
    { label: '08:30', value: 8.5 },
    { label: '09:00', value: 9 },
    { label: '09:30', value: 9.5 },
    { label: '10:00', value: 10 },
    { label: '10:30', value: 10.5 },
    { label: '11:00', value: 11 },
    { label: '11:30', value: 11.5 },
    { label: '12:00', value: 12 },
    { label: '12:30', value: 12.5 },
    { label: '13:00', value: 13 },
    { label: '13:30', value: 13.5 },
    { label: '14:00', value: 14 },
    { label: '14:30', value: 14.5 },
    { label: '15:00', value: 15 },
    { label: '15:30', value: 15.5 },
    { label: '16:00', value: 16 },
    { label: '16:30', value: 16.5 },
    { label: '17:00', value: 17 },
    { label: '17:30', value: 17.5 },
    { label: '18:00', value: 18 },
    { label: '18:30', value: 18.5 },
    { label: '19:00', value: 19 },
    { label: '19:30', value: 19.5 },
    { label: '20:00', value: 20 },
    { label: '20:30', value: 20.5 },
    { label: '21:00', value: 21 },
    { label: '21:30', value: 21.5 },
    { label: '22:00', value: 22 },
    { label: '22:30', value: 22.5 },
    { label: '23:00', value: 23 },
    { label: '23:30', value: 23.5 },
  ]);

  localeTimeFormatOptions = computed(() => {
    const timeFormat = this.localizationService.timeFormat();
    if (timeFormat === '12') {
      return this.time12HourFormats();
    }
    return this.time24HourFormats();
  });

  localeTimeFormatLookup = computed(() => {
    const localeTimeFormats = this.localeTimeFormatOptions();
    const mapped = new Map<number, DropdownOption<number>>(localeTimeFormats.map<[number, DropdownOption<number>]>(ltf => ([ltf.value!, { label: ltf.label, value: ltf.value }])));
    return mapped;
  });

  daysOfTheWeek = signal(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Motzei Shabbos']);

  /**
   * @param operatingHours this method assumes that the hours are sorted ascending
   */
  getIsCurrentlyOpenStatus(operatingHours: OperatingHour[]): CurrentOperatingHourStatus {
    const today = new Date();
    const currentWeekday = today.getDay(); // we store the day of week in index based
    const tomorrowsWeekday = (currentWeekday + 1) % 6; // in case tomorrow is Sunday (0)
    const yesterdaysWeekday = (currentWeekday - 1) % 6;
    const currentThreeDays = operatingHours.filter(o => o.day === currentWeekday || o.day === tomorrowsWeekday || o.day === yesterdaysWeekday);

    const currentHour = today.getHours();
    const mostRecentOperatingHour = currentThreeDays.reduce((prev, curr) => {
      const isNextWeek = (currentWeekday - (curr.day ?? 0)) >= 3;
      if (isNextWeek) return prev;
      const isTodayOrPreviousDays = curr.day! <= currentWeekday;
      const isInPast = isTodayOrPreviousDays && (curr.closingTime! <= currentHour || curr.openingTime! <= currentHour);
      if (!isInPast) return prev;
      const hasLaterHourThanPrev = !!prev && (curr.closingTime! > prev.closingTime! || curr.openingTime! > prev.openingTime!);
      if (hasLaterHourThanPrev) return prev;
      return curr;
    }, undefined as OperatingHour | undefined);

    const upcomingOperatingHour = currentThreeDays.reduce((prev, curr) => {
      const isPreviousWeek = ((curr.day ?? 0) - currentWeekday) >= 3;
      if (isPreviousWeek) return prev;
      const isTodayOrUpcomingDays = curr.day! >= currentWeekday;
      const isInFuture = isTodayOrUpcomingDays && (curr.closingTime! > currentHour || curr.openingTime! > currentHour);
      if (!isInFuture) return prev;
      const hasEarlierHoursThanPrev = prev && (curr.closingTime! < prev.closingTime! || curr.openingTime! < prev.openingTime!);
      if (hasEarlierHoursThanPrev) return curr;
      return prev;
    }, undefined as OperatingHour | undefined);

    const isOpenRightNow = mostRecentOperatingHour &&
      (
        mostRecentOperatingHour.closingTime === mostRecentOperatingHour.openingTime ||
        (mostRecentOperatingHour.closingTime! < mostRecentOperatingHour.openingTime! && mostRecentOperatingHour.openingTime! < currentHour) ||
        (mostRecentOperatingHour.closingTime! > currentHour && mostRecentOperatingHour.openingTime! < currentHour)
      );

    const getStatusForNextHour = (): CurrentOperatingHourStatus | undefined => {
      const nextHour = currentHour + 1;
      if (!upcomingOperatingHour) return;
      const willChangeWithinNextHour = upcomingOperatingHour.closingTime! <= nextHour || upcomingOperatingHour.openingTime! <= nextHour;
      if (!willChangeWithinNextHour) return;
      const isOpen24Hours = upcomingOperatingHour.closingTime === upcomingOperatingHour.openingTime;
      if (isOpen24Hours) return;
      let status: CurrentOperatingHourStatus | undefined;
      const willOpenSoon = upcomingOperatingHour.openingTime! < nextHour && upcomingOperatingHour.openingTime! >= currentHour;
      if (willOpenSoon) {
        status = CurrentOperatingHourStatus.OpeningSoon;
      }
      const willCloseSoon = upcomingOperatingHour.closingTime! < nextHour && upcomingOperatingHour.closingTime! >= currentHour;
      if (willCloseSoon) {
        status = CurrentOperatingHourStatus.ClosingSoon;
      }
      return status;
    };

    const currentStatus = isOpenRightNow ? CurrentOperatingHourStatus.Open : CurrentOperatingHourStatus.Closed;

    const nextStatus = getStatusForNextHour();
    if (nextStatus === CurrentOperatingHourStatus.ClosingSoon && currentStatus === CurrentOperatingHourStatus.Open) return nextStatus;
    if (nextStatus === CurrentOperatingHourStatus.OpeningSoon && currentStatus === CurrentOperatingHourStatus.Closed) return nextStatus;
    return currentStatus;
  }
}

export type OperatingHour = FormGroup<FormOperatingHour>['value'];

export enum CurrentOperatingHourStatus {
  Open = "Open now",
  Closed = "Closed",
  OpeningSoon = "Opening soon",
  ClosingSoon = "Closing soon",
}