<app-logo></app-logo>
<div class="flex flex-col gap-12 max-w-screen-lg w-full mx-auto">
  <div class="flex flex-col gap-1">
    <h1 class="text-3xl font-sherika-bold">
      {{ 'Koshery plans' | locale }}
    </h1>
    <h2 class="text-muted">
      {{ 'Choose a plan for your business or organization' | locale }}
    </h2>
  </div>
  <div class="flex mobile:flex-col gap-8 items-stretch">
    <app-upgrade-card
      [type]="SubscriptionType.Classic"
      [icon]="'flash-outline'"
      [price]="classicPrice"
      [discount]="discountAmount()"
      [pros]="[
        'List a single page',
        '2 custom boxes',
        'Upload PDF file',
        '5 image uploads',
      ]"
      [cons]="['Upload videos', 'Upload audio file', 'Free page design']"
      (onUpgrade)="onUpgrade($event)"
      [upgradingInProgress]="creatingSubscription()"
    />
    <app-upgrade-card
      [type]="SubscriptionType.Premium"
      [icon]="'cube-outline'"
      [price]="premiumPrice"
      [discount]="discountAmount()"
      [pros]="[
        'List 3 pages',
        '6 custom boxes',
        'Upload PDF file',
        '20 image uploads per page',
        '3 videos per page',
        'Upload audio file',
      ]"
      [cons]="['Free page design']"
      (onUpgrade)="onUpgrade($event)"
      [upgradingInProgress]="creatingSubscription()"
    />
    <app-upgrade-card
      [type]="SubscriptionType.Platinum"
      [icon]="'analytics-outline'"
      [price]="platinumPrice"
      [discount]="discountAmount()"
      [pros]="[
        'List unlimited pages',
        '25 custom boxes',
        'Upload PDF file',
        '100 image uploads per page',
        '5 videos per page',
        'Upload audio file',
        'Free page design',
      ]"
      [cons]="[]"
      (onUpgrade)="onUpgrade($event)"
      [upgradingInProgress]="creatingSubscription()"
    />
  </div>
</div>
