import { Component, computed, inject, input, model, OnDestroy, signal } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalService } from 'src/app/services/modal.service';
import { Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastService } from 'src/app/services/toast.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-profile-photo-editor',
  templateUrl: './profile-photo-editor.component.html',
  styleUrls: ['./profile-photo-editor.component.scss'],
})
export class ProfilePhotoEditorComponent implements OnDestroy {
  modelService = inject(ModalService);
  modalController = inject(ModalController);
  toastService = inject(ToastService);
  fileService = inject(FileService);

  imageFile = signal<File | undefined>(undefined);
  imageSrc = computed(() => {
    const file = this.imageFile();
    if (!file) return;
    return URL.createObjectURL(file);
  });
  accepts = input.required<string>();
  htmlFor = input.required<string>();
  croppedBlob = signal<Blob | null | undefined>(undefined);

  cropperReady = signal(false);

  ngOnDestroy(): void {
    URL.revokeObjectURL(this.imageSrc() ?? '');
  }

  close() {
    this.modalController.dismiss();
  }

  handleLoadImageFail() {
    this.toastService.error("There was an error loading this image");
    this.close();
  }

  cropperChange(event: ImageCroppedEvent) {
    this.croppedBlob.set(event.blob);
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const file = target.files?.[0];
    if (!file) return;
    this.imageFile.set(file);
  }

  confirmCrop() {
    const blob = this.croppedBlob();
    if (!blob) return;
    const name = this.imageFile()?.name;
    if (!name) return;
    const file = this.fileService.blobToFile(blob, name);
    this.modalController.dismiss(file);
  }

  onCropperReady(dimensions: Dimensions) {
    this.cropperReady.set(true);
  }
}
