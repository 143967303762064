<input
  type="text"
  [value]="inputValue() || value()?.label"
  (input)="onChange($event)"
  #input
  class="border-none outline-none px-1"
/>
@if (suggestions$ | async; as suggestions) {
  @if (suggestions.length && showSuggestions()) {
    <div
      class="absolute top-full left-0 w-max flex flex-col bg-white shadow-lg rounded p-1"
    >
      @for (suggestion of suggestions; track suggestion.value) {
        <span
          class="flex items-center justify-start px-2 py-2 cursor-pointer"
          (click)="setValue(suggestion)"
          >{{ suggestion.label }}</span
        >
      }
    </div>
  }
}
