<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="64"
  viewBox="0 0 64 64"
>
  <!-- Outer chip body with a goldish color -->
  <rect
    x="4"
    y="12"
    width="56"
    height="40"
    rx="6"
    ry="6"
    fill="#D4AF37"
    stroke="#B8860B"
    stroke-width="2"
  />

  <!-- Top row of chip contacts -->
  <rect x="14" y="18" width="8" height="4" fill="#FFD700" />
  <rect x="24" y="18" width="8" height="4" fill="#FFD700" />
  <rect x="34" y="18" width="8" height="4" fill="#FFD700" />

  <!-- Bottom row of chip contacts -->
  <rect x="14" y="38" width="8" height="4" fill="#FFD700" />
  <rect x="24" y="38" width="8" height="4" fill="#FFD700" />
  <rect x="34" y="38" width="8" height="4" fill="#FFD700" />

  <!-- Center block (chip processing unit) with a darker gold accent -->
  <rect x="14" y="26" width="28" height="8" fill="#B8860B" />
</svg>
