import { Component, computed, forwardRef, inject, input, model, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileService, UploadArgs } from 'src/app/services/file.service';
import { ModalService } from 'src/app/services/modal.service';
import { plural } from 'src/app/utils/string';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  host: {
    '[class]': 'hasImageClass()'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarComponent),
      multi: true
    }
  ]
})
export class AvatarComponent implements ControlValueAccessor {
  modalService = inject(ModalService);
  fileService = inject(FileService);
  loggingService = inject(LoggingService);

  label = input.required<string>();
  value = model<FileList | null>();
  valueChanged$ = toObservable(this.value);
  mode = input<'single' | 'multiple'>('single');
  mimeType = input.required<'image' | 'video'>();
  subMimeTypes = input.required<string[]>();
  bucketName = input.required<string>();
  plural = plural;
  fileNames = computed(() => {
    const files = this.filesListToArray(this.value());
    if (!files.length) return;
    return files.map(f => f.name).join(', ');
  });

  imageSrc = signal<string | null | undefined>(undefined);

  accepts = computed(() => {
    const type = this.mimeType();
    const subTypes = this.subMimeTypes();
    return subTypes.reduce((current, next, index, array) => {
      if (index === array.length - 1) {
        return current + `${type}/${next}`;
      }
      return current + `${type}/${next},`;
    }, '');
  });

  hasImageClass = computed(() => this.value()?.length ? 'aspect-[16/6]' : '');

  constructor() {
    this.valueChanged$.pipe(
      takeUntilDestroyed(),
    ).subscribe(file => this.convertFileToString(file?.[0]));
  }

  onChange = (value: UploadArgs[] | null | undefined) => { };
  onTouched = () => { };

  writeValue(obj: UploadArgs[] | null | undefined): void {
    if (!obj?.length) {
      this.value.set(null);
    }
    else {
      const files = obj.map(f => f.file);
      const fileList = this.arrayToFileList(files);
      this.value.set(fileList);
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const fileArray = Array.from(target.files ?? []);
    this.value.set(target.files);
    const uploadArgs = fileArray.map<UploadArgs>(f => ({ bucketName: this.bucketName(), file: f }));
    this.onChange(uploadArgs);
  }

  onFileRemove() {
    this.value.set(null);
    this.onChange(null);
  }

  arrayToFileList(files: File[]): FileList {
    files = files.filter(f => f);
    const dataTransfer = new DataTransfer();
    files.forEach(f => dataTransfer.items.add(f));
    return dataTransfer.files;
  }

  filesListToArray(filesList: FileList | null | undefined) {
    return Array.from(filesList ?? []);
  }

  async convertFileToString(file: File | undefined | null) {
    const fileSrc = await this.fileService.fileToUrl(file);
    this.imageSrc.set(fileSrc);
  }

  async openImageEditor() {
    const imageSrc = this.imageSrc();
    if (!imageSrc) {
      this.loggingService.error("No image source found to edit. Error code 1114");
      return;
    }
    const results = await this.modalService.open<File, ImageCropperComponent>(ImageCropperComponent, {
      componentProps: {
        imageSrc,
        aspectRatio: 16 / 6,
        fileName: this.fileNames(),
      }
    });
    const file = results?.data;
    if (!file) return; // the user probably cancelled
    const fileList = this.arrayToFileList([file]);
    this.value.set(fileList);
    this.onChange([{ bucketName: this.bucketName(), file }]);
  }
}
