import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { SupabaseService } from '../services/supabase.service';
import { PromoCode } from '../services/promo.service';
import { LocalStorageKey, LocalStorageService } from '../services/local-storage.service';

export const upgradeResolver: ResolveFn<void> = async (route, state) => {
  const supabaseService = inject(SupabaseService);
  const localStorageService = inject(LocalStorageService);
  const promoCode = route.queryParamMap.get('code') || localStorageService.get<PromoCode>(LocalStorageKey.PromoCode)?.code;
  if (!promoCode) return;
  const { data: dbPromoCode } = await supabaseService.supabase.from('promo_code').select('*').eq('code', promoCode).single();
  if (!dbPromoCode?.code || !dbPromoCode?.discount_amount) return;
  localStorageService.set<PromoCode>(LocalStorageKey.PromoCode, { code: dbPromoCode.code, discountAmount: dbPromoCode.discount_amount });
};
