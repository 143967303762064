import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";

export function markControlAsTouchedAndDirty(formControl: AbstractControl | null) {
  formControl?.markAsTouched();
  formControl?.markAsDirty();
  formControl?.updateValueAndValidity({ emitEvent: true });
}

export function markAllControlsAsTouchedAndDirty(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach(controlName => {
    const control = formGroup.get(controlName);
    markControlAsTouchedAndDirty(control);
    if (control instanceof FormGroup) {
      markAllControlsAsTouchedAndDirty(control); // Recursive for nested groups
    }
    else if (control instanceof FormArray) {
      for (const c of control.controls) {
        if (c instanceof FormGroup) {
          markAllControlsAsTouchedAndDirty(c);
        }
        else if (c instanceof FormControl) {
          markControlAsTouchedAndDirty(c);
        }
      }
    }
  });
}