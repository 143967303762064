import { Component, computed, DestroyRef, inject, Injector, input, OnInit, signal, ViewContainerRef } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import Quill from 'quill';
import { EntityEditCustomFields } from 'src/app/models/entity/edit/form';
import { InputComponent } from '../input/input.component';
import { ContentChange } from 'ngx-quill';
import { requiredValidator } from 'src/app/components/validators/required';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnInit {
  viewContainerRef = inject(ViewContainerRef);
  injector = inject(Injector);
  controlContainer = inject(ControlContainer);
  destroyRef = inject(DestroyRef);

  label = input<string>();
  labelRequired = input<boolean>(false);
  showTitle = input(false);
  id = input<string>();
  placeholder = input<string>('');
  maxLength = input.required<number>();
  form!: FormGroup<EntityEditCustomFields | null>;
  textLength = signal<number>(0);

  editorModules = computed(() => {
    const showTitle = this.showTitle();
    const editorModule = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link'/*, 'image'*/],
      ]
    };
    if (showTitle) {
      editorModule.toolbar.unshift(['editor-title']);
    }
    return editorModule;
  });

  constructor() {
    // registerClassOnWindow('RichTextEditorComponent', this);
  }

  ngOnInit() {
    this.form = this.controlContainer.control as FormGroup<EntityEditCustomFields | null>;
  }

  onEditorCreated(quillInstance: Quill) {
    this.setTextLength(quillInstance);
    if (!this.showTitle()) return;
    const toolbar = quillInstance.getModule('toolbar') as any;
    const toolbarContainer = toolbar.container;
    // Find the placeholder for the custom button
    const customButtonPlaceholder = toolbarContainer.querySelector('.ql-editor-title');
    if (!customButtonPlaceholder) return;

    const titleFormControl = this.form.controls?.richTextEditor?.controls?.title;
    if (!titleFormControl) return;
    const componentRef = this.viewContainerRef.createComponent(InputComponent);
    const componentInstance = componentRef.instance;

    componentRef.setInput('placeholder', 'Box title');
    componentRef.setInput('htmlFor', this.id());
    componentRef.setInput('maxLength', 20);


    const titleFormControlOnChangeFn = (value: string) => {
      componentInstance.writeValue(value);
    };

    titleFormControl.registerOnChange(titleFormControlOnChangeFn);
    titleFormControl.setValidators([requiredValidator("Title")]);

    componentInstance.registerOnChange((value: string) => {
      titleFormControl.setValue(value);
    });

    componentInstance.registerOnTouched(() => {
      titleFormControl.markAsTouched();
      titleFormControl.markAsDirty();
    });

    titleFormControl.setValue(titleFormControl.value); // since the creation of the input component is after the form control fired, we need to fire again manually.

    const subscription = titleFormControl.statusChanges.subscribe(() => {
      const error = titleFormControl.errors?.['error'];
      componentRef.setInput('errorMessage', error);
    });

    this.destroyRef.onDestroy(() => {
      subscription?.unsubscribe();
      componentRef.destroy();
    });

    componentRef.onDestroy(() => {
      componentInstance.onChange = (value: string) => { };
      componentInstance.onTouched = () => { };
      if (titleFormControl) {
        // _unregisterOnChange is an angular internal function on the FormControl prototype.
        // It needs to be called to un-register a FormControl event listener.
        if ('_unregisterOnChange' in titleFormControl) {
          (titleFormControl._unregisterOnChange as any)(titleFormControlOnChangeFn);
        }
      }
    });

    const element = componentRef.location.nativeElement as HTMLElement;
    element.style.width = '10rem';
    customButtonPlaceholder.appendChild(componentRef.location.nativeElement);
  }

  onContentChanged(event: ContentChange) {
    const length = event.editor.getText().length - 1;
    // the quill maxLength feature isn't working...
    if (length > this.maxLength()) {
      event.editor.setContents(event.oldDelta);
      return;
    }
    this.setTextLength(event.editor);
  }

  setTextLength(editor: Quill) {
    const length = editor.getText().length - 1;
    this.textLength.set(length);
  }
}