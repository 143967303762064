import { inject, Injectable } from '@angular/core';
import { AlertController, AlertOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alertController = inject(AlertController);

  async alert(options: AlertOptions) {
    const alert = await this.alertController.create(options);
    await alert.present();
    const result = await alert.onDidDismiss();
    return result;
  }
}
