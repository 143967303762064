<quill-editor
  [modules]="editorModules()"
  class="!flex flex-col"
  (onEditorCreated)="onEditorCreated($event)"
  [formControl]="form.controls?.richTextEditor?.controls?.value!"
  [id]="id()"
  [placeholder]="placeholder()"
  [maxLength]="maxLength()"
  (onContentChanged)="onContentChanged($event)"
  #editor
></quill-editor>
<div>{{ textLength() }} / 500</div>
