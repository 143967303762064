<app-navbar
  [menuItems]="menuItems()"
  [popoverButtons]="popoverButtons()"
  [hideLogo]="true"
  class="py-2 w-full"
/>

<app-page-wrapper
  [title]="'Pages'"
  [fullName]="sessionService.account()?.full_name"
>
  @if (entities().length && !loading()) {
    <div
      class="grow shrink-0 grid grid-rows-[auto] grid-cols-[repeat(auto-fill,_minmax(240px,_1fr))] small-mobile:grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] gap-5 overflow-y-auto max-h-fit"
    >
      @for (entity of entities(); track entity) {
        <app-entity-card
          [entity]="entity"
          [routerLink]="'/dashboard/pages/' + entity.id"
        />
      }
    </div>
  } @else {
    <div
      class="flex items-center justify-center grow text-sm text-muted text-center"
    >
      No pages yet.
    </div>
  }
  <app-button
    class="right-content"
    buttonStyleClass="mobile:shadow-[-2px_3px_8px_2px_#00000042] mobile:!rounded-full mobile:h-16 mobile:w-16"
    [icon]="'add-outline'"
    (click)="newEntityPage()"
  >
    <span class="mobile:hidden">New page</span>
  </app-button>
  <ion-fab vertical="bottom" horizontal="end" class="hidden mobile:block">
    <ion-fab-button (click)="newEntityPage()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</app-page-wrapper>
