<ion-app>
  <ion-content>
    <ion-router-outlet
      [animated]="true"
      [swipeGesture]="true"
    ></ion-router-outlet>
    <app-tabs />
    @if (modalService.backdropVisible(); as visible) {
      <ion-backdrop
        [visible]="true"
        [tappable]="true"
        [stopPropagation]="true"
        class="z-40"
      />
      <ng-container
        *ngComponentOutlet="
          modalService.renderedComponent();
          inputs: modalService.renderedComponentProps()
        "
      />
    }
  </ion-content>
</ion-app>
