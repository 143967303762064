import { Component, inject, input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OperatingHour } from 'src/app/models/entity/edit/form';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'app-entity-read-hours',
  templateUrl: './entity-read-hours.component.html',
  styleUrls: ['./entity-read-hours.component.scss'],
})
export class EntityReadHoursComponent {
  dateService = inject(DateService);

  hours = input.required<EntityHours[] | undefined>();
}

type EntityHours = FormGroup<OperatingHour>['value'];
