import { Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap } from 'rxjs';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {

  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);

  route = toSignal(this.activatedRoute.url.pipe(
    map(url => url.map(u => u.path).reduce((prev, curr) => `${prev}/${curr}`, '')),
    map(url => `${window.location.origin}/${url}`)
  ));

  goHome() {
    this.router.navigateByUrl('/');
  }
}

