<button
  class="px-3 py-1.5 outline-none focus:shadow-focused w-full flex items-center justify-center gap-2 whitespace-nowrap {{
    outlined() ? 'outlined' : ''
  }} {{ type() }} {{ buttonStyleClass() }} {{ disabled() ? 'opacity-50' : '' }}"
  [disabled]="disabled()"
>
  @if (icon()) {
    <ion-icon [name]="icon()" class="text-lg" />
  }
  <ng-content />
</button>
