import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { emailValidator } from '../../validators/email';
import { AuthService } from 'src/app/services/auth.service';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { ToastButton } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-enter-email',
  templateUrl: './forgot-password-enter-email.component.html',
  styleUrls: ['./forgot-password-enter-email.component.scss'],
})
export class ForgotPasswordEnterEmailComponent {
  apiService = inject(ApiService);
  authService = inject(AuthService);
  toastService = inject(ToastService);
  router = inject(Router);

  form = new FormGroup({
    email: new FormControl('', [emailValidator()])
  });
  toastButtons: ToastButton[] = [
    { text: "Close", handler: () => this.emailSentMessage.set(undefined) }
  ];

  sending = signal(false);
  errorMessage = signal<string | undefined>(undefined);
  emailSentMessage = signal<string | undefined>(undefined);

  async sendCode() {
    if (this.sending()) return;
    markAllControlsAsTouchedAndDirty(this.form);
    if (this.form.invalid) return;
    this.sending.set(true);
    try {
      const email = this.form.value.email;
      if (!email) return; // this should never happen
      const response = await this.apiService.postAsync('/auth/send-otp', { body: { email } });
      this.toastService.success(response.message);
      this.router.navigateByUrl('/auth/verify-otp', { state: { email } });
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message);
    } finally {
      this.sending.set(false);
    }
  }

}
