<app-entity-edit-header
  [title]="'Search engine optimization'"
  [description]="'Help the search engine by providing additional details'"
  [backUrl]="'/dashboard/pages'"
/>
<app-entity-edit-wrapper>
  @if (entityEditComponent.modelLoading()) {
    <app-loader class="my-auto" />
  } @else {
    <div
      [formGroup]="searchForm()!"
      class="flex flex-col gap-5 max-w-screen-lg grow"
    >
      <app-tag-input
        [formControl]="searchForm()?.controls?.tags!"
        [label]="'Tags'"
        [description]="
          'Tags are critical for the search engine. By entering as many relevant keywords as possible, users will be able to find you faster. Hit \'Enter\' to add a tag'
        "
      />
      <div
        class="flex mobile:flex-col gap-3 self-end mobile:self-stretch mt-auto mb-0"
      >
        <app-button
          [type]="'dark'"
          [outlined]="true"
          class="ml-auto mr-0 mobile:m-0 mobile:order-2"
          (click)="onBack($event)"
          [disabled]="entityEditComponent.saving()"
        >
          Back
        </app-button>
        <app-button
          class="self-end mobile:self-stretch mobile:order-1"
          (click)="next.emit()"
          [disabled]="entityEditComponent.saving()"
        >
          {{ sessionService.isStaff() ? 'Save' : 'Next' }}
        </app-button>
      </div>
    </div>
  }
</app-entity-edit-wrapper>
