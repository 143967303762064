import { AfterViewInit, Component, computed, ElementRef, inject, input, model, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import HLS from 'hls.js';
import { DisplayMedia } from 'src/app/models/image';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements AfterViewInit {

  loggingService = inject(LoggingService);

  media = input.required<DisplayMedia>();
  video = viewChild<ElementRef<HTMLVideoElement>>('video');
  muted = model(false);
  paused = model(true);
  mutedChanged$ = toObservable(this.muted);
  isStreamable = computed(() => !!this.media().fileReferenceId);
  videoSrc = computed(() => {
    if (this.isStreamable()) return;
    return this.media().src;
  });

  constructor() {
    this.mutedChanged$.pipe(takeUntilDestroyed()).subscribe((muted) => this.setMuteValue(muted));
  }

  ngAfterViewInit() {
    this.attachHlsPlayer();
  }

  async play() {
    await this.video()?.nativeElement.play();
    this.paused.set(false);
  }

  pause() {
    this.video()?.nativeElement.pause();
    this.paused.set(true);
  }

  toggleMute() {
    const video = this.video();
    if (!video) return;
    video.nativeElement.muted = !video.nativeElement.muted;
    this.muted.set(video.nativeElement.muted);
  }

  togglePlay(event?: Event) {
    event?.stopPropagation();
    const video = this.video();
    if (!video) return;
    if (video.nativeElement.paused) {
      this.play();
    } else {
      this.pause();
    }
    this.paused.set(video.nativeElement.paused);
  }

  setMuteValue(muted: boolean) {
    const video = this.video();
    if (!video) return;
    video.nativeElement.muted = muted;
  }

  attachHlsPlayer() {
    if (!HLS.isSupported()) return;
    if (!this.isStreamable()) return;
    const src = this.media().src;
    if (!src) return;
    const video = this.video()?.nativeElement;
    if (!video) {
      this.loggingService.error('Video element not found. Error code 1118');
      return;
    };
    if (video.canPlayType('application/vnd.apple.mpegurl')) { // for Safari
      video.src = src;
      return;
    }
    const hls = new HLS({
      xhrSetup(xhr, url) {
        xhr.withCredentials = false;
      },
    });
    hls.attachMedia(video);
    hls.on(HLS.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(src);
    });
  }

  overlayClicked(event: Event) {
    event.stopPropagation();
  }
}
