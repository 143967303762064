import { ChangeDetectorRef, inject, Injectable, signal } from '@angular/core';
import { LocalStorageKey, LocalStorageService } from 'src/app/services/local-storage.service';
import { LOCALE_ID } from '@angular/core';
import { locales } from '../locales/locale';
import { registerClassOnWindow } from 'src/app/utils/global';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  userLocale = signal<Language | (string & {}) | undefined>(undefined);
  get isHebrew() { return this.userLocale() === 'he'; }
  get isEnglish() { return this.userLocale() === 'en'; }
  private readonly supportedLocales = ['en', 'he'];
  // whether to use 12 or 24 hour format
  timeFormat = signal<'12' | '24'>(this.resolveDefaultTimeFormat());

  localeId = inject(LOCALE_ID);
  localStorageService = inject(LocalStorageService);

  constructor() {
    registerClassOnWindow("LocalizationService", this);
    this.init();
  }

  private init() {
    // localStorage.setItem('Locale', 'he');
    // localStorage.setItem('Locale', 'en');
    const userPreferredLocale = this.localStorageService.getString(LocalStorageKey.Locale);
    if (userPreferredLocale && this.supportedLocales.includes(userPreferredLocale)) {
      this.userLocale.set(userPreferredLocale);
      return;
    }
    const locale = this.localeId.slice(0, 2);
    if (this.supportedLocales.includes(locale)) {
      this.userLocale.set(locale);
    }
    else {
      this.userLocale.set('en');
    }
  }

  changeLanguage(lang: Language, cdr: ChangeDetectorRef) {
    this.userLocale.set(lang);
    cdr.markForCheck();
    this.localStorageService.setString(LocalStorageKey.Locale, lang);
  }

  getLocalizedText(key: string) {
    key = key.trim();
    let locale = this.userLocale() ?? 'en';
    if (!this.supportedLocales.includes(locale))
      locale = 'en';
    return locales[key]?.[locale as 'en' | 'he'];
  }

  resolveDefaultTimeFormat(): TimeFormat {
    const randomDate = new Date();
    const localeTime = randomDate.toLocaleTimeString().toLowerCase();
    const is12 = localeTime.includes('am') || localeTime.includes('pm');
    return is12 ? '12' : '24';
  }
}

export type Language = 'en' | 'he';
export type TimeFormat = '12' | '24';