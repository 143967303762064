import { Component, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnInit {
  value = input<string>();
  required = input<boolean>();
  htmlFor = input<string>();
  constructor() { }

  ngOnInit() { }

}
