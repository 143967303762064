import { Component, forwardRef, input, model } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { registerClassOnWindow } from 'src/app/utils/global';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor {
  value = model.required<boolean | undefined>();
  label = input<string>('');
  styleClass = input<string>();

  constructor() {
    // registerClassOnWindow('SwitchComponent', this);
  }
  onChange = (value: boolean | undefined) => { };
  onTouched = () => { };
  writeValue(value: boolean | undefined) {
    this.value.set(value);
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  checkedChange(event: CustomEvent) {
    const eventDetail = event.detail as SwitchState;
    this.onChange(eventDetail.checked);
    this.value.set(eventDetail.checked);
  }
}

export interface SwitchState {
  checked: boolean;
  value: 'on' | 'off';
}