<div class="text-gray-50 font-[rounded] text-xl strong-text-shadow">
  {{ cardTypeText() || "Card" }}&nbsp;
</div>
<div class="mt-4">
  <div class="font-[credit-card] text-lg text-gray-50 strong-text-shadow">
    {{ cardNumberDisplay() }} &nbsp;
  </div>
  <app-credit-card-chip class="-mt-1 block" />
</div>
<div class="text-gray-50 flex justify-between">
  <div class="flex flex-col gap-1 font-[rounded]">
    <div class="text-xs">Card Holder Name</div>
    <div class="font-[rounded] strong-text-shadow">
      {{ nameOnCard() || "VALUED CARDHOLDER" }}
    </div>
  </div>
  <div class="flex flex-col gap-1 font-[rounded]">
    <div class="text-xs">Expiration Date</div>
    <div class="strong-text-shadow">
      {{ expirationDate() || "00/00" }}
    </div>
  </div>
</div>
