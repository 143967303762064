import { Component, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  type = input<'primary' | 'dark' | 'danger'>('primary');
  outlined = input(false);
  icon = input<string>();
  buttonStyleClass = input<string>();
  disabled = input<boolean>();
  constructor() { }

  ngOnInit() { }

}

