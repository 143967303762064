<app-navbar
  [hideLogo]="true"
  [menuItems]="menuItems()"
  [popoverButtons]="popoverButtons()"
/>
<ion-header class="hidden mobile:block">
  <ion-toolbar>
    <ion-title>Export</ion-title>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/admin" />
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<div class="mobile:hidden mobile:px-6 px-12 pt-6">
  <div class="max-w-screen-lg">
    <h1 class="text-2xl font-sherika-bold">Export</h1>
  </div>
</div>

<ion-content>
  <ion-list class="mobile:p-0 px-8 max-w-xl">
    <ion-item>
      <ion-label>Export pages to Excel</ion-label>
      <ion-button
        slot="end"
        shape="round"
        (click)="export()"
        [disabled]="exporting()"
      >
        <ion-icon slot="icon-only" name="cloud-download-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</ion-content>
