import { Component, forwardRef, input, model, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor {
  value = model();
  rows = input(3);
  label = input<string>();
  htmlFor = input<string>();
  textareaStyleClass = input<string>();
  placeholder = input<string>('');
  errorMessage = input<string>();

  onChange = (value: string) => { };
  onTouched = () => { };
  writeValue(value: string): void {
    this.value.set(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;
    this.value.set(value);
    this.onChange(value);
    this.onTouched();
  }
}
