import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { SupabaseService } from './supabase.service';
import { Session } from '@supabase/supabase-js';
import { registerClassOnWindow } from '../utils/global';
import { toObservable } from '@angular/core/rxjs-interop';
import { LocalStorageKey, LocalStorageService } from './local-storage.service';
import { Database } from 'supabase/functions/database.types';
import { Router } from '@angular/router';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  supabaseService = inject(SupabaseService);
  localStorageService = inject(LocalStorageService);
  router = inject(Router);

  session = signal<Session | null>(null);
  email = computed(() => this.session()?.user.email);
  userId = computed(() => this.session()?.user.id);
  sessionChanged$ = toObservable(this.session);
  sessionToken = computed(() => this.session()?.access_token);
  account = signal<DbAppUser | undefined | null>(this.localStorageService.get<DbAppUser>(LocalStorageKey.AppUser) || undefined);
  accountConfirmed = computed(() => this.account()?.is_email_confirmed);
  accountChanged$ = toObservable(this.account);
  isLoggedIn = computed(() => !!this.session()?.access_token);
  isAdmin = computed(() => this.account()?.role === Role.Admin);
  isStaff = computed(() => {
    const account = this.account();
    return this.isStaffAccount(account);
  });

  constructor() {
    registerClassOnWindow('SessionService', this);
    this.supabaseService.supabase.auth.onAuthStateChange((event, session) => {
      const sessionWasCleared = !session && !!this.session();
      if (sessionWasCleared) {
        this.userLoggedOut();
      }
      // const emailConfirmed = session?.user.email_confirmed_at;
      // this.emailPendingConfirmation.set(!emailConfirmed);
      this.session.set(session);
    });

    effect(() => {
      const _ = this.session();
      this.retrieveAdditionalUserData();
    });

    this.accountChanged$.subscribe((value) => {
      // we want to cache the app_user (i.e. account) in case the page gets refreshed by the user.
      if (!value)
        this.localStorageService.remove(LocalStorageKey.AppUser);
      else
        this.localStorageService.set(LocalStorageKey.AppUser, value);
    });
  }

  async retrieveAdditionalUserData() {
    const { data } = await this.supabaseService.supabase.from('account').select("*");
    const account = data?.[0];
    this.account.set(account);
    return account;
  }

  userLoggedOut() {
    this.router.navigateByUrl('/');
  }

  async getUser(options?: { overrideSession?: boolean; }) {
    const existingSession = this.session();
    if (existingSession) return existingSession;
    const { data } = await this.supabaseService.supabase.auth.getSession();
    if (options?.overrideSession && !this.session()) {
      this.session.set(data.session);
    }
    return data.session;
  }

  async getUserId() {
    const session = await this.getUser();
    return session?.user.id;
  }

  async getAccount() {
    const existingAccount = this.account();
    if (existingAccount) return existingAccount;
    const account = await this.retrieveAdditionalUserData();
    this.account.set(account);
    return account;
  }

  isStaffAccount(account: DbAppUser | undefined | null) {
    if (!account) return false;
    return account.role === Role.Staff;
  }

  async isStaffAccountAsync() {
    const account = await this.getAccount();
    if (!account) return false;
    return this.isStaffAccount(account);
  }
}

export type DbAppUser = Database['public']['Tables']['account']['Row'];