import { effect, inject, Injectable, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryParams } from '../models/query-params';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  public queryParams = toSignal(this.activatedRoute.queryParams) as Signal<QueryParams>;

  set<T extends keyof QueryParams>(key: T, value: QueryParams[T]): void;
  set(params: Partial<QueryParams>): void;
  set<T extends keyof QueryParams>(params: Partial<QueryParams> | T, value?: QueryParams[T] | void): void {
    if (typeof params === 'object') {
      this.router.navigate([], { queryParams: { ...this.queryParams(), ...params, } });
    } else {
      this.router.navigate([], { queryParams: { ...this.queryParams(), [params]: value } });
    }
  }

}
