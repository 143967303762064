import { Injectable } from '@angular/core';
import { EntityEditForm } from '../models/entity/edit/form';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EntityEditService {

  alwaysOpenChange(form: FormGroup<EntityEditForm>, isAlwaysOpen: boolean | undefined) {
    form.controls.hours.controls.days.controls.forEach(c => {
      if (isAlwaysOpen) c.disable();
      else c.enable();
    });
  }
}
