import { HttpErrorResponse } from '@angular/common/http';
import { Component, computed, inject, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { filter, interval, map, merge, of, startWith, Subject, Subscription, switchMap } from 'rxjs';
import { CustomFieldType } from 'src/app/models/entity/custom-field';
import { CustomFieldValue, EntityEditFormValue } from 'src/app/models/entity/edit/form';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { CurrentOperatingHourStatus, DateService } from 'src/app/services/date.service';
import { FileService } from 'src/app/services/file.service';
import { ModalService } from 'src/app/services/modal.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { toNumber } from 'src/app/utils/number';
import { resolveValidWebUrl } from 'src/app/utils/url';
import { SupabaseService } from 'src/app/services/supabase.service';
import { DisplayMedia } from 'src/app/models/image';
import { EntityReadService } from 'src/app/services/entity-read.service';
import { groupBy } from 'src/app/utils/array';
import { LoggingService } from 'src/app/services/logging.service';
import { MenuItem } from 'src/app/models/menu-item';
import { PopoverButton } from '../../ui/popover-buttons/popover-buttons.component';
import { AuthService } from 'src/app/services/auth.service';
import { NavController, ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { registerClassOnWindow } from 'src/app/utils/global';
import { VisibilityService } from 'src/app/services/visibility.service';

@Component({
  selector: 'app-entity-read',
  templateUrl: './entity-read.component.html',
  styleUrls: ['./entity-read.component.scss'],
})
export class EntityReadComponent implements ViewDidLeave, ViewWillEnter {

  activatedRoute = inject(ActivatedRoute);
  router = inject(NavController);
  apiService = inject(ApiService);
  toastService = inject(ToastService);
  fileService = inject(FileService);
  sessionService = inject(SessionService);
  dateService = inject(DateService);
  alertService = inject(AlertService);
  modalService = inject(ModalService);
  supabaseService = inject(SupabaseService);
  entityReadService = inject(EntityReadService);
  loggingService = inject(LoggingService);
  authService = inject(AuthService);
  visibilityService = inject(VisibilityService);

  loading = signal(false);
  entityId$ = this.activatedRoute.params.pipe(
    map(params => toNumber(params['entityId'])),
  );
  entityId = toSignal(this.entityId$);
  model = signal<EntityEditFormValue | undefined>(undefined);
  modelChange$ = toObservable(this.model);
  reload$ = new Subject<void>();
  general = computed(() => this.model()?.general);
  hours = computed(() => this.model()?.hours?.days);
  avatarImageUrl = computed(() => {
    const objectName = this.general()?.avatar?.[0]?.objectName;
    if (!objectName) return;
    const url = this.fileService.getFullImageUrl('avatar', objectName);
    return url;
  });
  profilePhotoUrl = computed(() => {
    const objectName = this.general()?.profilePhoto?.objectName;
    if (!objectName) return;
    const url = this.fileService.getFullImageUrl('profile-photo', objectName);
    return url;
  });

  isUnclaimed = computed(() => this.model()?.general?.isUnclaimed);
  isUserOwner = computed(() => this.entityReadService.userOwnsPage(this.model()?.general?.userId));

  showActionButtons = computed(() => {
    const isUserOwner = this.isUserOwner();
    if (isUserOwner) return true;

    const isUnclaimed = this.model()?.general?.isUnclaimed && !isUserOwner;
    if (isUnclaimed) return true;
    return false;
  });

  description = computed(() => {
    const description = this.model()?.general?.description;
    if (!description) return;
    return description.split(/\n+/g);
  });

  CustomFieldType = CustomFieldType;
  updatingHidePage = signal(false);
  pageCurrentlyHidden = computed(() => !!this.model()?.general?.hideFromSearch);
  hideFromPageButtonText = computed(() => {
    const updatingHidePage = this.updatingHidePage();
    const pageCurrentlyHidden = this.pageCurrentlyHidden();
    if (updatingHidePage && pageCurrentlyHidden) return "Un-hiding from search";
    if (updatingHidePage && !pageCurrentlyHidden) return "Hiding from search";
    if (pageCurrentlyHidden) return "Un-hide from search";
    return "Hide from search";
  });

  subscription = new Subscription();

  menuItems = computed<MenuItem[]>(() => [
    { label: "Profile", href: "/user/account/profile", icon: "person-circle-outline" },
    { label: "Support", href: "/user/account/feedback", icon: "help-circle-outline" },
  ]);
  popoverButtons = computed<PopoverButton[]>(() => [{ label: "Log out", action: () => this.authService.logout(), icon: "power-outline" }]);

  shouldShowProcessing = computed<boolean>(() => {
    const isLoggedIn = this.sessionService.isLoggedIn();
    if (!isLoggedIn) return false;
    const loggedInUserId = this.sessionService.userId();
    if (!loggedInUserId) return false;
    return loggedInUserId === this.model()?.general?.userId;
  });

  currentOperatingHourStatus$ = merge(interval(30 * 60 * 1000), this.visibilityService.visibleChanged$, this.modelChange$).pipe(
    startWith(0), // start running immediately
    filter(() => this.visibilityService.visible()),
    switchMap(() => of(this.getIsOpenStatus()))
  );

  currentOperatingHourStatus = toSignal(this.currentOperatingHourStatus$);
  CurrentOperatingHourStatus = CurrentOperatingHourStatus;

  constructor() {
    registerClassOnWindow('EntityReadComponent', this);
  }

  ionViewWillEnter(): void {
    this.subscription.add(this.entityId$.subscribe(() => this.load()));
    this.subscription.add(this.sessionService.sessionChanged$.subscribe(() => this.load()));
  };

  ionViewDidLeave(): void {
    this.subscription.unsubscribe();
    this.model.set(undefined);
  }

  async load() {
    if (this.model()) return; // already loaded
    if (this.loading()) return;
    const entityId = this.entityId();
    if (!entityId) return;
    this.loading.set(true);
    try {
      const response = await this.apiService.getAsync<EntityEditFormValue>(`entity/read?entityId=${entityId}`);
      this.setModel(response.data);
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message ?? error.message);
    } finally {
      this.loading.set(false);
    }
  }

  resolveValidWebUrl(url: string) {
    return resolveValidWebUrl(url);
  }

  navigateToEditPage() {
    this.router.navigateForward(`/dashboard/pages/${this.entityId()}/edit?activeIndex=0`, { animated: true, animationDirection: 'forward' });
  }

  getFirstCustomFieldImage(customFieldImage: CustomFieldValue['imagePicker']) {
    const objectName = customFieldImage?.value?.[0]?.objectName;
    if (!objectName) return;
    return this.fileService.getFullImageUrl('images', objectName);
  }

  async hidePage() {
    if (this.updatingHidePage()) return;
    const entityId = this.entityId();
    if (!entityId) {
      this.toastService.error("There was an error hiding your page.");
      this.loggingService.error("Entity id not found when hiding page. Error code 1109");
      return;
    }
    this.updatingHidePage.set(true);
    const shouldHide = !this.model()?.general?.hideFromSearch;
    const { error, data } = await this.supabaseService.supabase.from('entity').update({ hide_from_search: shouldHide }).eq('id', entityId).select('*');
    if (error) {
      let errorMessage = "";
      if (shouldHide) {
        errorMessage = "There was an error hiding your page";
      } else {
        errorMessage = "There was an error un-hiding your page";
      }
      this.toastService.error(errorMessage);
    } else {
      const model = this.model();
      if (model?.general) {
        model.general.hideFromSearch = data[0]?.hide_from_search;
        this.model.set({ ...model });
      }
      let successMessage = "";
      if (shouldHide) {
        successMessage = "Page hidden successfully";
      } else {
        successMessage = "Page un-hidden successfully";
      }
      this.toastService.info(successMessage);
    }
    this.updatingHidePage.set(false);
  }

  setModel(value: EntityEditFormValue | null | undefined) {
    this.model.set(value ?? undefined);
    if (!value) return;
    const grouped = groupBy(value?.customFields ?? [], 'fieldType');
    grouped[CustomFieldType.Images]?.forEach((cf, i) => {
      const images = cf.imagePicker?.value;
      if (!images) return;
      images.forEach(image => {
        if (!image) return;
        if (!image.bucketName || !image.objectName) return;
        image.src = this.fileService.getFullImageUrl(image.bucketName, image.objectName);
      });
    });
    grouped[CustomFieldType.Videos]?.forEach((cf, i) => {
      const videos = cf.videoPicker?.value;
      if (!videos) return;
      videos.forEach(video => {
        if (!video) return;
        if (!video.objectName) return;
        video.src = this.fileService.getStreamableVideoUrl(video.objectName);
        video.thumbnail = this.fileService.getStreamableVideoThumbnailUrl(video.objectName);
      });
    });
  }

  getCustomFieldImageUrls(customFieldIndex: number): DisplayMedia[] {
    const images = this.model()?.customFields?.[customFieldIndex]?.imagePicker?.value;
    return images ?? [];
  }

  getCustomFieldVideoUrls(customFieldIndex: number): DisplayMedia[] {
    const videos = this.model()?.customFields?.[customFieldIndex]?.videoPicker?.value;
    return videos ?? [];
  }

  async copyToClipboard(text: string | undefined) {
    if (!text) return;
    window.navigator.clipboard.writeText(text)
      .then(() => this.toastService.info("Copied to clipboard"))
      .catch(() => this.loggingService.error("Error copying to clipboard. Error code 1110"));
  }

  getGoogleMapsUrl() {
    const address = this.general()?.address?.label;
    if (!address) {
      this.loggingService.error("Address not found when trying to get google maps url. Error code 1111");
      return;
    };
    return `https://www.google.com/maps/search/?api=1&query=${address}`;
  }

  call() {
    const phoneNumber = this.general()?.phoneNumber;
    if (!phoneNumber) {
      this.loggingService.error("Phone number not found when trying to call. Error code 1112");
      return;
    };
    const url = `tel:${phoneNumber}`;
    window.open(url);
  }

  openEmailApp() {
    const emailAddress = this.general()?.email;
    if (!emailAddress) {
      this.loggingService.error("Email address not found when trying to open email app. Error code 1113");
      return;
    }
    const url = `mailto:${emailAddress}`;
    window.open(url);
  }

  openWebpageUrl() {
    let website = this.general()?.website;
    if (!website) {
      this.loggingService.error("Website not found when trying to open webpage. Error code 1114");
      return;
    }
    if (!website.startsWith('http') && website.includes('://')) return; // unsafe
    if (!website.startsWith('http')) {
      website = 'https://' + website;
    }
    window.open(website, '_blank');
  }

  editOperatingHours() {
    const entityId = this.entityId();
    this.router.navigateForward(`/dashboard/pages/${entityId}/edit?activeIndex=1`);
  }

  getIsOpenStatus() {
    const operatingHours = this.model()?.hours;
    if (!operatingHours?.days) return;
    return this.dateService.getIsCurrentlyOpenStatus(operatingHours.days);
  }

  handleClaimPage() {
    const isLoggedIn = this.sessionService.isLoggedIn();
    const entityId = this.entityId();
    if (!isLoggedIn) {
      const returnUrl = encodeURIComponent(`/dashboard/pages/${entityId}/claim`);
      this.router.navigateForward(`/auth/signup?authReturnUrl=${returnUrl}`);
      return;
    }
    this.router.navigateForward(`/dashboard/pages/${entityId}/claim`);
  }
}
