<ul class="gap-4">
  @if (!hideLogo()) {
  <li>
    <a href="/">
      <app-logo />
    </a>
  </li>
  } @for (menuItem of menuItems(); track menuItem) {
  <li (click)="menuItem.action?.($event)">
    <a [routerLink]="menuItem.href">{{ menuItem.label }}</a>
  </li>
  }
</ul>
<ul>
  @if (sessionService.isLoggedIn()) {
  <div class="pr-4">
    <app-popover-buttons
      [buttons]="popoverButtons()"
      [label]="sessionService.account()?.full_name"
    />
  </div>
  } @else {
  <li>
    <a [routerLink]="'/auth/login'">
      <app-text-button>Login</app-text-button>
    </a>
  </li>
  <li>
    <a [routerLink]="'/auth/signup'">
      <app-button>Sign Up</app-button>
    </a>
  </li>
  }
</ul>
