import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { passwordValidator } from '../../validators/password';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { AuthService } from 'src/app/services/auth.service';
import { Navigation, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent {
  authService = inject(AuthService);
  sessionService = inject(SessionService);
  router = inject(Router);
  apiService = inject(ApiService);
  toastService = inject(ToastService);

  form = new FormGroup({
    password: new FormControl('', [passwordValidator]),
  });

  updatingPassword = signal(false);
  passwordResetToken = signal<string | undefined>(undefined);
  email = signal<string | undefined>(undefined);

  constructor() {
    const currentNavigation = this.router.getCurrentNavigation();
    this.setEmail(currentNavigation);
    this.setPasswordResetToken(currentNavigation);
  }

  setEmail(currentNavigation: Navigation | null) {
    const email = currentNavigation?.extras.state?.['email'] as string | undefined;
    if (!email) {
      this.goToEnterEmail();
      return;
    }
    this.email.set(email);
  }

  setPasswordResetToken(currentNavigation: Navigation | null) {
    const passwordResetToken = currentNavigation?.extras.state?.['token'] as string | undefined;
    if (!passwordResetToken) {
      this.goToEnterEmail();
      return;
    }
    this.passwordResetToken.set(passwordResetToken);
  }

  goToEnterEmail() {
    this.router.navigateByUrl('auth/enter-id');
  }

  async updatePassword() {
    if (this.updatingPassword()) return;
    markAllControlsAsTouchedAndDirty(this.form);
    if (this.form.invalid) return;
    this.updatingPassword.set(true);

    const password = this.form.value.password;
    if (!password) return;

    const email = this.email();
    if (!email) {
      this.goToEnterEmail();
      return;
    }

    const token = this.passwordResetToken();
    if (!token) {
      this.goToEnterEmail();
      return;
    }

    try {
      const response = await this.apiService.postAsync<string, ResetPasswordWithTokenOptions>('/auth/reset-password', {
        body: {
          email,
          newPassword: password,
          token
        }
      });
      this.toastService.success(response.message ?? "Password reset successfully");
      await this.authService.login({
        email,
        password,
      });
      this.router.navigateByUrl('/');
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message ?? "There was an error resetting your password");
    } finally {
      this.updatingPassword.set(false);
    }
  }
}

export interface ResetPasswordWithTokenOptions {
  email: string;
  token: string;
  newPassword: string;
}