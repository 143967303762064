import { Component, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  host: {
    '[class]': 'styleClass()'
  }
})
export class LoaderComponent {
  text = input<string>('Loading...');
  styleClass = input<string>('');
}
