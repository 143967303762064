import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { SignupComponent } from './components/auth/signup/signup.component';
import { LoginComponent } from './components/auth/login/login.component';
import { ForgotPasswordEnterEmailComponent } from './components/auth/forgot-password-enter-email/forgot-password-enter-email.component';
import { UpdatePasswordComponent } from './components/auth/update-password/update-password.component';
import { UpgradeComponent } from './components/entity/upgrade/upgrade.component';
import { EntityEditComponent } from './components/entity/entity-edit/entity-edit.component';
import { DashboardPage } from './components/dashboard/dashboard.component';
import { SubscriptionComponent } from './components/dashboard/subscription/subscription.component';
import { SettingsComponent } from './components/dashboard/settings/settings.component';
import { SupportComponent } from './components/dashboard/support/support.component';
import { PagesComponent } from './components/dashboard/pages/pages.component';
import { EntityReadComponent } from './components/entity/entity-read/entity-read.component';
import { NotFoundComponent } from './components/ui/not-found/not-found.component';
import { Tab } from './types/tab';
import { AccountHomeComponent } from './components/account-home/account-home.component';
import { AccountProfileComponent } from './components/account-home/account-profile/account-profile.component';
import { AccountPasswordComponent } from './components/account-home/account-password/account-password.component';
import { AccountFeedbackComponent } from './components/account-home/account-feedback/account-feedback.component';
import { authGuard } from './guards/auth.guard';
import { unauthGuard } from './guards/unauth.guard';
import { upgradeResolver } from './resolvers/upgrade.resolver';
import { subscriptionGuard } from './guards/subscription.guard';
import { ConfirmEmailComponent } from './components/auth/confirm-email/confirm-email.component';
import { entityGuard } from './guards/entity.guard';
import { TakePaymentComponent } from './components/take-payment/take-payment.component';
import { UsersComponent } from './components/dashboard/admin/users/users.component';
import { EntityClaimVerifyComponent } from './components/entity/entity-claim-verify/entity-claim-verify.component';
import { ExportComponent } from './components/dashboard/admin/export/export.component';
import { AdminComponent } from './components/dashboard/admin/admin.component';
import { UserDetailsComponent } from './components/dashboard/admin/users/user-details/user-details.component';
import { adminGuard } from './guards/admin.guard';
import { BulkPagesComponent } from './components/dashboard/bulk-pages/bulk-pages.component';
import { staffGuard } from './guards/staff.guard';
import { ForgotPasswordVerifyOtpComponent } from './components/auth/forgot-password-verify-otp/forgot-password-verify-otp.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: { tab: Tab.Home } },
  { path: 'auth/signup', component: SignupComponent, data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/login', component: LoginComponent, data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/enter-id', component: ForgotPasswordEnterEmailComponent, data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/verify-otp', component: ForgotPasswordVerifyOtpComponent, data: { tab: Tab.None }, canActivate: [unauthGuard] },
  { path: 'auth/reset-password', component: UpdatePasswordComponent, data: { tab: Tab.None } },
  { path: 'auth/confirm-email', component: ConfirmEmailComponent, data: { tab: Tab.None } },
  { path: 'user/upgrade', component: UpgradeComponent, data: { tab: Tab.None }, canActivate: [authGuard], resolve: { promoCode: upgradeResolver } },
  { path: 'user/pay', component: TakePaymentComponent, data: { tab: Tab.None }, canActivate: [authGuard] },
  {
    path: 'dashboard',
    component: DashboardPage,
    data: { tab: Tab.Business },
    children: [
      { path: '', redirectTo: 'pages', pathMatch: 'full', data: { tab: Tab.Business }, },
      { path: 'pages', component: PagesComponent, data: { tab: Tab.Business }, canActivate: [authGuard] },
      { path: 'pages/:entityId', component: EntityReadComponent, data: { tab: Tab.Business } },
      { path: 'pages/:entityId/edit', component: EntityEditComponent, data: { tab: Tab.Business }, canActivate: [authGuard, entityGuard, subscriptionGuard] },
      { path: 'pages/:entityId/claim', component: EntityClaimVerifyComponent, data: { tab: Tab.Business } },
      { path: 'subscription', component: SubscriptionComponent, data: { tab: Tab.Business }, canActivate: [entityGuard] },
      { path: 'settings', component: SettingsComponent, data: { tab: Tab.Business }, canActivate: [entityGuard] },
      { path: 'support', component: SupportComponent, data: { tab: Tab.Business }, canActivate: [entityGuard] },
      { path: 'users', component: UsersComponent, data: { tab: Tab.Admin } },
      { path: "users/:userId", component: UserDetailsComponent, data: { tab: Tab.Admin }, canActivate: [adminGuard] },
      { path: "export", component: ExportComponent, data: { tab: Tab.Admin }, canActivate: [adminGuard] },
      { path: 'bulk-pages', component: BulkPagesComponent, data: { tab: Tab.Admin }, canActivate: [staffGuard] }
    ]
  },
  { path: 'admin', component: AdminComponent, data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  { path: "admin/users", component: UsersComponent, data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  { path: "admin/users/:userId", component: UserDetailsComponent, data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  { path: "admin/export", component: ExportComponent, data: { tab: Tab.Admin }, canActivate: [adminGuard] },
  {
    path: 'user/account', component: AccountHomeComponent, data: { tab: Tab.Account }, canActivate: [authGuard], children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: AccountProfileComponent, data: { tab: Tab.Account } },
      { path: 'password', component: AccountPasswordComponent, data: { tab: Tab.Account } },
      { path: 'feedback', component: AccountFeedbackComponent, data: { tab: Tab.Account } }
    ]
  },
  { path: ':entityId', component: HomeComponent, data: { tab: Tab.Home } },
  { path: '**', component: NotFoundComponent, data: { tab: Tab.None } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }