import { Component, computed, input } from '@angular/core';
import { ForInsert } from 'src/app/types/database';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss'],
})
export class EntityCardComponent {

  entity = input.required<ForInsert<'entity'>>();
  environment = environment;
  avatarUrl = computed(() => {
    const objectId = this.entity().avatar_id;
    if (!objectId) return;
    const base = this.environment.supabaseUrl;
    const fullUrl = `${base}/storage/v1/object/public/avatar/${objectId}`;
    return fullUrl;
  });
}
