import { inject, Injectable } from '@angular/core';
import { registerClassOnWindow } from '../utils/global';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toastController = inject(ToastController);
  toastDuration = 4_000;

  constructor() {
    registerClassOnWindow('ToastService', this);
  }

  success(message: string | undefined, dismissButton?: boolean) {
    this.addToast({ type: 'success', message, dismissButton, icon: 'checkmark-circle-outline' });
  }

  error(message: string | undefined, dismissButton?: boolean) {
    this.addToast({ type: 'danger', message, dismissButton, icon: 'close-circle-outline' });
  }

  info(message: string | undefined, dismissButton?: boolean) {
    this.addToast({ type: 'primary', message, dismissButton, icon: 'information-circle-outline' });
  }

  warning(message: string | undefined, dismissButton?: boolean) {
    this.addToast({ type: 'warning', message, dismissButton, icon: 'warning-outline' });
  }

  private async addToast(newToast: Toast) {
    const toast = await this.toastController.create({
      duration: this.toastDuration,
      icon: newToast.icon,
      message: newToast.message,
      color: newToast.type,
    });
    toast.present();
  }

}

export interface Toast {
  type?: ToastType;
  message?: string;
  dismissButton?: boolean;
  icon?: string;
}

export type ToastType = 'primary' | 'warning' | 'danger' | 'success';