import { Component, computed, inject, input } from '@angular/core';
import { Location } from '@angular/common';
import { EntityEditComponent } from '../entity-edit.component';
import { toNumber } from 'src/app/utils/number';

@Component({
  selector: 'app-entity-edit-header',
  templateUrl: './entity-edit-header.component.html',
  styleUrls: ['./entity-edit-header.component.scss'],
})
export class EntityEditHeaderComponent {
  location = inject(Location);
  entityEditComponent = inject(EntityEditComponent);

  title = input.required<string>();
  description = input.required<string>();
  backUrl = input.required<string>();

  activeIndex = computed(() => {
    const currentIndex = this.entityEditComponent.activeIndex() ?? '0';
    return currentIndex;
  });
  progress = computed(() => {
    const activeIndex = this.activeIndex();
    const activeIndexNum = toNumber(activeIndex);
    return activeIndexNum * 33.33 / 100;
  });

  onBack() {
    this.location.back();
  }
}
