import { Component, computed, effect, inject, input, OnInit, output, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropdownOption } from 'src/app/components/ui/form/dropdown/dropdown.component';
import { countries } from 'src/app/data/countries';
import { EntityEditForm } from 'src/app/models/entity/edit/form';
import { AddressService } from 'src/app/services/address.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { EntityEditComponent } from '../entity-edit.component';

@Component({
  selector: 'app-entity-edit-general',
  templateUrl: './entity-edit-general.component.html',
  styleUrls: ['./entity-edit-general.component.scss'],
})
export class EntityEditGeneralComponent implements OnInit {
  sessionService = inject(SessionService);
  addressService = inject(AddressService);
  toastService = inject(ToastService);
  entityEditComponent = inject(EntityEditComponent);

  form = input.required<FormGroup<EntityEditForm>>();
  generalForm = computed(() => this.form().controls.general);
  countries = signal<DropdownOption<string | number>[]>([]);
  next = output<void>();
  usernameOutput = signal<UsernameOutput | undefined>(undefined);
  usernameAvailableMessage = computed(() => {
    const usernameOutput = this.usernameOutput();
    if (usernameOutput?.type === 'success') return usernameOutput.message;
    return undefined;
  });
  usernameNote = computed(() => {
    const usernameOutput = this.usernameOutput();
    if (usernameOutput?.type === 'info') return usernameOutput.message;
    return undefined;
  });

  ngOnInit() {
    this.countries.set(countries.map<DropdownOption>(country => ({ label: country.name, value: country.code, emoji: country.emoji })));
    const usernameFormControl = this.form().controls.general.controls.username;
    usernameFormControl.statusChanges.subscribe(status => {
      if (status === 'VALID' && usernameFormControl.value?.trim() && (usernameFormControl.dirty || usernameFormControl.touched)) {
        this.usernameOutput.set({ message: 'Username is available', type: 'success' });
      } else if (status === 'PENDING') {
        this.usernameOutput.set({ message: 'Checking availability...', type: 'info' });
      }
      else {
        this.usernameOutput.set(undefined);
      }
    });
  }

  loadAddresses = async (value: string | undefined): Promise<DropdownOption[]> => {
    const predictions = await this.addressService.getPlacePredictions(value);
    return predictions.map(p => ({ label: p.description, value: p.place_id }));
  };

  onNext(event: MouseEvent) {
    event.preventDefault();
    const generalForm = this.generalForm();
    markAllControlsAsTouchedAndDirty(generalForm);
    if (generalForm.invalid) {
      this.toastService.error("Please fix the errors in order to continue");
      return;
    };
    this.next.emit();
  }
}

export interface UsernameOutput {
  message: string;
  type: 'error' | 'success' | 'info';
}