<app-logo />
<div class="flex items-center justify-center flex-col gap-2 grow">
  <h1 class="text-3xl">Page not found!</h1>
  <p class="text-muted">
    The page <span class="font-mono">"{{ route() }}"</span> does not exist
  </p>
  <app-button [icon]="'home-outline'" class="mt-2" (click)="goHome()">
    Take me home
  </app-button>
</div>
