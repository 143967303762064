import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

/**
 * Use this service to tell when the tab (or app) loses focus
 */
@Injectable({
  providedIn: 'root'
})
export class VisibilityService {
  visible = signal<boolean>(true);
  visibleChanged$ = toObservable(this.visible);

  constructor() {
    document.addEventListener('visibilitychange', () => {
      const isVisible = document.visibilityState === 'visible';
      this.visible.set(isVisible);
    });
  }

}
