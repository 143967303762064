import { Dictionary } from "../models/dictionary";

export function removeItemFromArray<T, I extends number | T>(array: T[], item: I) {
  const arrayIndex = typeof item === 'number' ? item : array.indexOf(item as T);
  if (arrayIndex < 0) return [];
  return array.splice(arrayIndex, 1);
}

export function groupBy<T extends object>(array: T[], key: keyof T): Dictionary<T[]> {
  return array.reduce((acc, item) => {
    const value = item[key] as unknown as string | number;
    if (!acc[value]) acc[value] = [];
    acc[value]?.push(item);
    return acc;
  }, {} as Dictionary<T[]>);
}