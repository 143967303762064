import { ValidatorFunction } from "src/app/models/validator";
import { toNumber } from "src/app/utils/number";

export function creditCardDateValidator(): ValidatorFunction {
  return function (control) {
    if (!control.touched || !control.dirty) return null;
    const value = control.value as string;
    if (value.length < 5) {
      return { error: "Invalid date" };
    }
    if (!value.includes('/')) {
      return { error: "Invalid date" };
    }
    const [month, year] = value.split('/') as [string, string];
    const monthNum = toNumber(month);
    const yearNum = toNumber("20" + year);
    if (monthNum < 1 || monthNum > 12) {
      return { error: "Invalid date" };
    }
    const today = new Date();
    const isCardExpired = ((today.getMonth() + 1) > monthNum && today.getFullYear() === yearNum) || today.getFullYear() > yearNum;
    if (isCardExpired) {
      return { error: "Card expired" };
    }
    return null;
  };
}