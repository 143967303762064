export function resolveValidWebUrl(url: string) {
  try {
    if (!url.startsWith('http')) {
      if (url.includes('://')) {
        // TODO log error
        throw new Error("Only HTTP URLs are allowed!");
      }
      url = `https://${url}`;
    }
    const urlObject = new URL(url);
    if (urlObject.hostname === 'localhost') {
      // TODO log error
      throw new Error("Invalid URL");
    }
    if (urlObject.protocol !== 'https:') {
      urlObject.protocol = 'https:';
    }
    return urlObject.toString();
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function getLastSegment(url: string) {
  try {
    const urlObject = new URL(url);
    let pathname = urlObject.pathname;

    if (pathname.endsWith('/') && pathname.length > 1) {
      pathname = pathname.slice(0, -1);
    }

    const segments = pathname.split('/');
    return segments[segments.length - 1];
  } catch (e) {
    // TODO log error
    console.error(e);
    return null;
  }
}

export function isUrlValid(str: string) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return pattern.test(str);
}