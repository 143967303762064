@for (media of mediaToShow(); track media) {
<div class="relative">
  <button
    class="absolute -right-2 -top-2 p-0.5 border border-normal border-solid leading-3 bg-white rounded-full z-10"
    (click)="deleteMedia(media)"
  >
    <ion-icon
      name="close-outline"
      class="text-xl text-normal cursor-pointer"
    ></ion-icon>
  </button>
  <img
    [src]="media.thumbnail || media.src"
    [alt]="media.originalFile?.name"
    class="w-16 h-16 object-cover rounded-lg border border-solid border-light cursor-pointer peer"
    (click)="openMediaSlideshow($index)"
  />
  <div
    class="absolute pointer-events-none inset-0 peer-hover:bg-[#00000012] peer-active:bg-[#00000024] transition-colors rounded-lg"
  ></div>
</div>
}
<div>
  <label
    [htmlFor]="id()"
    class="flex items-center justify-center w-16 h-16 rounded-lg border border-solid border-light cursor-pointer transition-colors hover:bg-gray-50 active:bg-gray-200 shadow-sm"
  >
    <ion-icon
      name="add-outline"
      [size]="'large'"
      class="text-normal"
    ></ion-icon>
  </label>
  <input
    type="file"
    class="hidden"
    [id]="id()"
    [multiple]="true"
    (change)="addMedia($event)"
    [accept]="supportedMimeTypesString()"
  />
</div>
