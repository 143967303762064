<ion-content>
  <app-navbar
    [menuItems]="menuItems()"
    [popoverButtons]="popoverButtons()"
    [hideLogo]="true"
    class="py-2 px-7 max-w-screen-xl mx-auto"
  />
  @if (loading()) {
    <app-loader />
  } @else if (model()) {
    <div
      class="max-w-screen-xl mx-auto flex flex-col justify-start gap-8 pb-10 px-10 mobile:p-0 mobile:gap-4 w-full"
    >
      <div class="flex flex-col gap-8 mobile:gap-4">
        <div class="flex flex-col items-stretch">
          <div class="w-full aspect-[16/6] min-h-0 relative">
            <app-back-button class="absolute left-3 top-3" />
            @if (avatarImageUrl(); as avatar) {
              <img
                [src]="avatar"
                class="w-full h-full object-cover rounded-md mobile:rounded-none border border-solid border-black/10"
              />
            } @else {
              <div
                class="flex items-center justify-center text-4xl w-full h-full text-slate-500 select-none bg-slate-50 rounded-md"
              >
                <ion-img
                  src="assets/images/koshery-logo.svg"
                  class="pointer-events-none grayscale w-[40%] max-w-sm"
                />
              </div>
            }
          </div>
          <div
            class="-mt-8 mobile:-mt-6 flex items-end justify-start gap-4 mobile:gap-2 max-w-full"
          >
            <div
              class="ml-6 w-28 h-28 mobile:w-20 mobile:h-20 border-2 border-solid border-white rounded-full bg-white z-20 relative shrink-0 basis-auto"
            >
              @if (profilePhotoUrl(); as profilePhoto) {
                <ion-img
                  [src]="profilePhoto"
                  class="object-cover w-full h-full border border-solid border-black/5 rounded-full overflow-hidden"
                />
              } @else {
                <div
                  class="border border-solid border-black/5 rounded-full overflow-hidden flex items-center justify-center w-full h-full"
                >
                  <ion-img
                    src="assets/images/koshery-icon.svg"
                    class="w-2/5 h-2/5 grayscale opacity-75"
                  />
                </div>
              }
            </div>
            <div class="flex flex-col mb-4 mobile:mb-2 max-w-[65%]">
              <h1
                class="text-3xl mobile:text-2xl font-sherika-bold whitespace-nowrap text-ellipsis max-w-52 mobile:text-[1.25rem] overflow-hidden -mb-2"
              >
                {{ general()?.name }}
              </h1>
              <p class="text-sm text-slate-500">
                @if (general()?.username) {
                  &#64;{{ general()?.username }}
                }
              </p>
            </div>
          </div>
        </div>
        <div
          class="flex gap-10 mobile:flex-col small-tablet:flex-col justify-between mobile:pb-16 regular-mobile:!pb-6"
        >
          <div
            class="flex flex-col max-w-md pl-6 h-fit sticky top-3 small-tablet:static mobile:static mobile:px-16 regular-mobile:!px-0 mobile:max-w-full"
          >
            @if (showActionButtons()) {
              <div class="flex gap-3 flex-row mobile:hidden mb-3">
                @if (isUserOwner()) {
                  <app-button
                    [type]="'primary'"
                    [icon]="'create-outline'"
                    (click)="navigateToEditPage()"
                  >
                    <span class="text-sm">Edit page</span>
                  </app-button>
                  <app-button
                    [type]="'dark'"
                    [outlined]="pageCurrentlyHidden()"
                    [icon]="'ban-outline'"
                    (click)="hidePage()"
                    [disabled]="updatingHidePage()"
                  >
                    <span class="text-sm">{{ hideFromPageButtonText() }}</span>
                  </app-button>
                }
                @if (model()?.general?.isUnclaimed && !isUserOwner()) {
                  <app-button
                    [type]="'primary'"
                    [outlined]="true"
                    (click)="handleClaimPage()"
                    [icon]="'pricetag-outline'"
                  >
                    <span class="text-sm">Claim this page</span>
                  </app-button>
                }
              </div>
              <div
                class="hidden mobile:flex flex-col gap-3 regular-mobile:px-6 mb-3"
              >
                <div class="flex items-center justify-start">
                  <ion-buttons>
                    @if (isUserOwner()) {
                      <ion-button
                        size="small"
                        shape="round"
                        (click)="navigateToEditPage()"
                        class="edit-button"
                      >
                        <ion-icon slot="icon-only" icon="create-outline" />
                      </ion-button>
                      <ion-button
                        size="small"
                        shape="round"
                        (click)="hidePage()"
                        [disabled]="updatingHidePage()"
                      >
                        <ion-icon
                          slot="icon-only"
                          [icon]="
                            pageCurrentlyHidden()
                              ? 'eye-off-outline'
                              : 'eye-outline'
                          "
                        />
                      </ion-button>
                    }
                    @if (model()?.general?.isUnclaimed && !isUserOwner()) {
                      <ion-button
                        size="small"
                        shape="round"
                        (click)="handleClaimPage()"
                      >
                        <ion-icon
                          slot="icon-only"
                          [icon]="'pricetag-outline'"
                          size="small"
                        />
                        <span class="mx-1">Claim this page</span>
                      </ion-button>
                    }
                  </ion-buttons>
                </div>
              </div>
              <hr class="mb-3" />
            }
            @if (description(); as description) {
              <div
                class="flex flex-col gap-2 text-normal regular-mobile:px-6 mb-3"
              >
                @for (p of description; track $index) {
                  <p class="text-justify mobile:text-sm">{{ p }}</p>
                }
              </div>
              <hr class="mb-3" />
            }
            <address
              class="flex flex-col not-italic text-normal w-full regular-mobile:px-6 -my-1 mb-3"
            >
              @if (general()?.address?.label; as address) {
                <app-entity-contact
                  [icon]="'compass-outline'"
                  [url]="getGoogleMapsUrl()!"
                  [label]="address"
                  [target]="'_blank'"
                />
              }
              @if (general()?.phoneNumber; as phone) {
                <app-entity-contact
                  [icon]="'call-outline'"
                  [url]="'tel:' + phone"
                  [label]="phone"
                />
              }
              @if (general()?.email; as email) {
                <app-entity-contact
                  [icon]="'send-outline'"
                  [url]="'mailto:' + email"
                  [label]="email"
                />
              }
              @if (general()?.website; as website) {
                <app-entity-contact
                  [icon]="'link-outline'"
                  [url]="resolveValidWebUrl(website)!"
                  [label]="website"
                  [target]="'_blank'"
                />
              }
            </address>
            @if (!isUnclaimed()) {
              <hr />
              <div class="flex flex-col gap-3 w-full grow regular-mobile:px-0">
                <ion-accordion-group>
                  <ion-accordion value="first">
                    <ion-item slot="header">
                      <ion-label
                        class="!flex items-center gap-1 !text-sm regular-mobile:pl-6"
                      >
                        @if (
                          currentOperatingHourStatus() ===
                          CurrentOperatingHourStatus.Open
                        ) {
                          <ion-icon
                            name="ellipse"
                            class="text-green-500 pulse"
                          />
                          Open now
                        } @else if (
                          currentOperatingHourStatus() ===
                          CurrentOperatingHourStatus.OpeningSoon
                        ) {
                          <ion-icon name="ellipse" class="text-yellow-300" />
                          Opening soon
                        } @else if (
                          currentOperatingHourStatus() ===
                          CurrentOperatingHourStatus.ClosingSoon
                        ) {
                          <ion-icon name="ellipse" class="text-yellow-300" />
                          Closing soon
                        } @else {
                          <ion-icon name="ellipse" class="text-gray-500" />
                          Closed
                        }
                      </ion-label>
                    </ion-item>
                    <app-entity-read-hours
                      [hours]="hours()"
                      slot="content"
                      class="regular-mobile:px-6"
                    />
                  </ion-accordion>
                </ion-accordion-group>
              </div>
            }
          </div>
          <div
            class="flex flex-col items-center gap-3 shrink min-w-0 min-h-0 pr-6 mobile:px-16 regular-mobile:!px-6 small-tablet:px-6 max-w-xl mobile:max-w-full w-full grow"
          >
            @for (customField of model()?.customFields; track customField) {
              <app-custom-field-wrapper [model]="customField">
                @if (customField.fieldType === CustomFieldType.RichTextEditor) {
                  <app-rich-text-display
                    class="ql-snow ql-editor max-w-full text-black"
                    [text]="customField.richTextEditor?.value"
                  />
                } @else if (customField.fieldType === CustomFieldType.Images) {
                  <app-media-grid
                    [type]="'image'"
                    [media]="getCustomFieldImageUrls($index)"
                  />
                } @else if (customField.fieldType === CustomFieldType.Videos) {
                  <app-media-grid
                    [type]="'video'"
                    [media]="getCustomFieldVideoUrls($index)"
                    [showProcessingMedia]="shouldShowProcessing()"
                  />
                }
              </app-custom-field-wrapper>
            }
          </div>
        </div>
      </div>
    </div>
  }
</ion-content>
