@if(label()) {
<app-label [value]="label()" [required]="required()" />
}
<div
  class="relative focusable
  {{
    disabled()
      ? 'opacity-50'
      : 'focus:shadow-[0_0_0px_3px_var(--highlight)] hover:border-[var(--muted)] cursor-pointer'
  }}  
      {{ dropdownOpen() ? '-shadow-[0_0_2px_3px_var(--highlight)]' : '' }}"
  (click)="toggleDropdown()"
  [tabIndex]="0"
  (keydown.enter)="toggleDropdown()"
  (keydown.tab)="closeDropdown()"
>
  <ion-icon
    name="chevron-down-outline"
    class="absolute right-3 top-1/2 -translate-y-1/2 transition-[transform] {{
      dropdownOpen() ? 'rotate-180' : 'rotate-0'
    }}"
  ></ion-icon>
  @if(allowClear() && (selectedOption()?.value || selectedOption()?.value ===
  0)) {
  <ion-icon
    name="close-outline"
    class="absolute right-9 top-1/2 -translate-y-1/2 transition-[transform] text-lg p-1 rounded-full {{
      disabled() ? '' : 'hover:bg-gray-100'
    }}"
    (click)="clearSelection($event)"
  ></ion-icon>
  } @if (selectedOption()?.label; as selected) {
  <div class="label">{{ selected }}</div>
  } @else {
  <div class="select-none text-muted label">
    {{ "-- Please Select --" | locale }}
  </div>
  }
  <div
    class="absolute top-[calc(100%+5px)] left-0 overflow-hidden transition-[max-height,padding] ease-in-out duration-300 text-[var(--normal)] w-full rounded-lg z-40 bg-white px-1 {{
      dropdownOpen()
        ? 'max-h-52 overflow-y-auto py-1 shadow-[1px_2px_4px_1px_#00000017,1px_2px_14px_#00000018] border-[1.5px] border-light'
        : 'max-h-0'
    }}"
  >
    @for (item of selectOptions(); track $index; let index = $index) {
    <div
      class="px-4 py-2 focus:bg-[var(--highlight)] outline-none transition-colors rounded-[.35rem] overflow-hidden text-ellipsis whitespace-nowrap {{
        item.dropdownItemStyleClass ?? ''
      }} {{
        selectedOption() === item
          ? 'bg-[var(--highlight-strong)] focus:bg-[var(--highlight-strong)] text-[var(--subtle)]'
          : 'hover:bg-zinc-100'
      }}"
      (click)="valueChanged(item)"
      [attr.data-key]="index"
      [tabIndex]="dropdownOpen() ? 0 : -1"
      #dropdownItem
      (keydown.enter)="valueChanged(item)"
    >
      @if(item.emoji) {
      <span class="mr-0.5">{{ item.emoji }}</span>
      }
      {{ item.label | locale }}
    </div>
    }
  </div>
</div>
<app-validation-output [value]="errorMessage()" />
@if (note()) {
<app-validation-output
  [value]="note()"
  [type]="'info'"
  class="{{ noteStyleClass() }}"
/>
}
