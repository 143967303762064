import { Component, input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss'],
})
export class TextButtonComponent implements OnInit {
  type = input<'primary'>('primary');
  buttonStyleClass = input<string>();
  disabled = input<boolean | undefined>();

  constructor() { }

  ngOnInit() { }

}
