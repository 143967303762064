<div class="flex items-center justify-center w-full grow">
  <app-promotion-content></app-promotion-content>
  <div
    class="basis-1/2 grow shrink-0 py-8 px-4 flex flex-col items-center justify-center gap-10"
  >
    <div class="flex flex-col gap-6 w-full max-w-96">
      <app-logo class="tablet:hidden desktop:hidden"></app-logo>
      <div class="flex flex-col items-start gap-2">
        <h1 class="text-2xl font-semibold">{{ 'Welcome back!' | locale }}</h1>
        <p class="text-gray-500">{{ 'Please log in.' | locale }}</p>
      </div>
    </div>

    <form class="flex flex-col w-full max-w-96" [formGroup]="form">
      <app-input
        [label]="'Email'"
        [autocomplete]="'email'"
        [isRequired]="true"
        [type]="'email'"
        [formControl]="form.controls.email"
        [errorMessage]="form.controls.email.errors?.['error']"
        [autocomplete]="'email'"
        class="mb-4"
      />
      <div class="mb-2">
        <app-input
          [label]="'Password'"
          [autocomplete]="'off'"
          [isRequired]="true"
          [type]="'password'"
          [formControl]="form.controls.password"
          [errorMessage]="form.controls.password.errors?.['error']"
          [autocomplete]="'current-password'"
          (onEnter)="onEnter()"
        />
      </div>
      <app-text-button
        [type]="'primary'"
        class="flex justify-end mb-2 self-end"
        (click)="forgotPassword()"
      >
        <span class="text-sm">Forgot Password</span>
      </app-text-button>
      <app-button
        [buttonStyleClass]="'w-full block'"
        (click)="login($event)"
        [disabled]="loggingIn()"
        class="mb-4"
      >
        Log In
      </app-button>
      <div class="text-sm text-[var(--normal)]">
        <span>{{ "Don't have an account?" | locale }} </span>
        <a [routerLink]="'/auth/signup'" class="text-[var(--accent)]">{{
          'Sign up' | locale
        }}</a>
      </div>
    </form>
  </div>
</div>

<ion-alert
  [isOpen]="!!errorMessage()"
  [message]="errorMessage()"
  [header]="'Error!'"
  [buttons]="[{ text: 'Okay' }]"
/>
