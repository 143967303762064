import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { catchError, combineLatest, filter, map, of, startWith, Subject, switchMap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  apiService = inject(ApiService);
  sessionService = inject(SessionService);
  loggingService = inject(LoggingService);

  reloadUserCount$ = new Subject();
  userCount$ = combineLatest([
    this.sessionService.sessionChanged$,
    this.reloadUserCount$.pipe(startWith(null)),
  ]).pipe(
    filter(([session]) => !!session),
    switchMap(() => this.apiService.get<CountResponse>('/admin/get-user-count')),
    map(data => data.data.count ?? 0),
    catchError(e => {
      this.loggingService.error(e, { message: "There was an error retrieving the total amount of users" });
      return of(null);
    })
  );
  userCount = toSignal(this.userCount$);

  reloadPageCount$ = new Subject();
  pageCount$ = combineLatest([
    this.sessionService.sessionChanged$,
    this.reloadUserCount$.pipe(startWith(null)),
  ]).pipe(
    filter(([session]) => !!session),
    switchMap(() => this.apiService.get<CountResponse>('/admin/get-page-count')),
    map(data => data.data.count),
    catchError(e => {
      this.loggingService.error(e, { message: "There was an error retrieving the total amount of pages" });
      return of(null);
    })
  );
  pageCount = toSignal(this.pageCount$);
}

export interface CountResponse {
  count: number | null;
}