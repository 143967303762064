import { Component, computed, effect, forwardRef, HostListener, inject, input, model, OnDestroy, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileService, UploadArgs } from 'src/app/services/file.service';
import { ModalService } from 'src/app/services/modal.service';
import { ProfilePhotoEditorComponent } from './profile-photo-editor/profile-photo-editor.component';

@Component({
  selector: 'app-profile-photo-picker',
  templateUrl: './profile-photo-picker.component.html',
  styleUrls: ['./profile-photo-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProfilePhotoPickerComponent),
      multi: true
    }
  ]
})
export class ProfilePhotoPickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  fileService = inject(FileService);
  modalService = inject(ModalService);

  value = model<Partial<UploadArgs> | null | undefined>();
  valueChanged$ = toObservable(this.value);
  htmlFor = input.required<string>();
  imageSrc = signal<string | null>(null);
  type = 'image';
  disabled = model<boolean>(false);

  readonly BUCKET_NAME = 'profile-photo' as const;

  accepts = computed(() => {
    const mediaType = this.type;
    const supportedFileExtensions = this.fileService.supportedFileFormats().image.flatMap(t => t.extension);
    let unique = Array.from(new Set(supportedFileExtensions));
    return unique.map(t => `${mediaType}/${t}`).join(',');
  });

  constructor() {
    this.valueChanged$.pipe(takeUntilDestroyed()).subscribe(() => this.createObjectUrl());
  }

  ngOnInit() {
    this.createObjectUrl();
  }

  ngOnDestroy() {
    this.revokeObjectUrl();
  }

  onChange = (value: UploadArgs | null) => { };
  onTouched = () => { };
  writeValue(value: UploadArgs | null | undefined): void {
    this.value.set(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }

  @HostListener('click', ['$event']) async onHostClick(event: Event) {
    const value = await this.modalService.open<File, ProfilePhotoEditorComponent>(ProfilePhotoEditorComponent, {
      canDismiss: true,
      componentProps: {
        accepts: this.accepts(),
        htmlFor: this.htmlFor(),
      }
    });
    const file = value?.data;
    if (!file) return;
    const newValue: UploadArgs = { file, bucketName: this.BUCKET_NAME };
    this.value.set(newValue);
    this.onChange(newValue);
  }

  onFileChange(event: Event) {
    const el = event.target as HTMLInputElement;
    const file = el.files?.[0];
    if (!file) return;
    const newValue: UploadArgs = { file, bucketName: this.BUCKET_NAME };
    this.value.set(newValue);
    this.onChange(newValue);
  }

  createObjectUrl() {
    const file = this.value()?.file;
    if (!file) return;
    const url = URL.createObjectURL(file);
    this.imageSrc.set(url);
  }

  revokeObjectUrl() {
    URL.revokeObjectURL(this.imageSrc() ?? '');
  }

  clear(event: MouseEvent) {
    event.stopPropagation();
    this.value.set(null);
    this.onChange(null);
  }
}
