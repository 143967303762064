import { inject, Injectable, signal } from '@angular/core';
import { registerClassOnWindow } from '../utils/global';
import { wait } from '../utils/async';
import { LocalizationService } from '../modules/localization/services/localization.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private readonly frontendKey = environment.googleMapsKey;

  private autocomplete = signal<google.maps.places.AutocompleteService | undefined>(undefined);

  private localizationService = inject(LocalizationService);

  constructor() {
    registerClassOnWindow('AddressService', this);
    this.init();
  }

  private async init() {
    if (typeof google === 'undefined' || !google?.maps?.places?.AutocompleteService) {
      await wait(50);
      this.init();
      return;
    }
    this.autocomplete.set(new google.maps.places.AutocompleteService());
  }

  async getPlacePredictions(query: string | undefined) {
    if (!query) return [];
    const response = await this.autocomplete()?.getPlacePredictions({
      input: query,
      language: this.localizationService.userLocale()
    });
    return response?.predictions ?? [];
  }

  async getCoordsByPlaceId(placeId: string) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${this.frontendKey}`;
    const response = await fetch(url);
    const json = await response.json() as google.maps.GeocoderResponse;
    const first = json.results[0];
    const lat = first?.geometry.location.lat();
    const long = first?.geometry.location.lng();
    return { lat, long };
  }

  async encodeAddress(address: string) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${this.frontendKey}`;
    const response = await fetch(url);
    const json = await response.json() as google.maps.GeocoderResponse;
    const first = json.results[0];
    return first?.place_id;
  }
}
