import { inject, Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { SessionService } from './session.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  sessionService = inject(SessionService);
  environmentService = inject(EnvironmentService);

  async error<T extends Error | unknown>(error: T, data?: unknown) {
    if (this.environmentService.isDevelopment) return;
    const userId = await this.sessionService.getUserId();
    data = JSON.stringify(data);
    Sentry.captureException(error, { user: { id: userId }, level: 'error', extra: { data } });
  }
}
