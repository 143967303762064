import { Component, inject, input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {

  navController = inject(NavController);

  fallbackHref = input<string>();
  appearance = input<Appearance>('light');

  navigateBack() {
    this.navController.navigateBack('dashboard/pages', { animated: true, animationDirection: 'back' });
  }
}

type Appearance = 'dark' | 'light';