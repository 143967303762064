<div class="media-grid-{{ firstFour().length }} h-full w-full">
  @for (item of firstFour(); track item) {
    <div
      class="basis-1/2 min-w-0 min-h-0 grow shrink relative media-grid-item-{{
        $index + 3
      }} h-full"
    >
      @if (type() === 'image') {
        <ion-img
          [src]="item.src"
          class="object-cover max-w-full max-h-full w-full h-full select-none"
          draggable="false"
        />
      } @else {
        <div class="w-full h-full relative">
          @if (!$last) {
            <ion-icon
              name="play-outline"
              class="absolute-center text-muted text-2xl p-3 text-white bg-black/30 rounded-full shadow-[3px_3px_11px_4px_#00000020]"
            />
          }
          <ion-img
            [src]="item.thumbnail"
            class="object-cover max-w-full max-h-full w-full h-full select-none"
            draggable="false"
          />
        </div>
      }
      <div
        class="absolute inset-0 z-10 transition-colors cursor-pointer flex items-center justify-center {{
          $last && countOfAdditionalMedia()
            ? 'bg-black/20 hover:bg-black/25 active:bg-black/30'
            : 'hover:bg-black/10 active:bg-black/15 '
        }}"
        (click)="gridItemClicked($index)"
      >
        @if ($last && countOfAdditionalMedia()) {
          <div class="text-white text-xl subtle-text-shadow">
            +{{ countOfAdditionalMedia() }}
          </div>
        }
      </div>
    </div>
  }
</div>
