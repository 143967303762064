<div class="aspect-[16/6] bg-gray-50 overflow-hidden">
  @if (avatarUrl(); as src) {
    <img [src]="src" class="w-full h-full object-cover" />
  } @else {
    <div
      class="flex items-center justify-center text-4xl h-full text-slate-500 select-none"
    >
      <ion-img
        src="assets/images/koshery-logo.svg"
        class="pointer-events-none grayscale w-[40%] max-w-sm"
      />
    </div>
  }
</div>
<div class="flex items-start gap-0 flex-col p-3">
  <h3 class="font-semibold leading-6">{{ entity().name }}</h3>
  @if (entity().username; as username) {
    <span class="text-slate-500 text-sm">&commat;{{ username }}</span>
  }
</div>
