<app-logo></app-logo>
<div class="flex flex-col gap-12 max-w-screen-lg w-full mx-auto">
  <div class="flex flex-col gap-1">
    <h1 class="text-3xl font-sherika-bold">
      {{ 'Finalize payment' | locale }}
    </h1>
    <h2 class="text-muted">
      {{ 'Please enter your payment information' | locale }}
    </h2>
  </div>
  <div class="flex flex-col mobile:flex-col justify-between gap-14">
    <div class="flex gap-6 grow mobile:w-full mobile:max-w-md mobile:flex-col">
      <form
        [formGroup]="paymentForm"
        class="flex flex-col gap-4 grow strong-shadow regular-mobile:!shadow-none p-10 regular-mobile:p-0 rounded-lg mobile:order-2"
      >
        <app-input
          [formControl]="paymentForm.controls.nameOnCard"
          [label]="'Name on card'"
          [autocomplete]="'cc-name'"
          [errorMessage]="paymentForm.controls.nameOnCard.errors?.['error']"
          [placeholder]="'Valued Cardholder'"
        />
        <app-input
          [formControl]="paymentForm.controls.ccNumber"
          [label]="'Card number'"
          [autocomplete]="'cc-number'"
          [maxLength]="maxFormattedCardLength()"
          [errorMessage]="paymentForm.controls.ccNumber.errors?.['error']"
          [placeholder]="'0000 0000 0000 0000'"
        />
        <div class="flex gap-3">
          <app-input
            [formControl]="paymentForm.controls.expirationDate"
            [label]="'Expiration date'"
            class="grow basis-1/2"
            [autocomplete]="'cc-exp'"
            [maxLength]="5"
            [errorMessage]="
              paymentForm.controls.expirationDate.errors?.['error']
            "
            [placeholder]="'mm/yy'"
            #expirationDate
          />
          <app-input
            [formControl]="paymentForm.controls.zip"
            [label]="'Billing zip code'"
            class="grow basis-1/2"
            [autocomplete]="'billing postal-code'"
            [errorMessage]="paymentForm.controls.zip.errors?.['error']"
            #zipCode
          />
        </div>
      </form>
      <app-credit-card
        [cardNumber]="formValue()?.ccNumber"
        [expirationDate]="formValue()?.expirationDate"
        [nameOnCard]="formValue()?.nameOnCard"
        class="regular-mobile:!hidden max-w-md mobile:order-1"
      />
    </div>
    <div
      class="grow strong-shadow regular-mobile:!shadow-none h-full mobile:w-full rounded-lg flex flex-col gap-8 p-10 regular-mobile:p-0"
    >
      <h2 class="font-semibold text-xl">Summary</h2>
      <div class="flex flex-col gap-6 grow">
        <div class="flex flex-col gap-2">
          <div class="flex items-center justify-between gap-3">
            <div class="text-muted">Subscription</div>
            <div>{{ selectedSubscription()?.name }} (1 Year)</div>
          </div>
          <div class="flex items-center justify-between gap-3">
            <div class="text-muted">Tax</div>
            <div>{{ 0 | currency }}</div>
          </div>
          @if (discount()) {
            <div class="flex items-center justify-between gap-3">
              <div class="text-muted">Discount</div>
              <div class="text-red-500">-{{ discount() | currency }}</div>
            </div>
          }
          <div class="flex items-center justify-between gap-3">
            <div class="text-muted">Total</div>
            <div class="flex gap-1">
              @if (discount()) {
                <span class="line-through text-muted">{{
                  price() | currency
                }}</span>
                <span class="text-green-600">{{ total() | currency }}</span>
              } @else {
                {{ total() | currency }}
              }
            </div>
          </div>
        </div>
        <div class="mt-auto mb-0 flex flex-col gap-8">
          <form [formGroup]="promoCodeForm" class="flex flex-col gap-1">
            <div class="flex gap-3 items-end">
              <app-input
                [formControl]="promoCodeForm.controls.code"
                [errorMessage]="promoCodeForm.controls.code.errors?.['error']"
                [placeholder]="'Promo code'"
                [autocomplete]="'off'"
                class="grow"
                [autocapitalize]="'none'"
                [spellcheck]="false"
              />
              <app-button
                [outlined]="true"
                [disabled]="
                  applyingPromoCode() || !promoCodeForm.controls.code.value
                "
                (click)="getPromoCode()"
                >Apply</app-button
              >
            </div>
            @if (appliedPromoCode(); as appliedPromo) {
              <div class="flex self-start gap-0.5">
                <ion-icon
                  (click)="removePromoCode()"
                  [name]="'close-outline'"
                  class="cursor-pointer"
                />
                <span class="text-xs text-muted">{{ appliedPromo.code }}</span>
              </div>
            }
          </form>
          <app-button (click)="makePayment()" [disabled]="paying()"
            >Subscribe</app-button
          >
        </div>
      </div>
    </div>
  </div>
</div>
