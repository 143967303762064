import { computed, inject, Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';
import { SessionService } from './session.service';
import { ApiService } from './api.service';
import { CreateSubscriptionArgs, SubscriptionType } from '../models/subscription';
import { ToastService } from './toast.service';
import { LocalStorageKey, LocalStorageService } from './local-storage.service';
import { PromoCode } from './promo.service';
import { registerClassOnWindow } from '../utils/global';
import { map, merge, Subject, switchMap } from 'rxjs';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { EntityEditForm } from '../models/entity/edit/form';
import { FormGroup } from '@angular/forms';
import { CustomFieldType } from '../models/entity/custom-field';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  supabaseService = inject(SupabaseService);
  sessionService = inject(SessionService);
  apiService = inject(ApiService);
  toastService = inject(ToastService);
  localStorage = inject(LocalStorageService);
  loggingService = inject(LoggingService);

  subscriptionPricing: PricingList = {
    [SubscriptionType.Classic]: {
      price: 39.99,
      name: "Classic",
      canUploadAudioFiles: false,
      canUploadPdfFile: true,
      canUploadVideoFiles: false,
      maxCustomFields: 2,
      maxImagesPerCustomField: 5,
      maxVideosPerCustomField: 0,
      maxAudiosPerCustomField: 0,
      maxPages: 1,
    },
    [SubscriptionType.Premium]: {
      price: 199.99,
      name: "Premium",
      canUploadAudioFiles: true,
      canUploadPdfFile: true,
      canUploadVideoFiles: true,
      maxAudiosPerCustomField: 3,
      maxCustomFields: 6,
      maxImagesPerCustomField: 20,
      maxVideosPerCustomField: 3,
      maxPages: 5,
    },
    [SubscriptionType.Platinum]: {
      price: 499.99,
      name: "Platinum",
      canUploadAudioFiles: true,
      canUploadPdfFile: true,
      canUploadVideoFiles: true,
      maxAudiosPerCustomField: 5,
      maxCustomFields: 25,
      maxImagesPerCustomField: 100,
      maxVideosPerCustomField: 5,
      maxPages: 100,
    },
  };

  reload$ = new Subject<void>();
  subscription$ = merge(this.sessionService.sessionChanged$, this.reload$).pipe(
    map(session => session?.user.id ?? this.sessionService.userId()),
    switchMap(async userId => {
      if (!userId) return null;
      const subscription = await this.loadSubscription(userId);
      return subscription;
    })
  );
  subscription = toSignal(this.subscription$);
  hasActiveSubscription = computed(() => this.subscription()?.isActive);

  constructor() {
    registerClassOnWindow('SubscriptionService', this);
  }

  async getSubscription() {
    const existingSubscription = this.subscription();
    if (existingSubscription) return existingSubscription;
    const userId = await this.sessionService.getUserId();
    if (!userId) return;
    const subscription = await this.loadSubscription(userId);
    return subscription;
  }

  async createSubscriptionWithoutPayment(args: CreateSubscriptionArgs) {
    const code = this.localStorage.get<PromoCode>(LocalStorageKey.PromoCode);
    if (code?.code) {
      args.promoCode = code.code;
    }
    const response = await this.apiService.postAsync<unknown, CreateSubscriptionArgs>('subscription/create-without-payment', {
      body: args
    });
    return response;
  }

  private async loadSubscription(userId: string): Promise<Subscription | undefined> {
    const { data, error } = await this.supabaseService.supabase.from('subscription').select('*').eq('user_id', userId).eq('is_active', true).limit(1);
    if (error) {
      this.toastService.error("There was an error loading your subscription");
      this.loggingService.error(error);
      return;
    }
    const first = data?.[0];
    if (!first) return;
    return {
      type: first.type,
      isActive: first.is_active ?? false,
    };
  }

  canAddCustomField(form: FormGroup<EntityEditForm>): CanAddCustomFieldResponse {
    const subscriptionType = this.subscription()?.type;
    if (!subscriptionType) {
      return {
        canAdd: false,
        reason: "You don't have an active subscription",
      };
    }
    const existingCustomFieldCount = form.controls.customFields.controls.length;
    const pricingInfo = this.subscriptionPricing[subscriptionType];
    const maxCustomFields = pricingInfo.maxCustomFields;
    if (maxCustomFields === existingCustomFieldCount) {
      return {
        canAdd: false,
        reason: `You have reached the maximum amount of Custom boxes for your ${pricingInfo.name} subscription`
      };
    }
    return { canAdd: true };
  }

  canSelectCustomFieldType(customFieldType: CustomFieldType): CanAddCustomFieldResponse {
    const subscriptionType = this.subscription()?.type;
    if (!subscriptionType) {
      this.loggingService.error("User doesn't have an active subscription. Error code 1119");
      return {
        canAdd: false,
        reason: "You don't have an active subscription",
      };
    }
    const pricingInfo = this.subscriptionPricing[subscriptionType];
    if (customFieldType === CustomFieldType.RichTextEditor || customFieldType === CustomFieldType.Images) {
      return { canAdd: true, };
    }
    if (customFieldType === CustomFieldType.Videos) {
      if (pricingInfo.canUploadVideoFiles) {
        return { canAdd: true };
      }
      return { canAdd: false, reason: `Your ${pricingInfo.name} subscription is limited to videos` };
    }
    if (customFieldType === CustomFieldType.AudioFile) {
      if (pricingInfo.canUploadAudioFiles) {
        return { canAdd: true };
      }
      return { canAdd: false, reason: `Your ${pricingInfo.name} subscription is limited to Audios` };
    }
    return { canAdd: true };
  }

  async refreshSubscription() {
    this.reload$.next();
  }
}


export type PricingList = Record<SubscriptionType, PricingInfo>;

export interface PricingInfo {
  price: number;
  name: string;
  maxPages: number;
  maxCustomFields: number;
  maxImagesPerCustomField: number;
  maxVideosPerCustomField: number;
  canUploadPdfFile: boolean;
  canUploadVideoFiles: boolean;
  canUploadAudioFiles: boolean;
  maxAudiosPerCustomField: number;
}

export interface Subscription {
  type: SubscriptionType;
  isActive: boolean;
}

export type CanAddCustomFieldResponse = {
  canAdd: true;
} | {
  canAdd: false;
  reason: string;
};