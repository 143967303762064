import { Component, computed, effect, inject, OnDestroy, Signal, signal, viewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '../validators/required';
import { maxLengthValidator } from '../validators/max-length';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, Subscription } from 'rxjs';
import { capitalizeWords } from 'src/app/utils/string';
import { CardType, CreditCardService } from 'src/app/services/credit-card.service';
import { creditCardDateValidator } from '../validators/credit-card-date';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SubscriptionType } from 'src/app/models/subscription';
import { SupabaseService } from 'src/app/services/supabase.service';
import { ToastService } from 'src/app/services/toast.service';
import { ForInsert } from 'src/app/types/database';
import { ApiService } from 'src/app/services/api.service';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { InputComponent } from '../ui/form/input/input.component';
import { cardNumberValidator } from '../validators/card-number';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-take-payment',
  templateUrl: './take-payment.component.html',
  styleUrls: ['./take-payment.component.scss'],
})
export class TakePaymentComponent implements ViewDidEnter, ViewDidLeave, OnDestroy {

  creditCardService = inject(CreditCardService);
  activatedRoute = inject(ActivatedRoute);
  subscriptionService = inject(SubscriptionService);
  supabaseService = inject(SupabaseService);
  toastService = inject(ToastService);
  apiService = inject(ApiService);
  router = inject(Router);
  loggingService = inject(LoggingService);

  subscriptionType = toSignal(this.activatedRoute.queryParamMap.pipe(
    map(p => p.get('subscriptionType')),
    filter(p => !!p),
    map(p => +p!),
    filter(p => p >= 1 && p <= 3),
    takeUntilDestroyed(),
  )) as Signal<SubscriptionType | null>;
  selectedSubscription = computed(() => {
    const subscriptionType = this.subscriptionType();
    if (!subscriptionType) return;
    return this.subscriptionService.subscriptionPricing[subscriptionType];
  });

  paymentForm = new FormGroup<TakePaymentForm>({
    nameOnCard: new FormControl('', [requiredValidator('Name'), maxLengthValidator(35)]),
    ccNumber: new FormControl('', [requiredValidator('Credit card number'), cardNumberValidator()]),
    expirationDate: new FormControl('', [requiredValidator('Expiration date'), creditCardDateValidator()]),
    zip: new FormControl('', [requiredValidator('Zip code'), maxLengthValidator(15)]),
  });

  promoCodeForm = new FormGroup<PromoCodeForm>({
    code: new FormControl(''),
  });

  formValue = toSignal(this.paymentForm.valueChanges);
  cardType = computed(() => this.creditCardService.getCardType(this.formValue()?.ccNumber));
  maxFormattedCardLength = computed(() => this.cardType() === CardType.AmericanExpress ? 17 : 19);
  paying = signal(false);
  applyingPromoCode = signal(false);
  appliedPromoCode = signal<Partial<ForInsert<'promo_code'>> | undefined>(undefined);
  priceFromServer = signal<number | undefined>(undefined);
  price = computed(() => this.priceFromServer() ?? this.selectedSubscription()?.price ?? 0);
  total = computed(() => this.calculateTotal());
  discount = computed(() => this.appliedPromoCode()?.discount_amount);
  expirationDate = viewChild<InputComponent>('expirationDate');
  zipCode = viewChild<InputComponent>('zipCode');

  subscription = new Subscription();

  constructor() {
    this.subscription.add(this.paymentForm.controls.nameOnCard.valueChanges.subscribe(value => this.formatName(value)));
    this.subscription.add(this.paymentForm.controls.ccNumber.valueChanges.subscribe(value => this.handleCardNumber(value)));
    this.subscription.add(this.paymentForm.controls.expirationDate.valueChanges.subscribe(value => this.handleExpirationDate(value)));

    effect(() => {
      const total = this.total();
      if (total === 0) {
        this.paymentForm.disable();
      } else {
        this.paymentForm.enable();
      }
    }, { allowSignalWrites: true });
  }

  ionViewDidEnter() {
    const subscriptionType = this.subscriptionType();
    if (!subscriptionType) {
      this.router.navigateByUrl('/user/pay?subscriptionType=3', { replaceUrl: true });
      return;
    }
  }

  ionViewDidLeave(): void {
    this.subscription.unsubscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async makePayment() {
    const subscriptionType = this.subscriptionType();
    if (!subscriptionType) return;
    if (this.paying()) return;
    try {
      markAllControlsAsTouchedAndDirty(this.paymentForm);
      if (this.paymentForm.invalid) {
        this.toastService.error("Please fill in all required fields");
        return;
      }
      this.paying.set(true);
      const paymentValue = this.paymentForm.value;
      const paymentModel: MakePaymentModel = { ...paymentValue, code: this.appliedPromoCode()?.code, subscriptionType };
      paymentModel.ccNumber = this.creditCardService.parse(paymentModel.ccNumber ?? '');
      await this.apiService.postAsync('/subscription/create', { body: paymentModel });
      this.toastService.success("Payment successful");
      this.subscriptionService.refreshSubscription();
      this.router.navigateByUrl('/dashboard/pages/0/edit?activeIndex=0');
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message ?? "There was an error processing your payment");
    } finally {
      this.paying.set(false);
    }
  }

  formatName(value: string | null) {
    if (!value) return;
    this.paymentForm.controls.nameOnCard.setValue(capitalizeWords(value), { emitEvent: false });
  }

  handleCardNumber(value: string | null) {
    this.formatCardNumber(value);
    const isCardValid = this.creditCardService.isValid(value);
    if (isCardValid) {
      this.expirationDate()?.focus();
    }
  }

  formatCardNumber(value: string | null) {
    if (!value) return;
    const formatted = this.creditCardService.format(value);
    this.paymentForm.controls.ccNumber.setValue(formatted, { emitEvent: false });
  }

  handleExpirationDate(value: string | null) {
    this.formatExpirationDate(value);
    if (value?.length === 5) {
      this.zipCode()?.focus();
    }
  }

  formatExpirationDate(value: string | null) {
    if (!value) return;
    if (value.length === 1 && parseInt(value) > 1) {
      value = '0' + value + '/';
    }
    else if (value.length === 2) {
      value += '/';
    }
    else if (value.length > 5) {
      value = value.slice(0, 5);
    }
    this.paymentForm.controls.expirationDate.setValue(value, { emitEvent: false });
  }

  calculateTotal(): number {
    const price = this.price();
    const discount = this.discount() ?? 0;
    return price - discount;
  }

  async getPromoCode() {
    const promoCode = this.promoCodeForm.value.code;
    if (!promoCode) return;
    this.applyingPromoCode.set(true);
    const { data: code, error } = await this.supabaseService.supabase.from('promo_code').select('*').eq('code', promoCode);
    this.applyingPromoCode.set(false);
    if (error) {
      this.toastService.error("There was an error applying your promo code");
      this.loggingService.error(error);
      return;
    }
    const serverPromoCode = code?.[0];
    if (!serverPromoCode || !serverPromoCode.discount_amount) {
      this.toastService.error("Promo code does not exist or is expired");
      return;
    };
    this.appliedPromoCode.set(serverPromoCode);
    this.promoCodeForm.reset();
  }

  removePromoCode() {
    this.appliedPromoCode.set(undefined);
  }
}

export interface TakePaymentForm {
  nameOnCard: FormControl<string | null>;
  ccNumber: FormControl<string | null>;
  expirationDate: FormControl<string | null>;
  zip: FormControl<string | null>;
}

export interface PromoCodeForm {
  code: FormControl<string | null>;
}

type TakePaymentFormValue = Partial<FormGroup<TakePaymentForm>['value']>;
type PromoCodeFormValue = Partial<FormGroup<PromoCodeForm>['value']>;

export interface MakePaymentModel extends TakePaymentFormValue, PromoCodeFormValue {
  subscriptionType: SubscriptionType;
}