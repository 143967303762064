import { Component, input } from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent {
  value = input.required<string>();
  type = input<NoteType>('primary');
  size = input<NoteSize>('normal');
  styleClass = input<string>('');
}

export type NoteType = 'primary' | 'muted';
export type NoteSize = 'normal' | 'small' | 'tiny';