<div class="flex items-center justify-center w-full grow">
  <app-promotion-content />
  <div
    class="basis-1/2 grow shrink-0 py-8 px-4 flex flex-col items-center justify-center gap-10"
  >
    <div class="flex flex-col gap-6 w-full max-w-96">
      <app-logo class="tablet:hidden desktop:hidden"></app-logo>
      <div class="flex flex-col items-start gap-2">
        <h1 class="text-2xl font-semibold">
          {{ 'Confirm your email' | locale }}
        </h1>
        <p class="text-gray-500">
          {{
            'Please enter the code below that we sent to your email' | locale
          }}
        </p>
      </div>
      <form class="flex flex-col gap-3">
        <app-input
          [formControl]="form.controls.otp"
          [label]="'Code'"
          [errorMessage]="form.controls.otp.errors?.['error']"
          [inputMode]="'numeric'"
        />
        <app-button (click)="confirmEmail($event)" [disabled]="confirming()"
          >Confirm</app-button
        >
        <app-text-button (click)="resendCode($event)" [disabled]="resending()"
          >Resend code</app-text-button
        >
      </form>
    </div>
  </div>
</div>
