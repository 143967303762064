import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { allComponents } from './setup/all-components';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LocalizationModule } from './modules/localization/localization.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { ImageCropperComponent } from 'ngx-image-cropper';
import * as Sentry from "@sentry/angular";
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';

ModuleRegistry.registerModules([AllCommunityModule]);

@NgModule({
  declarations: [
    ...allComponents,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      useSetInputAPI: true,
      animated: true,
    }),
    AppRoutingModule,
    LocalizationModule,
    ReactiveFormsModule,
    CommonModule,
    QuillModule.forRoot(),
    ImageCropperComponent,
    BrowserAnimationsModule,
    AgGridAngular,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }