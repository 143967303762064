<div class="flex min-h-full">
  <form class="grow" [formGroup]="form">
    <!-- We need to hide the form groups, and not entirely remove them.
       Otherwise, angular will only validate the page currently displayed! -->
    <app-entity-edit-general
      [form]="form"
      (next)="next()"
      class="{{ activeIndex() === '0' ? '' : '!hidden' }}"
    />
    <app-entity-edit-hours
      [form]="form"
      (next)="next()"
      (back)="back()"
      class="{{ activeIndex() === '1' ? '' : '!hidden' }}"
      [(isAlwaysOpen)]="isAlwaysOpen"
    />
    <app-entity-edit-search
      [formGroup]="form"
      class="{{ activeIndex() === '2' ? '' : '!hidden' }}"
      (next)="next()"
      (back)="back()"
    />
    <app-entity-edit-custom-fields
      [formGroup]="form"
      (saveForm)="save()"
      class="{{ activeIndex() === '3' ? '' : '!hidden' }}"
      [saving]="saving()"
      (back)="back()"
    />
  </form>
</div>
