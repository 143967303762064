import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FileAccess } from './file.service';
import { getLastSegment } from '../utils/url';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  private apiService = inject(ApiService);

  private async getSignedUrls(count: number): Promise<string[]> {
    const response = await this.apiService.getAsync<string[]>(`file/get-signed-urls?count=${count}`);
    return response.data;
  }

  async upload(videos: File[]): Promise<VideoAccess[]> {
    const signedUrls = await this.getSignedUrls(videos.length);
    const videoUids = signedUrls.map(u => getLastSegment(u));
    const responsePromises: Promise<Response>[] = []; // we want to upload the videos in parallel
    for (const video of videos) {
      const formData = new FormData();
      formData.append('file', video);
      const response = fetch(signedUrls.pop()!, {
        method: 'POST',
        body: formData,
      });
      responsePromises.push(response);
    }
    await Promise.all(responsePromises);
    const uploadedMetadata = videoUids.map<VideoAccess>(r => ({
      uid: r!,
    }));
    return uploadedMetadata;
  }

  generateRandomThumbnail(videoFile: File, seekTime = 1) {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(videoFile);
      video.crossOrigin = "anonymous";
      video.muted = true; // Required for some browsers to allow auto-play
      video.play();
      video.addEventListener("loadeddata", () => {
        video.currentTime = seekTime; // Seek to the timestamp
      });
      video.addEventListener("seeked", () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (!blob) reject("Blob was null");
          else {
            const url = URL.createObjectURL(blob);
            resolve(url);
          }
          URL.revokeObjectURL(video.src); // Clean up memory
        }, "image/jpeg");
      });
      video.onerror = (error) => reject(error);
    });
  }
}

export interface CloudflareStreamUploadResponse {
  uid: string; // Unique ID of the uploaded video
  thumbnail: string; // Thumbnail URL
  readyToStream: boolean; // Indicates if the video is ready for streaming
  status: {
    state: "queued" | "inprogress" | "ready" | "error"; // Processing state
    pctComplete: number; // Percentage of completion
    errorReasonCode?: string; // Error code (if any)
    errorReasonText?: string; // Error message (if any)
  };
  meta: {
    name?: string; // Name of the uploaded file
  };
  created: string; // Timestamp when the video was uploaded
  modified: string; // Timestamp when the video was last modified
  size: number; // Size of the uploaded video in bytes
  duration: number; // Duration of the video in seconds
  input: {
    width: number;
    height: number;
  };
  playback: {
    hls: string; // HLS URL for streaming
    dash?: string; // DASH URL (if enabled)
  };
};

export interface VideoAccess {
  uid: string;
}