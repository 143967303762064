@for (hour of hours(); track hour) {
  <div class="flex justify-between">
    <div class="font-sherika-regular">
      {{ dateService.daysOfTheWeek()[$index] }}
    </div>
    <div class="flex gap-1.5 text-normal mobile:text-sm">
      @if (
        (hour.openingTime || hour.openingTime === 0) &&
        (hour.closingTime || hour.closingTime === 0)
      ) {
        @if (hour.openingTime === hour.closingTime) {
          <div>Open 24 hours</div>
        } @else {
          <div>
            {{
              dateService.localeTimeFormatLookup().get(hour.openingTime)?.label
            }}
          </div>
          <div>-</div>
          <div>
            {{
              dateService.localeTimeFormatLookup().get(hour.closingTime)?.label
            }}
          </div>
        }
      } @else {
        <div>Closed</div>
      }
    </div>
  </div>
}
