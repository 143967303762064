<div class="mobile:hidden mobile:px-6 px-12 pt-6 overflow-y-scroll scrollbar">
  <div class="max-w-screen-lg">
    <div class="flex items-center justify-between">
      <div class="">
        <h1 class="text-2xl font-sherika-bold">
          {{ title() | locale }}
        </h1>
        <p class="text-sm text-normal">{{ description() | locale }}</p>
      </div>
      <ng-content select=".action" />
    </div>
    <ion-progress-bar [value]="progress()" />
  </div>
</div>
<ion-header class="hidden mobile:block">
  <ion-toolbar>
    <ion-title>{{ title() | locale }}</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="onBack()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-progress-bar [value]="progress()" />
  </ion-toolbar>
</ion-header>
