<div>
  <app-navbar [menuItems]="menuItems()" [popoverButtons]="popoverButtons()" />
</div>
<div class="flex flex-col items-center justify-center gap-6 p-4">
  <h1 class="text-5xl text-accent font-sherika-bold">
    <img
      src="/assets/images/koshery-logo.svg"
      alt="Koshery logo"
      class="w-72 max-w-[54vw] mx-auto"
    />
  </h1>
  <div class="relative rounded-3xl overflow-hidden max-w-full">
    <input
      type="text"
      class="self-center w-[30rem] max-w-full rounded-3xl pl-5 pr-14 py-3 text-lg accent-accent outline-transparent focus:outline-accent outline-4 outline-offset-1 transition-[outline,shadow] border border-muted border-solid shadow focus:shadow-lg placeholder:text-lg"
      [size]="1"
      [placeholder]="placeholder()"
      [value]="searchValue()"
      (input)="searchValueChanged($event)"
    />
    <ion-button
      class="absolute right-0.5 top-1/2 -translate-y-1/2"
      shape="round"
      size="large"
    >
      <ion-icon
        name="search-outline"
        slot="icon-only"
        class="rounded-full"
      ></ion-icon>
    </ion-button>
  </div>
</div>
<footer>
  <div class="mobile:hidden p-5 flex gap-5 justify-between text-muted">
    <div>
      <small>Koshery.com &copy; All rights reserved</small>
    </div>
    <div class="flex items-center gap-5">
      <small>
        <a href="/platform/terms-and-conditions">Terms & conditions</a>
      </small>
      <small>
        <a href="/platform/privacy-policy">Privacy policy</a>
      </small>
    </div>
  </div>
</footer>
