import { Injectable } from '@angular/core';
import { createClient, RealtimeChannelOptions } from '@supabase/supabase-js';
import { Observable } from 'rxjs';
// import { Database } from 'database.types';
import { environment } from 'src/environments/environment';
import { Database } from 'supabase/functions/database.types';

@Injectable({
  providedIn: 'root'
})
export class SupabaseService {

  get supabaseUrl() { return environment.supabaseUrl; }

  get anonKey() { return environment.anonKey; }
  supabase = this.init();
  get avatarBucket() { return this.supabase.storage.from('avatar'); }

  private init() {
    return createClient<Database>(this.supabaseUrl, this.anonKey);
  }

  /**
   * Note: Realtime needs to be enabled on the table on order for this function to work.
   * @param table the name of the table
   * @param schema the name of the schema (optional)
   * @returns an observable that emits when the table gets updated
   */
  tableChanges<TName extends TableName>(table: TName, schema = 'public'): Observable<Table<TName>> {
    return new Observable<Table<TName>>((subscriber) => {
      const realtimeChannel = this.supabase.channel(SupabaseChannel.TableChanges).on(
        'postgres_changes',
        { event: '*', schema, table: table },
        (payload) => {
          console.log('table changes', payload);
          if (payload.errors?.length) {
            subscriber.error(payload.errors);
          } else {
            subscriber.next(payload.new as Table<TName>);
          }
        }
      ).subscribe();
      subscriber.add(() => realtimeChannel.unsubscribe());
    });
  }
}

export enum SupabaseChannel {
  UsersChanges = "UsersChanges",
  TableChanges = "TableChanges"
}

type Table<TName extends keyof Database['public']['Tables']> = Database['public']['Tables'][TName]['Row'];
type TableName = keyof Database['public']['Tables'];