import { Environment } from "src/app/models/environment";

export const environment: Environment = {
  production: true,
  supabaseUrl: 'https://eqnalgygsaemozcinpyb.supabase.co',
  anonKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVxbmFsZ3lnc2FlbW96Y2lucHliIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzg4MDcxODksImV4cCI6MjA1NDM4MzE4OX0.2PrhzVR4oyN6ji0cM5iE1g3wlTjUZnp-l2eNHVp_528',
  googleMapsKey: 'AIzaSyAEgAr5wyjdpmvc7OWKtZyENutThRKoCrE',
  baseUrl: "https://koshery.com",
  cloudflareProjectId: 'e91c7b391e4f01fb8ec34d0b1825252d',
  cloudflareStreamSubdomain: 'customer-jmrjnmummdgao4ev.cloudflarestream.com',
};
