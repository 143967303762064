import { computed, inject, Injectable, signal, Signal, Type } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalController = inject(ModalController);

  renderedComponent = signal<Type<unknown> | null>(null);
  renderedComponentProps = signal<Record<string, unknown> | undefined>(undefined);
  backdropVisible = computed(() => !!this.renderedComponent());
  backdropCloseResolver?: (value: PromiseLike<undefined> | undefined) => void;

  async open<R, C>(component: Type<C>, options?: ModalOptions<C>) {
    if (options?.backdropOnly) {
      return this.openBackdropOnly<C>(component, options);
    }
    const modal = await this.modalController.create({
      component,
      canDismiss: options?.canDismiss,
      componentProps: options?.componentProps as Partial<C>, // Safely cast here
    });
    await modal.present();
    return modal.onWillDismiss<R>();
  }

  private async openBackdropOnly<C>(component: Type<C>, options?: ModalOptions<C>) {
    return new Promise<undefined>((resolve) => {
      this.renderedComponent.set(component);
      this.renderedComponentProps.set(options?.componentProps);
      this.backdropCloseResolver = resolve;
    });
  }

  close() {
    this.renderedComponent.set(null);
    this.backdropCloseResolver?.(undefined);
  }
}

export interface ModalOptions<C> {
  canDismiss?: boolean | ((data?: any, role?: string) => Promise<boolean>);
  componentProps?: Partial<MappedComponent<C>>;
  backdropOnly?: boolean;
}

// Since ionic creates signals for us, we need to extract the signal parameter type here.
type MappedComponent<C> = {
  [K in keyof C]: C[K] extends Signal<infer T> ? T : C[K] extends (...args: any) => any ? Parameters<C[K]> : C[K];
};