import { Component, computed, inject, input, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';
import { FileService } from 'src/app/services/file.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent {
  domSanitizer = inject(DomSanitizer);
  modalController = inject(ModalController);
  toastService = inject(ToastService);
  fileService = inject(FileService);

  imageSrc = input.required<string>();
  aspectRatio = input<number | undefined>();
  fileName = input<string | undefined>(undefined);

  maintainAspectRatio = computed(() => !!this.aspectRatio());
  cropperReady = signal(false);
  croppedImageBlob = signal<Blob | null | undefined>(undefined);

  async imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBlob.set(event.blob);
  }

  close() {
    this.modalController.dismiss();
  }

  confirmCrop() {
    const blob = this.croppedImageBlob();
    if (!blob) {
      // this.close();
      return;
    }
    const file = this.fileService.blobToFile(blob, this.fileName() ?? 'cropped');
    this.modalController.dismiss(file);
  }

  onCropperReady(event: Dimensions) {
    this.cropperReady.set(true);
  }

  handleLoadImageFail() {
    this.toastService.error("There was an error loading this image");
    this.close();
  }
}
