import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-card-chip',
  templateUrl: './credit-card-chip.component.html',
  styleUrls: ['./credit-card-chip.component.scss'],
})
export class CreditCardChipComponent {

}
