<app-entity-edit-header
  [title]="'General Information'"
  [description]="'Enter the basics of your business'"
  [backUrl]="'/dashboard/pages'"
/>
<app-entity-edit-wrapper class="mobile:!pt-0">
  @if (entityEditComponent.modelLoading()) {
    <app-loader class="my-auto" />
  } @else {
    <div
      [formGroup]="generalForm()"
      class="flex flex-col gap-5 max-w-screen-lg"
    >
      <div>
        <app-avatar
          [mode]="'single'"
          [mimeType]="'image'"
          [subMimeTypes]="['jpeg', 'png', 'gif', 'webp', 'svg', 'bmp', 'avif']"
          [bucketName]="'avatar'"
          [formControl]="generalForm().controls.avatar"
          [label]="'Click to upload your cover photo'"
          class="mobile:-ml-6 mobile:-mr-6 mobile:w-screen mobile:!min-w-[100vw]"
        />
        <app-profile-photo-picker
          [formControl]="generalForm().controls.profilePhoto"
          htmlFor="profile-photo"
          class="-mt-8 mobile:-mt-6 ml-6 mobile:ml-0"
        />
      </div>
      <div class="flex gap-6 mobile:flex-col small-tablet:flex-col">
        <app-input
          [label]="'Page name'"
          [formControl]="generalForm().controls.name"
          [errorMessage]="generalForm().controls.name.errors?.['error']"
          [isRequired]="true"
          class="basis-1/2"
        />
        <app-input
          [label]="'Page username'"
          [formControl]="generalForm().controls.username"
          [errorMessage]="generalForm().controls.username.errors?.['error']"
          [isRequired]="true"
          class="basis-1/2"
          [successMessage]="usernameAvailableMessage()"
          [note]="usernameNote()"
          [placeholder]="'koshery.com/your-unique-username'"
        />
      </div>
      <div class="mobile:flex-col small-tablet:flex-col">
        <app-textarea
          [label]="'Description'"
          [formControl]="generalForm().controls.description"
          class="basis-full"
          [rows]="6"
          [placeholder]="'Describe your business in great detail'"
          [errorMessage]="generalForm().controls.description.errors?.['error']"
        />
      </div>
      <div class="flex gap-6 mobile:flex-col small-tablet:flex-col">
        <app-input
          [label]="'Phone number'"
          [formControl]="generalForm().controls.phoneNumber"
          [errorMessage]="generalForm().controls.phoneNumber.errors?.['error']"
          [isRequired]="true"
          [type]="'tel'"
          class="basis-1/2"
        />
        <app-input
          [label]="'Email'"
          [formControl]="generalForm().controls.email"
          [errorMessage]="generalForm().controls.email.errors?.['error']"
          [type]="'email'"
          class="basis-1/2"
        />
      </div>
      <div class="flex gap-6 mobile:flex-col small-tablet:flex-col">
        <app-autocomplete
          [label]="'Address'"
          [formControl]="generalForm().controls.address"
          [loaderFunction]="loadAddresses"
          class="basis-1/2"
        />
        <app-input
          [label]="'Website'"
          [formControl]="generalForm().controls.website"
          [errorMessage]="generalForm().controls.website.errors?.['error']"
          [isRequired]="false"
          class="basis-1/2"
          [type]="'url'"
        />
      </div>
      <div class="flex items-end mobile:items-stretch gap-6 flex-col">
        <app-button (click)="onNext($event)">Next</app-button>
      </div>
    </div>
  }
</app-entity-edit-wrapper>
