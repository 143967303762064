import { ValidatorFn } from "@angular/forms";
import { emailRegex } from "src/app/utils/string";

export function emailValidator(required?: boolean): ValidatorFn {
  return function (control) {
    if (!control.touched || !control.dirty) return null;
    const email = control.value as string | null | undefined;
    if (!email && required) {
      return {
        error: "Email is required"
      };
    } else if (!email) {
      return null;
    }
    if (!emailRegex.test(email)) {
      return {
        error: "Please enter a valid email"
      };
    }
    return null;
  };
}