@if (label()) {
  <label [htmlFor]="htmlFor()">{{ label() }}</label>
}
@if (description()) {
  <small class="text-gray-500 mb-1.5">{{ description() }}</small>
}
<div
  class="flex flex-wrap focusable self-stretch !p-3 !min-h-20 items-start"
  (click)="focusInput()"
>
  <div class="flex flex-wrap gap-2 me-3 grow">
    @for (tag of value(); track tag.label) {
      <div
        class="px-2 rounded-xl border border-solid flex gap-1 items-center shadow-sm"
      >
        <span class="text-gray-700">{{ tag.label }}</span>
        <ion-icon
          name="close-circle-outline"
          class="text-lg cursor-pointer text-gray-500"
          (click)="removeTag(tag)"
          [tabIndex]="0"
        />
      </div>
    }
    <input
      type="text"
      class="outline-none border-none p-0 grow"
      #input
      (keydown.enter)="onEnter($event, input)"
      (keydown.backspace)="removeLastTag(input.value)"
      [enterKeyHint]="'Enter'"
      [maxLength]="maximumTagLength()"
      [size]="1"
      [spellcheck]="false"
      [autocapitalize]="'none'"
      [autocomplete]="'off'"
    />
  </div>
</div>
<small class="self-end text-gray-500"
  >{{ value()?.length }} / {{ maximumTags() }}</small
>
