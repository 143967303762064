import { AfterViewInit, Component, computed, ElementRef, inject, input, model, OnInit, signal, viewChild, viewChildren } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MediaType } from 'src/app/models/file';
import Swiper from 'swiper';
import { SwiperContainer } from 'swiper/element';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { DisplayMedia } from 'src/app/models/image';

@Component({
  selector: 'app-media-slideshow',
  templateUrl: './media-slideshow.component.html',
  styleUrls: ['./media-slideshow.component.scss'],
})
export class MediaSlideshowComponent implements OnInit, AfterViewInit {
  hideToolbar = input(false);
  modalController = inject(ModalController);
  mediaIndex = model(0);
  media = input<DisplayMedia[]>([]);
  type = input.required<MediaType>();

  videoPlayers = viewChildren<VideoPlayerComponent>('videoPlayer');
  muted = signal(false);

  activeMedia = computed(() => this.media()[this.mediaIndex()]);
  hasNextMedia = computed(() => {
    let index = this.mediaIndex();
    index++;
    return !!this.media()[index];
  });
  hasPreviousMedia = computed(() => {
    let index = this.mediaIndex();
    index--;
    return !!this.media()[index];
  });

  imageElement = viewChild<ElementRef<HTMLImageElement>>('image');
  swiperContainer = viewChild<ElementRef<SwiperContainer>>('swiper');

  ngOnInit() {
    // The initialSlide property doesn't seem to work.
    this.swiperContainer()?.nativeElement.swiper.slideTo(this.mediaIndex());
  }

  ngAfterViewInit() {
    this.swiperContainer()?.nativeElement.swiper.on('slideChange', (swiper) => this.slideChange(swiper));
    this.updateVideoPlayback();
  }

  close() {
    this.modalController.dismiss();
  }

  slideChange(swiper: Swiper) {
    this.mediaIndex.set(swiper.activeIndex);
  }

  next() {
    const hasNextMedia = this.hasNextMedia();
    if (!hasNextMedia) return; // there are no further images
    this.swiperContainer()?.nativeElement.swiper.slideTo(this.mediaIndex() + 1);
  }

  previous() {
    const hasPreviousMedia = this.hasPreviousMedia();
    if (!hasPreviousMedia) return;
    this.swiperContainer()?.nativeElement.swiper.slideTo(this.mediaIndex() - 1);
  }

  handleSlideChange(event: Event) {
    this.updateVideoPlayback();
  }

  updateVideoPlayback() {
    if (this.type() !== 'video') return;
    const activeIndex = this.swiperContainer()?.nativeElement.swiper.activeIndex;
    const videoPlayers = this.videoPlayers();
    videoPlayers.forEach((player, index) => {
      if (index === activeIndex) {
        player.play();
      } else {
        player.pause();
      }
    });
  }
}