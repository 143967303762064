import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  getString(key: LocalStorageKey): string | null {
    return localStorage.getItem(key);
  }

  setString(key: LocalStorageKey, value: string) {
    localStorage.setItem(key, value);
  }

  set<T = any>(key: LocalStorageKey, value: T) {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  remove(key: LocalStorageKey) {
    localStorage.removeItem(key);
  }

  get<T>(key: LocalStorageKey): T | null {
    const rawValue = localStorage.getItem(key);
    if (!rawValue) return null;
    try {
      const parsedValue = JSON.parse(rawValue);
      return parsedValue;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

}

export enum LocalStorageKey {
  Locale = 'Locale',
  AppUser = 'AppUser',
  PromoCode = 'PromoCode',
}

export interface LocalStorageChangedInfo {
  key: LocalStorageKey;
  value: unknown;
}