import { Component, computed, inject, input, output } from '@angular/core';
import { OnUpgradeArgs, SubscriptionType } from 'src/app/models/subscription';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-upgrade-card',
  templateUrl: './upgrade-card.component.html',
  styleUrls: ['./upgrade-card.component.scss'],
})
export class UpgradeCardComponent {
  subscriptionService = inject(SubscriptionService);

  type = input.required<SubscriptionType>();
  icon = input.required<string>();
  price = input.required<number>();
  pros = input.required<string[]>();
  cons = input.required<string[]>();
  upgradingInProgress = input.required<boolean>();
  onUpgrade = output<OnUpgradeArgs>();
  name = computed(() => this.subscriptionService.subscriptionPricing[this.type()].name);
  upgradeStatus = computed<UpgradeArgs>(() => {
    const subscription = this.subscriptionService.subscription();
    const existingSubscriptionType = subscription?.type;
    if (existingSubscriptionType === this.type()) return { displayText: "Current subscription", direction: 'current' };
    if (!existingSubscriptionType) return { displayText: "Continue to payment", direction: 'upgrade' };
    if (existingSubscriptionType < this.type()) return { displayText: "Upgrade", direction: 'upgrade' };
    if (existingSubscriptionType > this.type()) return { displayText: "Downgrade", direction: 'downgrade' };
    return { displayText: "Current subscription", direction: 'current' };
  });

  onUpgradeClick() {
    if (this.upgradeStatus().direction === 'current') return;
    this.onUpgrade.emit({
      subscriptionType: this.type(),
      amount: this.subscriptionService.subscriptionPricing[this.type()].price,
    });
  }

}

export interface UpgradeArgs {
  displayText: string;
  direction: 'upgrade' | 'downgrade' | 'current';
}
