<div class="flex items-center justify-center w-full min-h-full">
  <app-promotion-content></app-promotion-content>
  <div
    class="basis-1/2 grow shrink-0 py-8 px-4 flex flex-col items-center justify-center gap-10"
  >
    <div class="flex flex-col gap-6 w-full max-w-96">
      <app-logo class="tablet:hidden desktop:hidden"></app-logo>
      <div class="flex flex-col items-start gap-2">
        <h1 class="text-2xl font-semibold">
          {{ 'Forgot password?' | locale }}
        </h1>
        <p class="text-gray-500">
          {{ "No worries, we'll email you a verification code" | locale }}
        </p>
      </div>
    </div>

    <form class="flex flex-col gap-4 w-full max-w-96" [formGroup]="form">
      <app-input
        [label]="'Email'"
        [autocomplete]="'email'"
        [isRequired]="true"
        [type]="'email'"
        [formControl]="form.controls.email"
        [errorMessage]="form.controls.email.errors?.['error']"
      />
      <app-button
        [buttonStyleClass]="'w-full'"
        (click)="sendCode()"
        [disabled]="sending()"
      >
        {{ 'Send code' | locale }}
      </app-button>
    </form>
  </div>
</div>

<ion-alert
  [isOpen]="!!errorMessage()"
  [message]="errorMessage()"
  [header]="'Error!'"
  [buttons]="[{ text: 'Okay' }]"
/>

<ion-toast
  [trigger]="'open-toast'"
  [duration]="4000"
  [message]="emailSentMessage()"
  [isOpen]="!!emailSentMessage()"
  [buttons]="toastButtons"
  class="success"
/>
