import { Component, computed, effect, HostBinding, inject, input, OnInit } from '@angular/core';
import { CardType, CreditCardService } from 'src/app/services/credit-card.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
  host: {
    '[class]': 'cardType()'
  }
})
export class CreditCardComponent {

  paymentService = inject(PaymentService);
  creditCardService = inject(CreditCardService);

  nameOnCard = input.required<string | null | undefined>();
  cardNumber = input.required<string | null | undefined>();
  expirationDate = input.required<string | Date | null | undefined>();
  obfuscate = input<boolean | undefined>(true);

  cardNumberDisplay = computed(() => {
    const cardNumber = this.cardNumber();
    if (!cardNumber) return '';
    if (!this.obfuscate()) return this.creditCardService.format(cardNumber);
    return this.getObfuscatedNumber();
  });

  cardTypeText = computed(() => {
    const cardType = this.cardType();
    if (cardType === CardType.Visa) return 'Visa';
    if (cardType === CardType.Mastercard) return 'Mastercard';
    if (cardType === CardType.Discover) return 'Discover';
    if (cardType === CardType.AmericanExpress) return 'American Express';
    return '';
  });
  cardType = computed(() => this.creditCardService.getCardType(this.cardNumber()));

  getObfuscatedNumber() {
    const cardNumber = this.cardNumber();
    if (!cardNumber) return;
    const cardType = this.cardType();
    if (!cardType) return;
    const parsedNumber = this.creditCardService.parse(cardNumber);
    const numLength = cardType === CardType.AmericanExpress ? 15 : 16;
    const toObfuscate = parsedNumber.slice(0, numLength - 4);
    const lastFour = parsedNumber.slice(numLength - 4, numLength);
    const obfuscatedNumber = `${toObfuscate.replace(/\d/g, '*')}${lastFour ?? ''}`;
    const formatted = this.creditCardService.format(obfuscatedNumber, cardType);
    return formatted;
  }
}
