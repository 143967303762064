import { computed, inject, Injectable } from '@angular/core';
import * as tus from 'tus-js-client';
import { SessionService } from './session.service';
import { registerClassOnWindow } from '../utils/global';
import { environment } from 'src/environments/environment';
import { replaceSpaces } from '../utils/string';
import imageCompression from 'browser-image-compression';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  sessionService = inject(SessionService);
  environmentService = inject(EnvironmentService);
  accessToken = computed(() => this.sessionService.sessionToken());

  supportedFileFormats = computed<SupportedFileFormats>(() => ({
    image: [
      { mimeSubType: 'apng', extension: ['apng', 'png'] },
      { mimeSubType: 'avif', extension: ['avif'] },
      { mimeSubType: 'gif', extension: ['gif'] },
      { mimeSubType: 'jpeg', extension: ['jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp'] },
      { mimeSubType: 'png', extension: ['png'] },
      { mimeSubType: 'svg+xml', extension: ['svg'] },
      { mimeSubType: 'webp', extension: ['webp'] },
    ],
    video: [
      { mimeSubType: "mp4", extension: ["mp4"] },
      { mimeSubType: "webm", extension: ["webm"] },
      { mimeSubType: "ogg", extension: ["ogv", "ogg"] },
      { mimeSubType: "x-matroska", extension: ["mkv"] },
      { mimeSubType: "quicktime", extension: ["mov"] },
    ],
  }));

  constructor() {
    registerClassOnWindow('FileService', this);
  }

  generateObjectName(args: UploadArgs) {
    args.objectName = replaceSpaces(args.objectName ?? args.file.name);
    return `${Date.now()}-${args.objectName}`;
  }

  resumableUpload(args: UploadArgs, onProgress?: (progress: TusProgress) => void) {
    return new Promise<FileAccess>(async (resolve, reject) => {
      const accessToken = this.accessToken();
      if (!accessToken) reject("User not logged in!");

      const objectName = this.generateObjectName(args);
      const objectType = args.contentType ?? args.file.type;
      const upload = new tus.Upload(args.file, {
        endpoint: `${environment.supabaseUrl}/storage/v1/upload/resumable`,
        headers: {
          authorization: `Bearer ${accessToken}`,
          'x-upsert': 'true',
        },
        uploadDataDuringCreation: true,
        removeFingerprintOnSuccess: true,
        metadata: {
          bucketName: args.bucketName,
          objectName,
          contentType: objectType,
          cacheControl: '3600',
        },
        chunkSize: 6 * 1024 * 1024, // SUPABASE NOTE: it must be set to 6MB (for now) do not change it
        onError(error) {
          reject(error.message);
        },
        onProgress(bytesSent, bytesTotal) {
          const progress = (bytesSent * bytesTotal) / 100;
          onProgress?.({
            bytesSent,
            bytesTotal,
            progress,
          });
        },
        onSuccess() {
          resolve({
            objectName,
            bucketName: args.bucketName,
            objectType,
          });
        },
      });
      const prev = await upload.findPreviousUploads();
      if (prev.length) {
        upload.resumeFromPreviousUpload(prev[0]!);
      }
      upload.start();
    });
  }

  fileToUrl<T extends File | undefined | null = File | undefined | null>(file: T): Promise<NullIfEmpty<T>> {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve(null as NullIfEmpty<T>);
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result as NullIfEmpty<T>);
      };
      reader.onerror = e => reject({ error: e });
    });
  }

  getFullImageUrl(bucket: string, objectName: string, isPrivate = false) {
    return `${environment.supabaseUrl}/storage/v1/object/${isPrivate ? 'private' : 'public'}/${bucket}/${objectName}`;
  }

  async createFileFromObjectName(objectName: string, bucket: string) {
    const fileUrl = this.getFullImageUrl(bucket, objectName);
    const fileResponse = await fetch(fileUrl);
    const blob = await fileResponse.blob();
    const file = new File([blob], objectName, { type: blob.type });
    return file;
  }

  blobToFile(blob: Blob, fileName: string) {
    return new File([blob], fileName, { type: 'image/webp' });
  }

  resizeImage(file: File, maxSizeMB: number) {
    return imageCompression(file, { maxSizeMB });
  }

  getStreamableVideoUrl(objectName: string) {
    return `https://${environment.cloudflareStreamSubdomain}/${objectName}/manifest/video.m3u8`;
  }

  getStreamableVideoThumbnailUrl(objectName: string) {
    return `https://${environment.cloudflareStreamSubdomain}/${objectName}/thumbnails/thumbnail.jpg?time=0s&height=200`;
  }

  getFileSizeMb(file: File) {
    return file.size / (1024 ** 2);
  }

  async download(blob: Blob, fileName: string) {
    if (this.environmentService.isInstalledApp) {
      return this.downloadApp(blob, fileName);
    }
    return this.downloadWeb(blob, fileName);
  }

  async downloadWeb(blob: Blob, fileName: string) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async downloadApp(blob: Blob, fileName: string) {
    const { Filesystem, Directory } = await import('@capacitor/filesystem');
    const response = await Filesystem.writeFile({
      path: fileName,
      data: blob,
      directory: Directory.Documents,
      recursive: true,
    });
    return response.uri;
  }
}

export interface UploadArgs {
  bucketName: string;
  file: File;
  objectName?: string;
  contentType?: string;
  fileReferenceId?: string;
}

export interface TusProgress {
  progress: number;
  bytesSent: number;
  bytesTotal: number;
}

export interface FileAccess {
  objectName: string;
  bucketName: string;
  objectType?: string;
}

export interface SupportedFileFormats {
  image: FileType[];
  video: FileType[];
}

export interface FileType {
  mimeSubType: string;
  extension: string[];
}

type NullIfEmpty<T> = T extends undefined | null ? null : string;