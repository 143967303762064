import { ValidatorFunction } from "src/app/models/validator";
import { isUrlValid } from "src/app/utils/url";

export function urlValidator(required?: boolean, fieldName?: string): ValidatorFunction {
  return function (control) {
    if (!control.touched || !control.dirty) return null;
    const value = control.value;
    if (!value && !required) {
      return null;
    }
    else if (!value) {
      return { error: fieldName ? `${fieldName} is required` : "Please enter a value" };
    }

    // If that doesn't work at the end, we can simply try to make a fetch request with that url and validate it that way...
    const valid = isUrlValid(value);
    if (!valid) {
      return { error: "The web address you have entered is invalid" };
    }
    return null;
  };
}