import { Component, computed, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SessionService } from 'src/app/services/session.service';
import { requiredValidator } from '../../validators/required';
import { emailValidator } from '../../validators/email';
import { maxLengthValidator } from '../../validators/max-length';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { copyObject } from 'src/app/utils/object';
import { ApiService } from 'src/app/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/services/toast.service';
import { registerClassOnWindow } from 'src/app/utils/global';

@Component({
  selector: 'app-account-feedback',
  templateUrl: './account-feedback.component.html',
  styleUrls: ['./account-feedback.component.scss'],
})
export class AccountFeedbackComponent {

  sessionService = inject(SessionService);
  apiService = inject(ApiService);
  toastService = inject(ToastService);

  fullName = computed(() => this.sessionService.account()?.full_name);
  email = computed(() => this.sessionService.session()?.user.email);
  sending = signal(false);

  form = new FormGroup<SendFeedbackForm>({
    name: new FormControl('', [requiredValidator("Name"), maxLengthValidator(50)]),
    email: new FormControl('', [emailValidator(true), maxLengthValidator(50)]),
    message: new FormControl('', [requiredValidator("Message"), maxLengthValidator(500)]),
  });

  constructor() {
    registerClassOnWindow('AccountFeedbackComponent', this);
  }

  sendWithControlEnter(event: KeyboardEvent) {
    if (!event.ctrlKey) return;
    if (event.key !== "Enter") return;
    this.send(event);
  }

  async send<E extends { preventDefault: () => void; }>(event: E) {
    event.preventDefault();
    if (this.sending()) return;
    try {
      // in case the user is signed in, we'll take these data from their session.
      const { name, email } = this.form.controls;
      if (!name.value) {
        name.setValue(this.fullName() ?? null);
      }
      if (!email.value) {
        email.setValue(this.email() ?? null);
      }
      markAllControlsAsTouchedAndDirty(this.form);
      if (this.form.invalid) return;
      this.sending.set(true);
      const value = copyObject(this.form.value);

      const response = await this.apiService.postAsync<{ message: string; }, Partial<FormGroup<SendFeedbackForm>>['value']>('/feedback/send', { body: value });
      this.form.reset();
      this.toastService.success(response.message);
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message ?? error.message ?? error.error);
    } finally {
      this.sending.set(false);
    }
  }
}

export interface SendFeedbackForm {
  name: FormControl<string | null>;
  email: FormControl<string | null>;
  message: FormControl<string | null>;
}