<div
  class="p-8 flex flex-col gap-3 large-mobile:items-start items-center w-full"
>
  <div
    class="rounded-full shadow-[0px_0px_0px_6px_#fafafa] w-10 h-10 bg-accent-light flex items-center justify-center"
  >
    <ion-icon [name]="icon()" class="text-accent text-2xl" />
  </div>
  <p class="font-sherika-bold text-sm text-accent">{{ name() | locale }}</p>
  <h3 class="text-3xl [&>span]:font-sherika-bold">
    @if(!price()) {
    <span>{{ "Free" | locale }}</span>
    } @else {
    <span class="flex gap-2 items-end">
      @if (price()) {
      <span class="text-black font-semibold"
        >{{ price() | currency }}<span class="text-sm">/year</span>
      </span>
      }
    </span>
    }
  </h3>
  <div class="w-full flex flex-col gap-4 mt-6">
    @for(pro of pros(); track pro){
    <div class="w-full flex gap-2 items-center">
      <div
        class="rounded-full w-5 h-5 flex items-center justify-center bg-accent-light"
      >
        <ion-icon name="checkmark-outline" class="text-accent" />
      </div>
      <p class="text-sm text-gray-600">{{ pro | locale }}</p>
    </div>
    } @for (con of cons(); track con) {
    <div class="w-full flex gap-2 items-center">
      <div
        class="rounded-full w-5 h-5 flex items-center justify-center bg-gray-100"
      >
        <ion-icon name="close-outline" class="text-gray-500" />
      </div>
      <p class="text-sm text-gray-600">{{ con | locale }}</p>
    </div>
    }
  </div>
</div>
<div class="w-full p-8 bg-gray-50 mt-auto">
  <app-button
    class="w-full"
    [buttonStyleClass]="'w-full'"
    (click)="onUpgradeClick()"
    [outlined]="price() === 0"
    [disabled]="
      upgradingInProgress() || upgradeStatus().direction === 'current'
    "
    >{{ upgradeStatus().displayText }}
  </app-button>
</div>
