@if (label()) {
  <app-label [htmlFor]="htmlFor()" [value]="label()"></app-label>
}
<textarea
  [rows]="rows()"
  (input)="onInput($event)"
  class="focusable {{ textareaStyleClass() }} relative"
  [value]="value()"
  [placeholder]="placeholder()"
></textarea>
<app-validation-output [value]="errorMessage()" />
