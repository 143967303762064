import { Component, ElementRef, inject, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { debounceTime, switchMap } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { DropdownOption } from '../../../form/dropdown/dropdown.component';

@Component({
  selector: 'app-autocomplete-cell-editor',
  templateUrl: './autocomplete-cell-editor.component.html',
  styleUrls: ['./autocomplete-cell-editor.component.scss'],
})
export class AutocompleteCellEditorComponent implements ICellEditorAngularComp, OnInit, OnDestroy {
  apiService = inject(ApiService);

  loaderFunction = signal<Loader | undefined>(undefined);
  value = signal<AutocompleteOption | undefined>(undefined);
  inputValue = signal<string>('');
  inputValueChanged$ = toObservable(this.inputValue);
  suggestions$ = this.inputValueChanged$.pipe(
    debounceTime(250),
    switchMap(async value => {
      value = value.trim();
      if (value) {
        this.showSuggestions.set(true);
        return await this.loaderFunction()?.(value) ?? [];
      }
      this.showSuggestions.set(false);
      return [];
    }),
  );
  showSuggestions = signal(false);
  input = viewChild<ElementRef<HTMLInputElement>>('input');

  ngOnInit(): void {
    document.addEventListener('click', this.handleDocumentClick);
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick = (event: Event) => {
    if (this.input()?.nativeElement !== event.target) {
      this.showSuggestions.set(false);
    }
  };

  getValue() {
    return this.value();
  }

  agInit(params: ICellEditorParams<any, any, any>): void {
    this.value.set(params.value);
    setTimeout(() => {
      this.input()?.nativeElement.focus();
    }, 0);
    const loaderFunction = params.column.getColDef().cellEditorParams?.loaderFunction;
    this.loaderFunction.set(loaderFunction);
  }

  onChange(event: Event) {
    const target = event.target as HTMLInputElement;
    this.inputValue.set(target.value);
  }

  setValue(suggestion: AutocompleteOption) {
    this.value.set(suggestion);
    this.setValueQuietly(suggestion.label);
  }

  setValueQuietly(value: string | null | undefined) {
    const input = this.input()?.nativeElement;
    if (!input) return;
    input.value = value ?? '';
  }
}

export type Loader = (value: string | undefined) => AutocompleteOption[] | Promise<AutocompleteOption[]>;

export interface AutocompleteOption<T = any> extends DropdownOption<string> {
  data?: T | null;
}