@if (value()?.file) {
  <div>
    <img [src]="imageSrc()" alt="" class="rounded-full" />
    <div
      class="absolute z-20 inset-0 bg-black/5 hover:bg-black/10 active:bg-black/10 transition-colors rounded-full"
    ></div>
    <ion-icon
      name="close-circle-outline"
      class="text-2xl absolute top-0 right-0 z-20 bg-white rounded-full"
      (click)="clear($event)"
    ></ion-icon>
  </div>
} @else {
  <label
    for="koshery-file-upload"
    class="w-full h-full border border-solid border-gray-300 rounded-full flex justify-center items-center cursor-pointer"
    [tabIndex]="0"
    [htmlFor]="htmlFor()"
  >
    <ion-icon name="camera-outline" size="large" class="text-gray-600" />
  </label>
}
