import { Component, computed, inject } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { PopoverButton } from '../../ui/popover-buttons/popover-buttons.component';
import { SessionService } from 'src/app/services/session.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent {
  sessionService = inject(SessionService);
  authService = inject(AuthService);

  menuItems = computed<MenuItem[]>(() => [
    { label: "Business dashboard", href: "/dashboard/pages" },
    { label: "Profile", href: "/user/account/profile", icon: "person-circle-outline" },
    { label: "Support", href: "/user/account/feedback", icon: "help-circle-outline" },
  ]);
  popoverButtons = computed<PopoverButton[]>(() => [
    { label: "Profile", icon: "person-circle-outline", href: "/user/account/profile" },
    { label: "Support", icon: "help-circle-outline", href: "/user/account/feedback" },
    { isDivider: true },
    { label: "Log out", action: () => this.authService.logout(), icon: "power-outline" }
  ]);
}
