import { Component, computed, inject, signal } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { SessionService } from 'src/app/services/session.service';
import { AuthService } from 'src/app/services/auth.service';
import { ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ToastService } from 'src/app/services/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Role } from 'src/app/models/role';
import { registerClassOnWindow } from 'src/app/utils/global';
import { ActionSheetButton } from '@ionic/angular';
import type { IonInfiniteScrollCustomEvent, OverlayEventDetail } from '@ionic/core';
import { User } from 'src/app/models/user';
import { BehaviorSubject, debounceTime, distinctUntilChanged, map, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements ViewWillEnter, ViewDidLeave {
  sessionService = inject(SessionService);
  authService = inject(AuthService);
  apiService = inject(ApiService);
  toastService = inject(ToastService);

  menuItems = computed(() => []);

  popoverButtons = computed(() => [{ label: "Log out", action: () => this.authService.logout(), icon: 'power' }]);

  model = signal<UsersResponse | undefined>(undefined);
  loading = signal(false);
  Role = Role;
  updatingUserRole = signal(false);

  openActionSheet = signal<number | undefined>(undefined);
  pageNumber = signal(0);
  searchText$ = new BehaviorSubject<string>('');
  search$ = this.searchText$.pipe(
    distinctUntilChanged(),
    debounceTime(500),
    switchMap(text => {
      return this.apiService.get<UsersResponse>(`/admin/get-all-users?page=${this.pageNumber()}&q=${text ?? ''}`);
    }),
    map(response => response.data)
  );
  subscription = new Subscription();

  constructor() {
    registerClassOnWindow('UsersComponent', this);
  }

  ionViewWillEnter() {
    this.load();
    if (this.subscription.closed)
      this.subscription = new Subscription();
    this.subscription.add(this.search$.subscribe(value => this.model.set(value)));
  }

  ionViewDidLeave() {
    this.model.set(undefined);
    this.pageNumber.set(0);
    this.subscription.unsubscribe();
  }

  async load(reload?: boolean) {
    if (!this.sessionService.session()) return;
    if (this.loading()) return;
    try {
      this.loading.set(true);
      const response = await this.apiService.getAsync<UsersResponse>(`/admin/get-all-users?page=${this.pageNumber()}&q=${this.searchText$.value}`);
      if (reload) {
        this.model.set(response.data);
        this.pageNumber.set(0);
      } else {
        const existingModel = this.model();
        const existingUsers = existingModel?.users ?? [];
        const mergedUsers = [...existingUsers, ...(response.data.users ?? [])];
        response.data.users = mergedUsers;
        this.model.set(response.data);
      }
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error?.message ?? 'Error loading users');
    } finally {
      this.loading.set(false);
    }
  }

  async onInfinite(event: IonInfiniteScrollCustomEvent<void>) {
    this.pageNumber.set(this.pageNumber() + 1);
    await this.load();
    event.target.complete();
  }

  async updateUserRole(newRole: Role | null | undefined, userIndex: number) {
    if (!newRole && newRole !== 0) return;
    if (this.updatingUserRole()) return;
    try {
      this.updatingUserRole.set(true);
      const userId = this.model()?.users[userIndex]?.userId;
      const response = await this.apiService.postAsync('/admin/update-user-role', { body: { userId, role: newRole } });
      this.toastService.info(response.message);
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message);
    } finally {
      this.updatingUserRole.set(false);
    }
  }

  getUserRoleActionSheetButtons(role: Role | null | undefined) {
    const actionSheetButton: ActionSheetButton[] = [];
    if (role !== Role.RegularUser) {
      actionSheetButton.push({ text: 'Regular user', data: Role.RegularUser });
    }
    if (role !== Role.Staff) {
      actionSheetButton.push({ text: 'Koshery staff', role: 'destructive', data: Role.Staff });
    }
    if (role !== Role.Admin) {
      actionSheetButton.push({ text: 'Admin', role: 'destructive', data: Role.Admin });
    }
    return actionSheetButton;
  }

  async actionSheetResults(event: CustomEvent<OverlayEventDetail>, index: number) {
    this.openActionSheet.set(undefined);
    const newRole = event.detail.data as Role | null | undefined;
    await this.updateUserRole(newRole, index);
    this.load(true);
  }
}


export interface UsersResponse {
  users: User[];
  page: number;
  perPage: number;
  total: number;
}
