export const locales: LocaleDict = {
  "Log In": {
    en: "Log In",
    he: "התחבר",
  },
  "Sign Up": {
    en: "Sign Up",
    he: "הירשם"
  },
  "Home": {
    en: "Home",
    he: "בית"
  },
  "Switch to Professional Account": {
    en: "Switch to Professional Account",
    he: "מעבר לחשבון מקצועי",
  },
  "Settings": {
    en: "Settings",
    he: "הגדרות",
  },
  "Support": {
    en: "Support",
    he: "תמיכה",
  },
  "Sign Out": {
    en: "Sign Out",
    he: "יציאה",
  },
  "Search": {
    en: "Search",
    he: "חיפוש",
  },
  "Menu": {
    en: "Menu",
    he: "תפריט",
  },
  "Let's get started.": {
    en: "Let's get started.",
    he: 'בוא נתחיל.'
  },
  "-- Please Select --": {
    en: "-- Please Select --",
    he: "-- אנא בחר --"
  },
  "Regular User": {
    en: "Regular User",
    he: "משתמש רגיל"
  },
  "Business": {
    en: "Business",
    he: "עסק"
  },
  "Family": {
    en: "Family",
    he: "משפחה",
  },
  "Organization": {
    en: "Organization",
    he: "ארגון"
  },
  "Welcome back!": {
    en: "Welcome back!",
    he: "ברוך שובך!"
  },
  "Please enter your details.": {
    en: "Please enter your details.",
    he: "בבקשה הזן את פרטיך."
  },
  "Password": {
    en: "Password",
    he: "סיסמה",
  },
  "Email": {
    en: "Email",
    he: 'דוא"ל',
  },
  "Don't have an account?": {
    en: "Don't have an account?",
    he: "אין לך חשבון?"
  },
  "Forgot password?": {
    en: "Forgot password",
    he: "שכחת סיסמה?"
  },
  "No worries, we'll email you a reset link.": {
    en: "No worries, we'll email you a reset link.",
    he: "אל דאגה, נשלח לך קישור לאיפוס הסיסמה במייל."
  },
  "Reset password": {
    en: "Reset password",
    he: "איפוס סיסמה"
  },
  "New password": {
    en: "New Password",
    he: "סיסמה חדשה"
  },
  "Please enter a new password": {
    en: "Please enter a new password",
    he: "אנא הזן סיסמה חדשה"
  },
  "Must be at least 8 characters": {
    en: "Must be at least 8 characters",
    he: "חייב להיות לפחות 8 תווים"
  },
  "Account Type": {
    en: "Account Type",
    he: "סוג חשבון"
  },
  "Name": {
    en: "Name",
    he: "שם"
  },
  "Phone": {
    en: "Phone",
    he: "טלפון"
  },
  "Platinum": {
    en: "Platinum",
    he: "פלטינום"
  },
  "Year": {
    en: "Year",
    he: "שנה"
  },
  "List unlimited pages": {
    en: "List unlimited pages",
    he: "רשימת דפים בלתי מוגבלת"
  },
  "25 custom boxes": {
    en: "25 custom boxes",
    he: "25 תיבות מותאמות אישית"
  },
  "Upload audio file": {
    en: "Upload audio file",
    he: "העלאת קובץ שמע"
  },
  "3 videos per page": {
    en: "3 videos per page",
    he: "3 סרטונים לדף"
  },
  "20 image uploads per page": {
    en: "20 image uploads per page",
    he: "20 העלאות תמונה לדף"
  },
  "List 3 pages": {
    en: "List 3 pages",
    he: "רשימת 3 דפים"
  },
  "6 custom boxes": {
    en: "6 custom boxes",
    he: "6 תיבות מותאמות אישית"
  },
  "Premium": {
    en: "Premium",
    he: "פרימיום"
  },
  "Upload videos": {
    en: "Upload videos",
    he: "העלאת סרטונים"
  },
  "5 image uploads": {
    en: "5 image uploads",
    he: "5 העלאות תמונה"
  },
  "Upload PDF file": {
    en: "Upload PDF file",
    he: "העלאת קובץ PDF"
  },
  "Get started": {
    en: "Get started",
    he: "התחל"
  },
  "Upgrade": {
    en: "Upgrade",
    he: "שדרג"
  },
  "Plans": {
    en: "Plans",
    he: "תוכניות"
  },
  "Choose a plan that suits you best": {
    en: "Choose a plan that suits you best",
    he: "בחר תוכנית שמתאימה לך"
  },
  "Classic": {
    en: "Classic",
    he: "קלאסי"
  },
  "List a single page": {
    en: "List a single page",
    he: "רשימת דף יחיד"
  },
  "2 custom boxes": {
    en: "2 custom boxes",
    he: "2 תיבות מותאמות אישית"
  },
  "100 image uploads per page": {
    en: "100 image uploads per page",
    he: "100 העלאות תמונה לדף"
  },
  "Free": {
    en: "Free",
    he: "חינם"
  },
  "5 videos per page": {
    en: "5 videos per page",
    he: "5 סרטונים לדף"
  },
  "Continue to payment": {
    en: "Continue to payment",
    he: "המשך לתשלום"
  },
  "Afghanistan": {
    "en": "Afghanistan",
    "he": "אפגניסטן"
  },
  "Albania": {
    "en": "Albania",
    "he": "אלבניה"
  },
  "Algeria": {
    "en": "Algeria",
    "he": "אלג׳יריה"
  },
  "American Samoa": {
    "en": "American Samoa",
    "he": "סמואה האמריקנית"
  },
  "Andorra": {
    "en": "Andorra",
    "he": "אנדורה"
  },
  "Angola": {
    "en": "Angola",
    "he": "אנגולה"
  },
  "Anguilla": {
    "en": "Anguilla",
    "he": "אנגילה"
  },
  "Antarctica": {
    "en": "Antarctica",
    "he": "אנטארקטיקה"
  },
  "Antigua and Barbuda": {
    "en": "Antigua and Barbuda",
    "he": "אנטיגואה וברבודה"
  },
  "Argentina": {
    "en": "Argentina",
    "he": "ארגנטינה"
  },
  "Armenia": {
    "en": "Armenia",
    "he": "ארמניה"
  },
  "Aruba": {
    "en": "Aruba",
    "he": "ארובה"
  },
  "Australia": {
    "en": "Australia",
    "he": "אוסטרליה"
  },
  "Austria": {
    "en": "Austria",
    "he": "אוסטריה"
  },
  "Azerbaijan": {
    "en": "Azerbaijan",
    "he": "אזרבייג׳ן"
  },
  "Bahamas": {
    "en": "Bahamas",
    "he": "איי בהאמה"
  },
  "Bahrain": {
    "en": "Bahrain",
    "he": "בחריין"
  },
  "Bangladesh": {
    "en": "Bangladesh",
    "he": "בנגלדש"
  },
  "Barbados": {
    "en": "Barbados",
    "he": "ברבדוס"
  },
  "Belarus": {
    "en": "Belarus",
    "he": "בלארוס"
  },
  "Belgium": {
    "en": "Belgium",
    "he": "בלגיה"
  },
  "Belize": {
    "en": "Belize",
    "he": "בליז"
  },
  "Benin": {
    "en": "Benin",
    "he": "בנין"
  },
  "Bermuda": {
    "en": "Bermuda",
    "he": "ברמודה"
  },
  "Bhutan": {
    "en": "Bhutan",
    "he": "בהוטן"
  },
  "Bolivia": {
    "en": "Bolivia",
    "he": "בוליביה"
  },
  "Bosnia and Herzegovina": {
    "en": "Bosnia and Herzegovina",
    "he": "בוסניה והרצגובינה"
  },
  "Botswana": {
    "en": "Botswana",
    "he": "בוצוואנה"
  },
  "Bouvet Island": {
    "en": "Bouvet Island",
    "he": "איי בובה"
  },
  "Brazil": {
    "en": "Brazil",
    "he": "ברזיל"
  },
  "British Indian Ocean Territory": {
    "en": "British Indian Ocean Territory",
    "he": "הטריטוריה הבריטית באוקיינוס ההודי"
  },
  "Brunei Darussalam": {
    "en": "Brunei Darussalam",
    "he": "ברוניי"
  },
  "Bulgaria": {
    "en": "Bulgaria",
    "he": "בולגריה"
  },
  "Burkina Faso": {
    "en": "Burkina Faso",
    "he": "בורקינה פאסו"
  },
  "Burundi": {
    "en": "Burundi",
    "he": "בורונדי"
  },
  "Cambodia": {
    "en": "Cambodia",
    "he": "קמבודיה"
  },
  "Cameroon": {
    "en": "Cameroon",
    "he": "קמרון"
  },
  "Canada": {
    "en": "Canada",
    "he": "קנדה"
  },
  "Cape Verde": {
    "en": "Cape Verde",
    "he": "כף ורדה"
  },
  "Cayman Islands": {
    "en": "Cayman Islands",
    "he": "איי קיימן"
  },
  "Central African Republic": {
    "en": "Central African Republic",
    "he": "הרפובליקה של מרכז אפריקה"
  },
  "Chad": {
    "en": "Chad",
    "he": "צ׳אד"
  },
  "Chile": {
    "en": "Chile",
    "he": "צ׳ילה"
  },
  "People's Republic of China": {
    "en": "People's Republic of China",
    "he": "סין"
  },
  "Christmas Island": {
    "en": "Christmas Island",
    "he": "האי כריסטמס"
  },
  "Cocos (Keeling) Islands": {
    "en": "Cocos (Keeling) Islands",
    "he": "איי קוקוס (קילינג)"
  },
  "Colombia": {
    "en": "Colombia",
    "he": "קולומביה"
  },
  "Comoros": {
    "en": "Comoros",
    "he": "קומורו"
  },
  "Republic of the Congo": {
    "en": "Republic of the Congo",
    "he": "קונגו - ברזאויל"
  },
  "Democratic Republic of the Congo": {
    "en": "Democratic Republic of the Congo",
    "he": "קונגו - קינשאסה"
  },
  "Cook Islands": {
    "en": "Cook Islands",
    "he": "איי קוק"
  },
  "Costa Rica": {
    "en": "Costa Rica",
    "he": "קוסטה ריקה"
  },
  "Cote d'Ivoire": {
    "en": "Cote d'Ivoire",
    "he": "חוף השנהב"
  },
  "Croatia": {
    "en": "Croatia",
    "he": "קרואטיה"
  },
  "Cuba": {
    "en": "Cuba",
    "he": "קובה"
  },
  "Cyprus": {
    "en": "Cyprus",
    "he": "קפריסין"
  },
  "Czech Republic": {
    "en": "Czech Republic",
    "he": "צ׳כיה"
  },
  "Denmark": {
    "en": "Denmark",
    "he": "דנמרק"
  },
  "Djibouti": {
    "en": "Djibouti",
    "he": "ג׳יבוטי"
  },
  "Dominica": {
    "en": "Dominica",
    "he": "דומיניקה"
  },
  "Dominican Republic": {
    "en": "Dominican Republic",
    "he": "הרפובליקה הדומיניקנית"
  },
  "Ecuador": {
    "en": "Ecuador",
    "he": "אקוודור"
  },
  "Egypt": {
    "en": "Egypt",
    "he": "מצרים"
  },
  "El Salvador": {
    "en": "El Salvador",
    "he": "אל סלבדור"
  },
  "Equatorial Guinea": {
    "en": "Equatorial Guinea",
    "he": "גינאה המשוונית"
  },
  "Eritrea": {
    "en": "Eritrea",
    "he": "אריתריאה"
  },
  "Estonia": {
    "en": "Estonia",
    "he": "אסטוניה"
  },
  "Ethiopia": {
    "en": "Ethiopia",
    "he": "אתיופיה"
  },
  "Falkland Islands (Malvinas)": {
    "en": "Falkland Islands (Malvinas)",
    "he": "איי פוקלנד"
  },
  "Faroe Islands": {
    "en": "Faroe Islands",
    "he": "איי פארו"
  },
  "Fiji": {
    "en": "Fiji",
    "he": "פיג׳י"
  },
  "Finland": {
    "en": "Finland",
    "he": "פינלנד"
  },
  "France": {
    "en": "France",
    "he": "צרפת"
  },
  "French Guiana": {
    "en": "French Guiana",
    "he": "גיאנה הצרפתית"
  },
  "French Polynesia": {
    "en": "French Polynesia",
    "he": "פולינזיה הצרפתית"
  },
  "French Southern Territories": {
    "en": "French Southern Territories",
    "he": "הטריטוריות הדרומיות של צרפת"
  },
  "Gabon": {
    "en": "Gabon",
    "he": "גבון"
  },
  "Republic of The Gambia": {
    "en": "Republic of The Gambia",
    "he": "גמביה"
  },
  "Georgia": {
    "en": "Georgia",
    "he": "גאורגיה"
  },
  "Germany": {
    "en": "Germany",
    "he": "גרמניה"
  },
  "Ghana": {
    "en": "Ghana",
    "he": "גאנה"
  },
  "Gibraltar": {
    "en": "Gibraltar",
    "he": "גיברלטר"
  },
  "Greece": {
    "en": "Greece",
    "he": "יוון"
  },
  "Greenland": {
    "en": "Greenland",
    "he": "גרינלנד"
  },
  "Grenada": {
    "en": "Grenada",
    "he": "גרנדה"
  },
  "Guadeloupe": {
    "en": "Guadeloupe",
    "he": "גוואדלופ"
  },
  "Guam": {
    "en": "Guam",
    "he": "גואם"
  },
  "Guatemala": {
    "en": "Guatemala",
    "he": "גואטמלה"
  },
  "Guinea": {
    "en": "Guinea",
    "he": "גינאה"
  },
  "Guinea-Bissau": {
    "en": "Guinea-Bissau",
    "he": "גינאה ביסאו"
  },
  "Guyana": {
    "en": "Guyana",
    "he": "גיאנה"
  },
  "Haiti": {
    "en": "Haiti",
    "he": "האיטי"
  },
  "Heard Island and McDonald Islands": {
    "en": "Heard Island and McDonald Islands",
    "he": "איי הרד ומקדונלד"
  },
  "Holy See (Vatican City State)": {
    "en": "Holy See (Vatican City State)",
    "he": "הוותיקן"
  },
  "Honduras": {
    "en": "Honduras",
    "he": "הונדורס"
  },
  "Hong Kong": {
    "en": "Hong Kong",
    "he": "הונג קונג (מחוז מנהלי מיוחד של סין)"
  },
  "Hungary": {
    "en": "Hungary",
    "he": "הונגריה"
  },
  "Iceland": {
    "en": "Iceland",
    "he": "איסלנד"
  },
  "India": {
    "en": "India",
    "he": "הודו"
  },
  "Indonesia": {
    "en": "Indonesia",
    "he": "אינדונזיה"
  },
  "Islamic Republic of Iran": {
    "en": "Islamic Republic of Iran",
    "he": "איראן"
  },
  "Iraq": {
    "en": "Iraq",
    "he": "עיראק"
  },
  "Ireland": {
    "en": "Ireland",
    "he": "אירלנד"
  },
  "Israel": {
    "en": "Israel",
    "he": "ישראל"
  },
  "Italy": {
    "en": "Italy",
    "he": "איטליה"
  },
  "Jamaica": {
    "en": "Jamaica",
    "he": "ג׳מייקה"
  },
  "Japan": {
    "en": "Japan",
    "he": "יפן"
  },
  "Jordan": {
    "en": "Jordan",
    "he": "ירדן"
  },
  "Kazakhstan": {
    "en": "Kazakhstan",
    "he": "קזחסטן"
  },
  "Kenya": {
    "en": "Kenya",
    "he": "קניה"
  },
  "Kiribati": {
    "en": "Kiribati",
    "he": "קיריבאטי"
  },
  "North Korea": {
    "en": "North Korea",
    "he": "קוריאה הצפונית"
  },
  "South Korea": {
    "en": "South Korea",
    "he": "קוריאה הדרומית"
  },
  "Kuwait": {
    "en": "Kuwait",
    "he": "כווית"
  },
  "Kyrgyzstan": {
    "en": "Kyrgyzstan",
    "he": "קירגיזסטן"
  },
  "Lao People's Democratic Republic": {
    "en": "Lao People's Democratic Republic",
    "he": "לאוס"
  },
  "Latvia": {
    "en": "Latvia",
    "he": "לטביה"
  },
  "Lebanon": {
    "en": "Lebanon",
    "he": "לבנון"
  },
  "Lesotho": {
    "en": "Lesotho",
    "he": "לסוטו"
  },
  "Liberia": {
    "en": "Liberia",
    "he": "ליבריה"
  },
  "Libya": {
    "en": "Libya",
    "he": "לוב"
  },
  "Liechtenstein": {
    "en": "Liechtenstein",
    "he": "ליכטנשטיין"
  },
  "Lithuania": {
    "en": "Lithuania",
    "he": "ליטא"
  },
  "Luxembourg": {
    "en": "Luxembourg",
    "he": "לוקסמבורג"
  },
  "Macao": {
    "en": "Macao",
    "he": "מקאו (מחוז מנהלי מיוחד של סין)"
  },
  "Madagascar": {
    "en": "Madagascar",
    "he": "מדגסקר"
  },
  "Malawi": {
    "en": "Malawi",
    "he": "מלאווי"
  },
  "Malaysia": {
    "en": "Malaysia",
    "he": "מלזיה"
  },
  "Maldives": {
    "en": "Maldives",
    "he": "האיים המלדיביים"
  },
  "Mali": {
    "en": "Mali",
    "he": "מאלי"
  },
  "Malta": {
    "en": "Malta",
    "he": "מלטה"
  },
  "Marshall Islands": {
    "en": "Marshall Islands",
    "he": "איי מרשל"
  },
  "Martinique": {
    "en": "Martinique",
    "he": "מרטיניק"
  },
  "Mauritania": {
    "en": "Mauritania",
    "he": "מאוריטניה"
  },
  "Mauritius": {
    "en": "Mauritius",
    "he": "מאוריציוס"
  },
  "Mayotte": {
    "en": "Mayotte",
    "he": "מאיוט"
  },
  "Mexico": {
    "en": "Mexico",
    "he": "מקסיקו"
  },
  "Micronesia, Federated States of": {
    "en": "Micronesia, Federated States of",
    "he": "מיקרונזיה"
  },
  "Moldova, Republic of": {
    "en": "Moldova, Republic of",
    "he": "מולדובה"
  },
  "Monaco": {
    "en": "Monaco",
    "he": "מונקו"
  },
  "Mongolia": {
    "en": "Mongolia",
    "he": "מונגוליה"
  },
  "Montserrat": {
    "en": "Montserrat",
    "he": "מונסראט"
  },
  "Morocco": {
    "en": "Morocco",
    "he": "מרוקו"
  },
  "Mozambique": {
    "en": "Mozambique",
    "he": "מוזמביק"
  },
  "Myanmar": {
    "en": "Myanmar",
    "he": "מיאנמר (בורמה)"
  },
  "Namibia": {
    "en": "Namibia",
    "he": "נמיביה"
  },
  "Nauru": {
    "en": "Nauru",
    "he": "נאורו"
  },
  "Nepal": {
    "en": "Nepal",
    "he": "נפאל"
  },
  "Netherlands": {
    "en": "Netherlands",
    "he": "הולנד"
  },
  "New Caledonia": {
    "en": "New Caledonia",
    "he": "קלדוניה החדשה"
  },
  "New Zealand": {
    "en": "New Zealand",
    "he": "ניו זילנד"
  },
  "Nicaragua": {
    "en": "Nicaragua",
    "he": "ניקרגואה"
  },
  "Niger": {
    "en": "Niger",
    "he": "ניז׳ר"
  },
  "Nigeria": {
    "en": "Nigeria",
    "he": "ניגריה"
  },
  "Niue": {
    "en": "Niue",
    "he": "ניווה"
  },
  "Norfolk Island": {
    "en": "Norfolk Island",
    "he": "איי נורפוק"
  },
  "The Republic of North Macedonia": {
    "en": "The Republic of North Macedonia",
    "he": "מקדוניה הצפונית"
  },
  "Northern Mariana Islands": {
    "en": "Northern Mariana Islands",
    "he": "איי מריאנה הצפוניים"
  },
  "Norway": {
    "en": "Norway",
    "he": "נורווגיה"
  },
  "Oman": {
    "en": "Oman",
    "he": "עומאן"
  },
  "Pakistan": {
    "en": "Pakistan",
    "he": "פקיסטן"
  },
  "Palau": {
    "en": "Palau",
    "he": "פלאו"
  },
  "State of Palestine": {
    "en": "State of Palestine",
    "he": "השטחים הפלסטיניים"
  },
  "Panama": {
    "en": "Panama",
    "he": "פנמה"
  },
  "Papua New Guinea": {
    "en": "Papua New Guinea",
    "he": "פפואה גינאה החדשה"
  },
  "Paraguay": {
    "en": "Paraguay",
    "he": "פרגוואי"
  },
  "Peru": {
    "en": "Peru",
    "he": "פרו"
  },
  "Philippines": {
    "en": "Philippines",
    "he": "הפיליפינים"
  },
  "Pitcairn": {
    "en": "Pitcairn",
    "he": "איי פיטקרן"
  },
  "Poland": {
    "en": "Poland",
    "he": "פולין"
  },
  "Portugal": {
    "en": "Portugal",
    "he": "פורטוגל"
  },
  "Puerto Rico": {
    "en": "Puerto Rico",
    "he": "פוארטו ריקו"
  },
  "Qatar": {
    "en": "Qatar",
    "he": "קטאר"
  },
  "Reunion": {
    "en": "Reunion",
    "he": "ראוניון"
  },
  "Romania": {
    "en": "Romania",
    "he": "רומניה"
  },
  "Russian Federation": {
    "en": "Russian Federation",
    "he": "רוסיה"
  },
  "Rwanda": {
    "en": "Rwanda",
    "he": "רואנדה"
  },
  "Saint Helena": {
    "en": "Saint Helena",
    "he": "סנט הלנה"
  },
  "Saint Kitts and Nevis": {
    "en": "Saint Kitts and Nevis",
    "he": "סנט קיטס ונוויס"
  },
  "Saint Lucia": {
    "en": "Saint Lucia",
    "he": "סנט לוסיה"
  },
  "Saint Pierre and Miquelon": {
    "en": "Saint Pierre and Miquelon",
    "he": "סנט פייר ומיקלון"
  },
  "Saint Vincent and the Grenadines": {
    "en": "Saint Vincent and the Grenadines",
    "he": "סנט וינסנט והגרנדינים"
  },
  "Samoa": {
    "en": "Samoa",
    "he": "סמואה"
  },
  "San Marino": {
    "en": "San Marino",
    "he": "סן מרינו"
  },
  "Sao Tome and Principe": {
    "en": "Sao Tome and Principe",
    "he": "סאו טומה ופרינסיפה"
  },
  "Saudi Arabia": {
    "en": "Saudi Arabia",
    "he": "ערב הסעודית"
  },
  "Senegal": {
    "en": "Senegal",
    "he": "סנגל"
  },
  "Seychelles": {
    "en": "Seychelles",
    "he": "איי סיישל"
  },
  "Sierra Leone": {
    "en": "Sierra Leone",
    "he": "סיירה לאונה"
  },
  "Singapore": {
    "en": "Singapore",
    "he": "סינגפור"
  },
  "Slovakia": {
    "en": "Slovakia",
    "he": "סלובקיה"
  },
  "Slovenia": {
    "en": "Slovenia",
    "he": "סלובניה"
  },
  "Solomon Islands": {
    "en": "Solomon Islands",
    "he": "איי שלמה"
  },
  "Somalia": {
    "en": "Somalia",
    "he": "סומליה"
  },
  "South Africa": {
    "en": "South Africa",
    "he": "דרום אפריקה"
  },
  "South Georgia and the South Sandwich Islands": {
    "en": "South Georgia and the South Sandwich Islands",
    "he": "ג׳ורג׳יה הדרומית ואיי סנדוויץ׳ הדרומיים"
  },
  "Spain": {
    "en": "Spain",
    "he": "ספרד"
  },
  "Sri Lanka": {
    "en": "Sri Lanka",
    "he": "סרי לנקה"
  },
  "Sudan": {
    "en": "Sudan",
    "he": "סודן"
  },
  "Suriname": {
    "en": "Suriname",
    "he": "סורינם"
  },
  "Svalbard and Jan Mayen": {
    "en": "Svalbard and Jan Mayen",
    "he": "סוולבארד ויאן מאיין"
  },
  "Eswatini": {
    "en": "Eswatini",
    "he": "סווזילנד"
  },
  "Sweden": {
    "en": "Sweden",
    "he": "שוודיה"
  },
  "Switzerland": {
    "en": "Switzerland",
    "he": "שווייץ"
  },
  "Syrian Arab Republic": {
    "en": "Syrian Arab Republic",
    "he": "סוריה"
  },
  "Taiwan, Province of China": {
    "en": "Taiwan, Province of China",
    "he": "טייוואן"
  },
  "Tajikistan": {
    "en": "Tajikistan",
    "he": "טג׳יקיסטן"
  },
  "United Republic of Tanzania": {
    "en": "United Republic of Tanzania",
    "he": "טנזניה"
  },
  "Thailand": {
    "en": "Thailand",
    "he": "תאילנד"
  },
  "Timor-Leste": {
    "en": "Timor-Leste",
    "he": "טימור לסטה"
  },
  "Togo": {
    "en": "Togo",
    "he": "טוגו"
  },
  "Tokelau": {
    "en": "Tokelau",
    "he": "טוקלאו"
  },
  "Tonga": {
    "en": "Tonga",
    "he": "טונגה"
  },
  "Trinidad and Tobago": {
    "en": "Trinidad and Tobago",
    "he": "טרינידד וטובגו"
  },
  "Tunisia": {
    "en": "Tunisia",
    "he": "טוניסיה"
  },
  "Türkiye": {
    "en": "Türkiye",
    "he": "טורקיה"
  },
  "Turkmenistan": {
    "en": "Turkmenistan",
    "he": "טורקמניסטן"
  },
  "Turks and Caicos Islands": {
    "en": "Turks and Caicos Islands",
    "he": "איי טורקס וקאיקוס"
  },
  "Tuvalu": {
    "en": "Tuvalu",
    "he": "טובאלו"
  },
  "Uganda": {
    "en": "Uganda",
    "he": "אוגנדה"
  },
  "Ukraine": {
    "en": "Ukraine",
    "he": "אוקראינה"
  },
  "United Arab Emirates": {
    "en": "United Arab Emirates",
    "he": "איחוד האמירויות הערביות"
  },
  "United Kingdom": {
    "en": "United Kingdom",
    "he": "הממלכה המאוחדת"
  },
  "United States of America": {
    "en": "United States of America",
    "he": "ארצות הברית"
  },
  "United States Minor Outlying Islands": {
    "en": "United States Minor Outlying Islands",
    "he": "האיים המרוחקים הקטנים של ארה״ב"
  },
  "Uruguay": {
    "en": "Uruguay",
    "he": "אורוגוואי"
  },
  "Uzbekistan": {
    "en": "Uzbekistan",
    "he": "אוזבקיסטן"
  },
  "Vanuatu": {
    "en": "Vanuatu",
    "he": "ונואטו"
  },
  "Venezuela": {
    "en": "Venezuela",
    "he": "ונצואלה"
  },
  "Vietnam": {
    "en": "Vietnam",
    "he": "וייטנאם"
  },
  "Virgin Islands, British": {
    "en": "Virgin Islands, British",
    "he": "איי הבתולה הבריטיים"
  },
  "Virgin Islands, U.S.": {
    "en": "Virgin Islands, U.S.",
    "he": "איי הבתולה של ארצות הברית"
  },
  "Wallis and Futuna": {
    "en": "Wallis and Futuna",
    "he": "איי ווליס ופוטונה"
  },
  "Western Sahara": {
    "en": "Western Sahara",
    "he": "סהרה המערבית"
  },
  "Yemen": {
    "en": "Yemen",
    "he": "תימן"
  },
  "Zambia": {
    "en": "Zambia",
    "he": "זמביה"
  },
  "Zimbabwe": {
    "en": "Zimbabwe",
    "he": "זימבבואה"
  },
  "Åland Islands": {
    "en": "Åland Islands",
    "he": "איי אולנד"
  },
  "Bonaire, Sint Eustatius and Saba": {
    "en": "Bonaire, Sint Eustatius and Saba",
    "he": "האיים הקריביים ההולנדיים"
  },
  "Curaçao": {
    "en": "Curaçao",
    "he": "קוראסאו"
  },
  "Guernsey": {
    "en": "Guernsey",
    "he": "גרנסי"
  },
  "Isle of Man": {
    "en": "Isle of Man",
    "he": "האי מאן"
  },
  "Jersey": {
    "en": "Jersey",
    "he": "ג׳רסי"
  },
  "Montenegro": {
    "en": "Montenegro",
    "he": "מונטנגרו"
  },
  "Saint Barthélemy": {
    "en": "Saint Barthélemy",
    "he": "סנט ברתולומיאו"
  },
  "Saint Martin (French part)": {
    "en": "Saint Martin (French part)",
    "he": "סן מרטן"
  },
  "Serbia": {
    "en": "Serbia",
    "he": "סרביה"
  },
  "Sint Maarten (Dutch part)": {
    "en": "Sint Maarten (Dutch part)",
    "he": "סנט מארטן"
  },
  "South Sudan": {
    "en": "South Sudan",
    "he": "דרום סודן"
  },
  "Kosovo": {
    "en": "Kosovo",
    "he": "קוסובו"
  }

};

export interface Locale {
  he: string | null | undefined;
  en: string | null | undefined;
}

export interface LocaleDict {
  [key: string]: Locale;
}