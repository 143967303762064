import { Component, computed, inject, input, OnInit, output } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
import { DropdownOption } from 'src/app/components/ui/form/dropdown/dropdown.component';
import { CustomFieldType } from 'src/app/models/entity/custom-field';
import { EntityEditCustomFields, EntityEditForm } from 'src/app/models/entity/edit/form';
import { CustomFieldService } from 'src/app/services/custom-field.service';
import { FileService } from 'src/app/services/file.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { EntityEditComponent } from '../entity-edit.component';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-entity-edit-custom-fields',
  templateUrl: './entity-edit-custom-fields.component.html',
  styleUrls: ['./entity-edit-custom-fields.component.scss'],
})
export class EntityEditCustomFieldsComponent implements OnInit {
  sessionService = inject(SessionService);
  controlContainer = inject(ControlContainer);
  fileService = inject(FileService);
  toastService = inject(ToastService);
  customFieldService = inject(CustomFieldService);
  entityEditComponent = inject(EntityEditComponent);
  subscriptionService = inject(SubscriptionService);

  subscription = computed(() => {
    const type = this.subscriptionService.subscription()?.type;
    if (!type) return;
    const subscription = this.subscriptionService.subscriptionPricing[type];
    return subscription;
  });

  form!: FormGroup<EntityEditForm>;
  saveForm = output();
  saving = input(false);
  customFieldOptions = computed<DropdownOption<CustomFieldType>[]>(() => [
    { label: 'Rich Text Editor', value: CustomFieldType.RichTextEditor, emoji: '✒️' },
    { label: 'Images', value: CustomFieldType.Images, emoji: '📷' },
    { label: 'Videos', value: CustomFieldType.Videos, emoji: '🎞️' },
    { label: 'Audio File', value: CustomFieldType.AudioFile, emoji: '🔈' },
    { label: 'PDF File', value: CustomFieldType.PdfFile, emoji: '🗃️' },
  ]);

  CustomFieldType = CustomFieldType;
  entityId = computed(() => this.entityEditComponent.entityId());
  back = output<void>();

  ngOnInit() {
    this.form = this.controlContainer.control as FormGroup<EntityEditForm>;
  }

  addCustomField(event: Event) {
    // TODO check if the user is eligible to add a new custom field
    event.preventDefault(); // prevent form submission.
    this.customFieldService.add(this.form);
  }

  removeField(event: Event, index: number) {
    event.preventDefault();
    this.customFieldService.removeByIndex(this.form, index);
  }

  save(event: MouseEvent) {
    event.preventDefault();
    this.saveForm.emit();
  }

  async handleCustomFieldSelected(dropdownOption: DropdownOption<CustomFieldType> | undefined, customField: FormGroup<EntityEditCustomFields>) {
    const customFieldType = dropdownOption?.value;
    if (!customFieldType) return;
    const canSelectType = await this.customFieldService.canSelectCustomFieldType(customFieldType);
    if (!canSelectType.canAdd) {
      customField.controls.fieldType.setValue(null);
      this.toastService.error(canSelectType.reason);
      return;
    }
    customField.controls.fieldType.setValue(customFieldType);
    this.customFieldService.applyCustomFieldControl(customField, customFieldType);
  }


  onBack(event: MouseEvent) {
    event.preventDefault();
    this.back.emit();
  }
}