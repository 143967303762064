import { Component, computed, inject } from '@angular/core';
import { MenuItem } from 'src/app/models/menu-item';
import { SessionService } from 'src/app/services/session.service';
import { AuthService } from 'src/app/services/auth.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardPage {

  authService = inject(AuthService);
  sessionService = inject(SessionService);
  navController = inject(NavController);

  sideMenuButtons = computed<MenuItem[]>(() => {
    const buttons: MenuItem[] = [
      { label: "Dashboard", href: "/dashboard/pages", icon: "pricetags" },
      { label: "Subscription", href: "/dashboard/subscription", icon: "card" },
    ];
    const isAdmin = this.sessionService.isAdmin();
    const isStaff = this.sessionService.isStaff();
    if (isAdmin || isStaff) {
      buttons.push({ isDivider: true });
      buttons.push({ label: "Bulk pages", href: "/dashboard/bulk-pages", icon: "documents" });
    }
    if (isAdmin) {
      buttons.push({ isDivider: true });
    }
    if (isAdmin) {
      buttons.push(
        { label: "Users", href: "/dashboard/users", icon: "people" },
        { label: "Export", href: "/dashboard/export", icon: "cloud-download" },
      );
    }
    return buttons;
  });

  navigateRoot(event: Event | undefined, url: string) {
    event?.preventDefault();
    event?.stopPropagation();
    this.navController.navigateRoot(url);
  }
}
