import { HttpErrorResponse } from '@angular/common/http';
import { Component, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { OnUpgradeArgs, SubscriptionType } from 'src/app/models/subscription';
import { PromoCode, PromoService } from 'src/app/services/promo.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent {

  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  promoService = inject(PromoService);
  subscriptionService = inject(SubscriptionService);
  toastService = inject(ToastService);

  discountAmount = signal(this.promoService.appliedPromo()?.discountAmount ?? 0);
  classicPrice = this.subscriptionService.subscriptionPricing[SubscriptionType.Classic].price;
  premiumPrice = this.subscriptionService.subscriptionPricing[SubscriptionType.Premium].price;
  platinumPrice = this.subscriptionService.subscriptionPricing[SubscriptionType.Platinum].price;;
  SubscriptionType = SubscriptionType;
  creatingSubscription = signal(false);

  constructor() {
    this.promoService.promoCode$.pipe(
      takeUntilDestroyed(),
      // seems like there is a mismatch between ts and angular's ts configs, so we need to cast. TODO figure it out.
    ).subscribe(promo => this.discountAmount.set((promo as PromoCode).discountAmount));
  }

  async onUpgrade(upgradeArgs: OnUpgradeArgs) {
    if (this.creatingSubscription()) return; // prevent rage clicking
    this.creatingSubscription.set(true);
    try {
      if (!upgradeArgs.amount) {
        await this.subscriptionService.createSubscriptionWithoutPayment({ subscriptionType: upgradeArgs.subscriptionType, isActive: true });
        this.router.navigateByUrl('/dashboard/pages/0/edit?activeIndex=0');
      } else {
        this.router.navigateByUrl(`/user/pay?subscriptionType=${upgradeArgs.subscriptionType}`);
      }
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message);
    } finally {
      this.creatingSubscription.set(false);
    }
  }
}
