import { inject, Injectable } from '@angular/core';
import { SupabaseService } from './supabase.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { SessionService } from './session.service';
import { RequiredField } from '../types/required-field';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  supabase = inject(SupabaseService);
  httpClient = inject(HttpClient);
  sessionService = inject(SessionService);

  private defaultHeaders = {
    'Content-Type': 'application/json'
  };

  private ensureValidUrl(url: string): string {
    const sanitized = url.replace(/^\/?/, '');
    const base = this.supabase.supabaseUrl;
    return `${base}/functions/v1/api/${sanitized}`;
  }

  get<R>(url: string, options?: ApiRequestOptions): Observable<ApiResponse<R>> {
    url = this.ensureValidUrl(url);
    const jwt = this.sessionService.session()?.access_token;
    return this.httpClient.get<ApiResponse<R>>(url, {
      headers: {
        ...options?.headers,
        ...this.defaultHeaders,
        authorization: `Bearer ${jwt}`
      },
    });
  }
  getAsync<R>(url: string, options?: ApiRequestOptions): Promise<ApiResponse<R>> {
    return firstValueFrom(this.get(url, options));
  }

  post<R, P>(url: string, options?: ApiRequestOptions<P>): Observable<ApiResponse<R>> {
    url = this.ensureValidUrl(url);
    const jwt = this.sessionService.session()?.access_token;
    return this.httpClient.post<ApiResponse<R>>(url, options?.body, {
      headers: {
        ...options?.headers,
        ...this.defaultHeaders,
        authorization: `Bearer ${jwt}`
      }
    });
  }

  postAsync<R, P>(url: string, options: RequiredField<ApiRequestOptions<P>, 'body'>): Promise<ApiResponse<R>> {
    return firstValueFrom(this.post(url, options));
  }
}

export interface ApiRequestOptions<P = any> {
  headers?: HttpHeaders,
  body: P;
};

export interface ApiErrorResponse {
  message: string;
  status: number;
}

export interface ApiResponse<T> {
  data: T;
  message?: string;
}