import { inject, Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../services/localization.service';

@Pipe({
  name: 'locale',
  pure: false,
})
export class LocalePipe implements PipeTransform {
  localizationService = inject(LocalizationService);

  transform(value: string | null | undefined | number): string | null | undefined {
    if (!value) return value?.toString();
    value = value.toString();
    const translated = this.localizationService.getLocalizedText(value);
    if (!translated) {
      // console.warn(`Missing translation for "${value}"`);
      return value;
    }
    return translated;
  }

}
