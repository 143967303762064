import { Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { EntityEditComponent } from '../entity-edit.component';
import { ControlContainer, FormGroup } from '@angular/forms';
import { EntityEditForm } from 'src/app/models/entity/edit/form';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: 'app-entity-edit-search',
  templateUrl: './entity-edit-search.component.html',
  styleUrls: ['./entity-edit-search.component.scss'],
})
export class EntityEditSearchComponent implements OnInit {
  entityEditComponent = inject(EntityEditComponent);
  controlContainer = inject(ControlContainer);
  sessionService = inject(SessionService);

  form = signal<FormGroup<EntityEditForm> | undefined>(undefined);
  searchForm = computed(() => this.form()?.controls.search);
  next = output<void>();
  back = output<void>();

  ngOnInit(): void {
    this.form.set(this.controlContainer.control as FormGroup<EntityEditForm>);
  }

  onBack(event: MouseEvent) {
    event.preventDefault();
    this.back.emit();
  }
}
