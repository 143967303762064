import { HttpErrorResponse } from '@angular/common/http';
import { Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ActionSheetButton, ViewWillEnter } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Session } from '@supabase/supabase-js';
import { combineLatest, filter, map, startWith, Subject, switchMap } from 'rxjs';
import { MenuItem } from 'src/app/models/menu-item';
import { Role } from 'src/app/models/role';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SessionService } from 'src/app/services/session.service';
import { ToastService } from 'src/app/services/toast.service';
import { View } from 'src/app/types/database';
import { registerClassOnWindow } from 'src/app/utils/global';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements ViewWillEnter {
  activatedRoute = inject(ActivatedRoute);
  sessionService = inject(SessionService);
  apiService = inject(ApiService);
  loggingService = inject(LoggingService);
  authService = inject(AuthService);
  toastService = inject(ToastService);

  reloadUser$ = new Subject<void>();
  userId$ = this.activatedRoute.params.pipe(
    map(params => params['userId'] as string),
  );
  userId = toSignal(this.userId$);
  user$ = combineLatest([
    this.userId$,
    this.reloadUser$.pipe(startWith(null)),
  ]).pipe(
    filter(([userId]) => !!userId),
    switchMap(async ([userId]) => {
      const loggedInUser = await this.sessionService.getUser();
      return [loggedInUser, userId] as [Session | null, string];
    }),
    filter(([session]) => !!session),
    switchMap(([_, userId]) => this.apiService.get<View<'admin_user_list'>>(`/admin/get-user?userId=${userId}`)),
    map(data => data.data),
  );
  user = toSignal(this.user$);
  isBanned = computed(() => {
    const user = this.user();
    if (!user) return false;
    return user.banned_until !== 'none' && !!user.banned_until;
  });
  banning = signal(false);
  Role = Role;
  isLoggedInUser = computed(() => this.sessionService.userId() === this.userId());
  updatingUserRole = signal(false);
  openActionSheet = signal<boolean>(false);

  menuItems = computed(() => {
    const items: MenuItem[] = [];
    return items;
  });

  popoverButtons = computed(() => [{ label: "Log out", action: () => this.authService.logout(), icon: 'power' }]);

  constructor() {
    registerClassOnWindow('UserDetailsComponent', this);
  }

  ionViewWillEnter(): void {
    this.reloadUser$.next();
  }

  ngOnInit() {
    this.reloadUser$.next();
  }

  async toggleUserBan() {
    const user = this.user();
    if (!user) {
      this.loggingService.error('User not found');
      return;
    }
    try {
      if (this.banning()) return;
      this.banning.set(true);
      if (this.isBanned()) {
        await this.authService.banUser(user.id!, 'none');
        this.toastService.success(`Ban lifted`);
      } else {
        await this.authService.banUser(user.id!, '876000h');
        this.toastService.info(`${user.full_name ?? 'User'} has been banned`);
      }
      this.reloadUser$.next();
    } catch (e) {
      this.toastService.error(`Error banning ${user.full_name ?? 'user'}`);
    } finally {
      this.banning.set(false);
    }
  }

  async updateUserRole(newRole: Role | null | undefined) {
    if (!newRole && newRole !== 0) return;
    if (this.updatingUserRole()) return;
    try {
      this.updatingUserRole.set(true);
      const userId = this.userId();
      const response = await this.apiService.postAsync('/admin/update-user-role', { body: { userId, role: newRole } });
      this.toastService.info(response.message);
    } catch (e) {
      const error = e as HttpErrorResponse;
      this.toastService.error(error.error.message);
    } finally {
      this.updatingUserRole.set(false);
    }
  }

  getUserRoleActionSheetButtons() {
    const role = this.user()?.role ?? Role.RegularUser;
    const actionSheetButton: ActionSheetButton[] = [];
    if (role !== Role.RegularUser) {
      actionSheetButton.push({ text: 'Regular user', data: Role.RegularUser });
    }
    if (role !== Role.Staff) {
      actionSheetButton.push({ text: 'Koshery staff', data: Role.Staff });
    }
    if (role !== Role.Admin) {
      actionSheetButton.push({ text: 'Admin', role: 'destructive', data: Role.Admin });
    }
    return actionSheetButton;
  }


  async actionSheetResults(event: CustomEvent<OverlayEventDetail>) {
    this.openActionSheet.set(false);
    const newRole = event.detail.data as Role | null | undefined;
    await this.updateUserRole(newRole);
    this.reloadUser$.next();
  }
}
