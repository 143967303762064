@if(mimeType(); as mime) {
<div
  class="w-12 h-12 flex items-center justify-center bg-gray-200 rounded-full border-4 border-solid border-gray-100 mb-1"
>
  <ion-icon name="cloud-upload-outline" class="text-xl"></ion-icon>
</div>
@if (!imageSrc()) {
<div class="max-w-96">
  <input
    type="file"
    name="file"
    id="koshery-file-upload"
    class="hidden"
    [accept]="accepts()"
    [multiple]="mode() === 'multiple'"
    (change)="onFileChange($event)"
    [files]="value()"
    #input
  />
  <label
    for="koshery-file-upload"
    class="cursor-pointer text-accent hover:text-highlight-strong active:text-highlight transition-colors font-sherika-bold text-sm outline-none focus:underline regular-mobile:block text-center"
    [tabIndex]="0"
    (keydown.enter)="input.click()"
    >{{ label() | locale }}
  </label>
  <span class="text-normal text-sm regular-mobile:block text-center"
    >or drag and drop</span
  >
  <div class="text-muted text-sm text-center mt-0.5">
    @if (fileNames() && mode() === 'multiple') {
    {{ fileNames() }}
    }@else {
    {{ "We support " | locale }}{{ subMimeTypes().join(", ") }}
    }
  </div>
</div>
} @if(mode() === 'single' && imageSrc()) {
<div class="absolute inset-0">
  <div
    class="absolute right-2 top-2 p-1 flex items-center justify-center gap-2"
  >
    <ion-icon
      name="crop-outline"
      class="text-2xl cursor-pointer rounded-full bg-muted text-white transition-colors hover:bg-normal p-1"
      (click)="openImageEditor()"
    />
    <ion-icon
      name="close-outline"
      class="text-2xl cursor-pointer rounded-full bg-muted text-white transition-colors hover:bg-normal p-1"
      (click)="onFileRemove()"
    />
  </div>
  <img
    [src]="imageSrc()"
    class="w-full h-full object-cover rounded-lg mobile:rounded-none"
  />
</div>
} }
