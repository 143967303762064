import { Component, computed, inject, input } from '@angular/core';
import { DisplayMedia } from 'src/app/models/image';
import { ModalService } from 'src/app/services/modal.service';
import { MediaSliderComponent } from '../media-slider/media-slider.component';
import { registerClassOnWindow } from 'src/app/utils/global';

@Component({
  selector: 'app-media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['./media-grid.component.scss'],
})
export class MediaGridComponent {
  modalService = inject(ModalService);

  type = input.required<'image' | 'video'>();
  media = input.required<DisplayMedia[]>();
  showProcessingMedia = input<boolean>(false);
  filteredMedia = computed(() => {
    const showProcessingMedia = this.showProcessingMedia();
    const media = this.media();
    if (showProcessingMedia) return media;
    return media.filter(m => !m.processing);
  });
  firstFour = computed(() => this.filteredMedia().slice(0, 4));
  countOfAdditionalMedia = computed(() => {
    const firstFour = this.firstFour();
    return Math.abs(this.filteredMedia().length - firstFour.length);
  });

  constructor() {
    registerClassOnWindow('MediaGridComponent', this);
  }

  async gridItemClicked(index: number) {
    await this.modalService.open(MediaSliderComponent, {
      componentProps: {
        media: this.filteredMedia(),
        type: this.type(),
        initialIndex: index,
      },
      backdropOnly: true,
    });
  }
}