import { inject, Injectable } from '@angular/core';
import { EntityEditCustomFields, EntityEditForm, EntityEditFormValue, ImagePicker, RichTextEditor, VideoPicker } from '../models/entity/edit/form';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '../components/validators/required';
import { Subject } from 'rxjs';
import { CustomFieldType } from '../models/entity/custom-field';
import { CanAddCustomFieldResponse, SubscriptionService } from './subscription.service';
import { ToastService } from './toast.service';
import { VideoService } from './video.service';
import { removeItemFromArray } from '../utils/array';
import { DisplayMedia } from '../models/image';
import { FileService } from './file.service';
import { ImageService } from './image.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {
  subscriptionService = inject(SubscriptionService);
  toastService = inject(ToastService);
  videoService = inject(VideoService);
  fileService = inject(FileService);
  imageService = inject(ImageService);
  sessionService = inject(SessionService);
  customFieldDeleted = new Subject<number>();

  private constructCustomField(customFieldType?: CustomFieldType | null) {
    const group = new FormGroup<EntityEditCustomFields>({
      fieldType: new FormControl(customFieldType ?? null),
    });
    return group;
  }

  applyCustomFieldControl(formControl: FormGroup<EntityEditCustomFields>, customFieldType: CustomFieldType) {
    if (customFieldType === CustomFieldType.RichTextEditor) {
      formControl.addControl('richTextEditor', new FormGroup<RichTextEditor | null>({
        title: new FormControl('', [requiredValidator("Rich Text Field Title")]),
        value: new FormControl(''),
      }));
    } else if (customFieldType === CustomFieldType.Images) {
      formControl.addControl('imagePicker', new FormGroup<ImagePicker | null>({
        title: new FormControl('', [requiredValidator("Images Title")]),
        value: new FormControl([]),
      }));
    } else if (customFieldType === CustomFieldType.Videos) {
      formControl.addControl('videoPicker', new FormGroup<VideoPicker | null>({
        title: new FormControl('', [requiredValidator("Videos Title")]),
        value: new FormControl([]),
      }));
    }
  }

  async add(form: FormGroup<EntityEditForm>, index = form.controls.customFields.length, customFieldType?: CustomFieldType) {
    const canAdd = await this.canAddCustomField(form);
    if (!canAdd) return;
    form.controls.customFields.insert(index, this.constructCustomField(customFieldType));
  }

  removeByIndex(form: FormGroup<EntityEditForm>, index: number) {
    const fieldToRemove = form.controls.customFields.at(index);
    const fieldId = fieldToRemove.value.id;
    if (fieldId) {
      this.customFieldDeleted.next(fieldId);
    }
    form.controls.customFields.removeAt(index);
  }

  async canAddCustomField(form: FormGroup<EntityEditForm>) {
    const isStaff = await this.sessionService.isStaffAccountAsync();
    if (isStaff) return true;
    const response = this.subscriptionService.canAddCustomField(form);
    if (!response.canAdd) {
      this.toastService.error(response.reason);
      return false;
    };
    return true;
  }

  async canSelectCustomFieldType(customFieldType: CustomFieldType): Promise<CanAddCustomFieldResponse> {
    const isStaff = await this.sessionService.isStaffAccountAsync();
    if (isStaff) return { canAdd: true };
    return this.subscriptionService.canSelectCustomFieldType(customFieldType);
  }

  async uploadVideos(model: EntityEditFormValue) {
    const customFieldVideos = model.customFields?.filter(c => c.fieldType === CustomFieldType.Videos) || [];
    for (const field of customFieldVideos) {
      let allVideos = field.videoPicker?.value || [];
      const newVideos = allVideos.filter(v => v.status === 'new');
      if (!allVideos.length) {
        removeItemFromArray(customFieldVideos, field);
        continue;
      }
      const videos = newVideos.map(v => v.originalFile).filter(f => f) as File[];
      const response = await this.videoService.upload(videos);
      allVideos = allVideos.filter(v => v.status !== 'new');
      allVideos.push(...response.map<DisplayMedia>(r => ({ status: 'new', objectName: r.uid })));
      allVideos.forEach(v => {
        delete v.originalFile;
        delete v.src;
        delete v.thumbnail;
      });
      if (field.videoPicker?.value) // null check
        field.videoPicker.value = allVideos;
    }
  }

  async uploadCustomFieldImages(formValue: EntityEditFormValue) {
    const customFields = formValue.customFields?.filter(field => field.fieldType === CustomFieldType.Images) ?? [];
    for (const customField of customFields) {
      let allImages = customField.imagePicker?.value;
      const newImages = customField.imagePicker?.value?.filter(image => image.status === 'new') ?? [];
      if (!allImages?.length) {
        removeItemFromArray(customFields, customField);
        continue;
      }
      for (const newImage of newImages) {
        newImage.originalFile = await this.imageService.compress(newImage.originalFile!, 150);
      }
      const uploadResponses = await Promise.all(newImages.map(i => this.fileService.resumableUpload({ bucketName: 'images', file: i.originalFile! })));
      allImages = allImages.filter(image => image.status !== 'new');
      allImages.push(...uploadResponses.map<DisplayMedia>(u => ({ bucketName: u.bucketName, objectName: u.objectName, status: 'new' })));
      allImages.forEach((image, index) => {
        // we need to delete heavy properties to send a minimal payload on save.
        delete image.originalFile;
        delete image.src;
      });
      if (customField.imagePicker?.value) // null check
        customField.imagePicker.value = allImages;
    }
  }

  deleteUnnecessaryCustomFieldProperties(formValue: EntityEditFormValue) {
    formValue.customFields = formValue.customFields?.map<FormGroup<EntityEditCustomFields>['value']>(f => {
      const filtered: FormGroup<EntityEditCustomFields>['value'] = {};
      const customFieldType = f.fieldType;
      if (customFieldType === CustomFieldType.RichTextEditor) {
        filtered.richTextEditor = f.richTextEditor;
      } else if (customFieldType === CustomFieldType.Images) {
        filtered.imagePicker = f.imagePicker;
      } else if (customFieldType === CustomFieldType.Videos) {
        filtered.videoPicker = f.videoPicker;
      }
      filtered.fieldType = customFieldType;
      filtered.id = f.id;
      return filtered;
    });
  }
}
