import { Component, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '../../validators/required';
import { markAllControlsAsTouchedAndDirty } from 'src/app/utils/form';
import { SupabaseService } from 'src/app/services/supabase.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session.service';
import { AuthApiError } from '@supabase/supabase-js';
import { ToastService } from 'src/app/services/toast.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  supabaseService = inject(SupabaseService);
  authService = inject(AuthService);
  sessionService = inject(SessionService);
  router = inject(NavController);
  toastService = inject(ToastService);
  activatedRoute = inject(ActivatedRoute);

  form = new FormGroup({
    // we intentionally don't use the email or password validator here
    // since the logic might change in the future, and it may prevent users
    // from reaching their accounts...
    email: new FormControl('', [requiredValidator("Email")]),
    password: new FormControl('', [requiredValidator("Password")])
  });

  loggingIn = signal(false);
  errorMessage = signal<string | undefined>(undefined);


  async login(event?: Event) {
    event?.preventDefault();
    markAllControlsAsTouchedAndDirty(this.form);
    this.loggingIn.set(true);
    const formValues = this.form.value;
    try {
      await this.authService.login(formValues);
      await this.afterLogin();
    } catch (e) {
      const error = e as AuthApiError;
      this.toastService.error(error.message);
      console.log('ERROR!', { error });
    } finally {
      this.loggingIn.set(false);
    }
  }

  async afterLogin() {
    const returnUrl = this.getReturnUrl();
    if (returnUrl) {
      this.router.navigateForward(returnUrl);
      return;
    }
    const account = await this.sessionService.getAccount();
    const userPages = await this.supabaseService.supabase.from('entity').select('*').eq('user_id', account?.user_id!);
    if (userPages.data?.length) {
      this.router.navigateForward('/dashboard/pages');
    }
    else {
      this.router.navigateForward('/dashboard/pages/0/edit?activeIndex=0');
    }
  }

  getReturnUrl() {
    const returnUrl = this.activatedRoute.snapshot.queryParamMap.get('authReturnUrl');
    return decodeURIComponent(returnUrl ?? '');
  }

  forgotPassword() {
    this.router.navigateForward('/auth/enter-id');
  }

  onEnter() {
    this.login();
  }
}
