import { CanActivateFn } from '@angular/router';
import { SessionService } from '../services/session.service';
import { inject } from '@angular/core';
import { Role } from '../models/role';

export const staffGuard: CanActivateFn = async (route, state) => {
  const sessionService = inject(SessionService);
  const account = await sessionService.getAccount();
  return account?.role === Role.Staff || account?.role === Role.Admin;
};
