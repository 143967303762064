import { inject, Injectable, signal } from '@angular/core';
import { LocalStorageKey, LocalStorageService } from './local-storage.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { filter, map, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PromoService {

  localStorageService = inject(LocalStorageService);
  activatedRoute = inject(ActivatedRoute);

  appliedPromo = signal<PromoCode | null | undefined>(this.localStorageService.get(LocalStorageKey.PromoCode));
  appliedPromoChanged$ = toObservable(this.appliedPromo);
  promoCode$ = this.activatedRoute.queryParamMap.pipe(
    map(params => params.get('code')),
    filter(param => !!param),
    switchMap(param => {
      const localStorageCode = this.localStorageService.get<PromoCode>(LocalStorageKey.PromoCode);
      if (localStorageCode?.code === param)
        return of(localStorageCode);
      return of(null);
    }),
    filter(promo => !!promo),
  );

  constructor() {
    this.appliedPromoChanged$.subscribe(promo => {
      if (promo)
        this.localStorageService.set(LocalStorageKey.PromoCode, promo);
      else
        this.localStorageService.remove(LocalStorageKey.PromoCode);
    });

    this.promoCode$.subscribe(code => this.setPromoCode(code as PromoCode));
  }

  setPromoCode(promo: PromoCode | undefined) {
    this.appliedPromo.set(promo);
  }

  checkHasAppliedCode(code: string | undefined) {
    return this.appliedPromo()?.code === code;
  }
}

// we'll use hardcoded promos for now.
export interface PromoCode {
  code: string;
  discountAmount: number;
}