import { Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '../../validators/required';
import { SupabaseService } from 'src/app/services/supabase.service';
import { ToastService } from 'src/app/services/toast.service';
import { SessionService } from 'src/app/services/session.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
})
export class AccountProfileComponent {

  supabaseService = inject(SupabaseService);
  toastService = inject(ToastService);
  sessionService = inject(SessionService);
  authService = inject(AuthService);
  loggingService = inject(LoggingService);

  form = new FormGroup<UpdateProfileForm>({
    name: new FormControl('', [requiredValidator("Name")]),
  });

  saving = signal(false);
  loggingOut = signal(false);

  async updateUser() {
    if (this.form.invalid) return;
    if (this.saving()) return;
    this.saving.set(true);
    const value = this.form.value;
    const account = await this.sessionService.getAccount();
    const accountId = account?.id;
    const { error } = await this.supabaseService.supabase.from('account').update({
      full_name: value.name,
    }).eq('id', accountId!);
    if (error) {
      this.loggingService.error(error);
      this.toastService.error("Error updating account information");
    } else {
      this.toastService.success("Profile updated successfully");
      this.sessionService.retrieveAdditionalUserData();
      this.form.reset();
    }
    this.saving.set(false);
  }

  async signOut() {
    if (this.loggingOut()) return;
    this.loggingOut.set(true);
    await this.authService.logout();
    this.loggingOut.set(false);
  }
}

export interface UpdateProfileForm {
  // email: FormControl<string>; we'll do email a different time since it requires verification.
  name: FormControl<string | null>;
}