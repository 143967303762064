import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SupabaseService } from '../services/supabase.service';

export const authGuard: CanActivateFn = async (route, state) => {
  const supabaseService = inject(SupabaseService);
  const router = inject(Router);
  // we need to fetch the session, in case the user reloads the page.
  const session = await supabaseService.supabase.auth.getSession();
  const isLoggedIn = !!session.data.session?.access_token;
  if (!isLoggedIn) {
    router.navigateByUrl('/auth/login');
    return false;
  }
  return true;
};
