@if(!hideToolbar()) {
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start"> </ion-buttons>
    <ion-title>Preview Images</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()" [strong]="true">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
}
<swiper-container
  class="w-auto grow-1 shrink max-w-full min-h-0 h-full"
  [initialSlide]="mediaIndex()"
  #swiper
  [injectStyles]="['assets/swiper.theme.css']"
  (swiperslidechange)="handleSlideChange($event)"
>
  @for (media of media(); track $index) {
  <swiper-slide class="w-full flex items-center justify-center">
    @if (type() === 'image') {
    <img [src]="media.src" class="max-h-full w-auto object-contain block" />
    } @else {
    <app-video-player #videoPlayer [media]="media" [(muted)]="muted" />
    }
  </swiper-slide>
  }
</swiper-container>
<button
  class="slide-button right-4"
  (click)="next()"
  [disabled]="!hasNextMedia()"
>
  <ion-icon name="chevron-forward-outline"></ion-icon>
</button>
<button
  class="slide-button left-4"
  (click)="previous()"
  [disabled]="!hasPreviousMedia()"
>
  <ion-icon name="chevron-back-outline"></ion-icon>
</button>
<div
  class="flex items-center justify-center gap-2 px-3 py-2 bg-gray-700/50 absolute bottom-4 right-4 rounded-lg shadow-lg shadow-black/20 text-white backdrop-blur-sm z-[10000]"
>
  @if (media().length === 1) {
  <ion-icon name="image-outline"></ion-icon>
  } @else {
  <ion-icon name="images-outline"></ion-icon>
  }
  <span>{{ mediaIndex() + 1 }} / {{ media().length }}</span>
</div>
