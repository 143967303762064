import { Component, ElementRef, forwardRef, input, model, viewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Tag } from 'src/app/models/entity/edit/form';
import { copyObject } from 'src/app/utils/object';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagInputComponent),
      multi: true
    }
  ],
})
export class TagInputComponent implements ControlValueAccessor {
  label = input<string>();
  description = input<string>();
  htmlFor = input<string>();
  value = model<TagValue[]>();
  input = viewChild<ElementRef<HTMLInputElement>>('input');
  maximumTags = input<number>(50);
  maximumTagLength = input<number>(30);
  onChange = (value: TagValue[] | undefined) => { };
  onTouched = () => { };
  writeValue(value: TagValue[] | undefined) {
    this.value.set(value);
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onEnter(event: Event, input: HTMLInputElement) {
    event.preventDefault();
    const value = input.value;
    if (!value) return;
    const existingTags = this.value() ?? [];
    if (existingTags.length === this.maximumTags()) {
      return;
    }
    input.value = '';
    const labelForCompare = value.toLowerCase().trim();
    const alreadyExists = existingTags.find(t => t.label?.toLowerCase() === labelForCompare);
    if (alreadyExists) {
      const filtered = existingTags.filter(t => t.label !== labelForCompare);
      filtered?.push(alreadyExists);
      this.value.set(filtered);
      this.onChange(filtered);
      return;
    }
    const newTag: TagValue = {
      label: value.toLowerCase(),
    };
    existingTags.push(newTag);
    const newArr = [...existingTags];
    this.value.set(newArr);
    this.onChange(newArr);
  }

  removeTag(tag: TagValue) {
    const filtered = this.value()?.filter(t => t !== tag);
    this.value.set(filtered);
    this.onChange(filtered);
  }

  removeLastTag(value: string) {
    if (value) return; // a character was deleted
    const existingTags = this.value();
    if (!existingTags?.length) return;
    existingTags?.pop();
    this.value.set(existingTags);
    this.onChange(existingTags);
  }

  focusInput() {
    this.input()?.nativeElement.focus();
  }
}

export type TagValue = FormControl<Tag>['value'];