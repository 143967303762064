import { Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { registerClassOnWindow } from 'src/app/utils/global';
import * as sanitizeHtml from 'sanitize-html';

@Component({
  selector: 'app-rich-text-display',
  templateUrl: './rich-text-display.component.html',
  styleUrls: ['./rich-text-display.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class RichTextDisplayComponent {
  domSanitizer = inject(DomSanitizer);
  text = input.required<string | undefined | null>();
  sanitizedText = computed(() => {
    let text = this.text() ?? '';
    text = sanitizeHtml(text, { allowedAttributes: { '*': ['style'] } });
    return this.domSanitizer.bypassSecurityTrustHtml(text);
  });

  constructor() {
    registerClassOnWindow('RichTextDisplayComponent', this);
  }
}
