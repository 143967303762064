import { AfterViewInit, Component, ElementRef, inject, input, OnInit, signal, viewChild, viewChildren } from '@angular/core';
import { MediaType } from 'src/app/models/file';
import { DisplayMedia } from 'src/app/models/image';
import { ModalService } from 'src/app/services/modal.service';
import { SwiperContainer } from 'swiper/element';
import { VideoPlayerComponent } from '../video-player/video-player.component';

@Component({
  selector: 'app-media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss'],
})
export class MediaSliderComponent implements AfterViewInit {
  modalService = inject(ModalService, { optional: true });
  swiperContainer = viewChild<ElementRef<SwiperContainer>>('swiper');

  media = input.required<DisplayMedia[]>();
  initialIndex = input<number | undefined>(0);
  type = input.required<MediaType>();
  videoPlayers = viewChildren<VideoPlayerComponent>('videoPlayer');
  muted = signal(false);

  ngAfterViewInit() {
    const initialIndex = this.initialIndex() ?? 0;
    this.swiperContainer()?.nativeElement.swiper.slideTo(initialIndex);
  }

  handleSlideChange(event: Event) {
    this.updateVideoPlayback();
  }

  updateVideoPlayback() {
    if (this.type() !== 'video') return;
    const activeIndex = this.swiperContainer()?.nativeElement.swiper.activeIndex;
    const videoPlayers = this.videoPlayers();
    videoPlayers.forEach((player, index) => {
      if (index === activeIndex) {
        player.play();
      } else {
        player.pause();
      }
    });
  }

  close() {
    this.modalService?.close();
  }

  mediaClick(event: Event) {
    event.stopPropagation();
  }
}
