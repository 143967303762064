import { ValidatorFunction } from "src/app/models/validator";

export function fixedLengthValidator(length: number): ValidatorFunction {
  return function (control) {
    if (!control.touched || !control.dirty) return null;
    const value = String(control.value);
    if (value.length !== length) {
      return { error: `Must be ${length} characters` };
    }
    return null;
  };
}